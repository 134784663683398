@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400;1,500&display=swap');
// font-family: 'Roboto', sans-serif;
// font-family: 'Roboto Condensed', sans-serif;

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-size: 16px;
	color: #040707;
	line-height: 20px;
	cursor: default;
}
body:has(#menu-desktop) {
	height: 100vh;
	overflow-y: hidden;
}
body:has(.modal-dialog) {
	height: 100vh;
	overflow-y: hidden;
}
body:has(.sizeguide-dialog) {
	height: 100vh;
	overflow-y: hidden;
}
body:has(#search-screen) {
	height: 100vh;
	overflow-y: hidden;
}

* {
	box-sizing: border-box;
	--bigsize: 46px;
	--mediumsize: 30px;
	--smallsize: 24px;
	--microsize: 16px;
	--nanosize: 14px;
	--picosize: 12px;

	@media (max-width: 980px) {
		--bigsize: 38px;
		--mediumsize: 26px;
		--smallsize: 22px;
		--microsize: 14px;
		--nanosize: 12px;
	}

	@media (max-width: 575px) {
		--bigsize: 24px;
		--mediumsize: 22px;
		--smallsize: 18px;
		--microsize: 12px;
		--nanosize: 10px;
	}
}

a {
	text-decoration: none;
	color: #040707;
}

img {
	max-width: 100%;
	display: block;
}

b,
strong {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
}

.medium-font {
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
}

.black-font {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
}

button {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	cursor: pointer;
	color: #040707;

	@media (min-width: 1000px) {
		transition: 0.3s ease-in-out;
	}
	/*	&.secondary {
		color: rgb(0, 0, 0);
		font-size: 16px;
		border: 1px solid rgb(0, 0, 0);
		border-radius: 0px;

		&:hover {
			background-color: #000;
			color: #fff;
		}
	}*/
}

.seo-menu {
	display: none;
}

.content-wrapper {
	width: 100%;
	max-width: 1160px;
	margin: auto;
	padding: 0 15px;

	@media (max-width: 1200px) {
		padding: 0 22px;
	}

	@media (max-width: 575px) {
		padding: 0 12px;
	}
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

#default-header {
	position: relative;
	z-index: 2000;
}

/* Top Message Banner Start */
.topbar-message {
	background-color: #000;
	color: #ffffff;
	text-align: center;
	padding: 10px 0;
	font-family: 'Roboto', sans-serif;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	justify-content: space-between;

	span {
		width: 100%;
		text-align: center;
		margin-right: 10px;
		margin-left: 10px;
	}

	button {
		color: #ffffff;
		font-size: 20px;
		align-self: center;
		margin-right: 10px;
		transform: rotate(90deg);
	}
	a {
		color: #fff;
	}
}

#default-header .underheader-message {
	background-color: #0154ff;
	color: #ffffff;
	text-align: center;
	padding: 14px 0;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	justify-content: space-between;

	span {
		width: 100%;
		text-align: center;
		margin-right: 10px;
		margin-left: 15px;
	}

	button {
		color: #ffffff;
		font-size: 20px;
		align-self: center;
		margin-right: 10px;
		transform: rotate(90deg);
	}

	@media (max-width: 600px) {
		padding: 5px 0;
		span {
			text-align: left;
		}
		button {
			align-self: start;
			margin-top: 5px;
		}
	}
	@media (max-width: 420px) {
		button {
			margin-top: 6px;
		}
	}
}

/* Top Message Banner end */

#default-header,
#anchor-links {
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
}

#default-header .content-wrapper {
	max-width: 1400px;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 74px;

	@media (max-width: 600px) {
		height: 45px;
		justify-content: flex-start;

		.header-options {
			margin-left: auto;
		}
	}
}

#default-header .content-wrapper {
	@media (min-width: 1201px) {
		display: grid;
		grid-template-columns: minmax(159px, 218px) minmax(700px, 1fr) minmax(290px, 29%);
		gap: 15px;
	}
}

#default-header .visible-menu {
	display: inline-flex;
	column-gap: 35px;
}

#default-header .visible-menu .menu-item a {
	display: inline-block;
	font-family: Ubuntu, sans-serif;
	font-weight: 700;
	color: black;
	font-size: 14px;
}

#default-header #logo {
	max-width: 218px;
	line-height: 0;

	/*@media (min-width: 1025px) {
		width: 16%;
	}*/

	a {
		width: 100%;
	}

	img {
		width: 100%;
	}
}

@media (max-width: 600px) {
	#default-header #logo {
		max-width: 200px;
	}
}

@media (max-width: 425px) {
	#default-header #logo {
		padding: 0;
		max-width: 160px;
	}
}

@media (max-width: 500px) {
	#default-header #logo {
		padding: 0;
		// max-width: 160px;
		// margin-right: 5px;
	}

	#default-header .content-wrapper {
		padding: 0 17px 0px 11px;
	}
}

#default-header #logo a {
	display: inline-block;
	line-height: 0;
}

#default-header #burger-btn {
	width: 34px;
	height: 34px;
	display: none;
	align-items: center;
	cursor: pointer;
	margin-right: 35px;

	svg.close-burger {
		display: none;
	}

	&.active {
		svg.close-burger {
			display: initial;
		}

		svg.open-burger {
			display: none;
		}
	}

	@media (max-width: 1024px) {
		display: flex;
	}
}

#default-header {
	background: white;
	position: sticky;
	// position: fixed;
	width: 100%;
	top: 0px;
	z-index: 2002;
	box-shadow: none;

	@media (max-width: 767px) {
		&.home-header {
			box-shadow: none;
			background: transparent;
		}

		box-shadow: 0px 10px 30px #00000029;
	}

	hr {
		border-top: 1px solid #a4a9b8;
		margin-top: 0px;
	}
}

.menu-item-active {
	color: #0154ff;
}

.hover-underline-animation {
	display: inline-block;
	position: relative;
}

.hover-underline-animation:after {
	content: '';
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 2px;
	bottom: 0;
	left: 0;
	background-color: #0154ff;
	transform-origin: bottom right;
	transition: transform 0.3s ease-out;
}

.hover-underline-animation:hover:after {
	transform: scaleX(1);
	transform-origin: bottom left;
}

.menu {
	@media (min-width: 1201px) {
		padding-right: 18px;
	}

	@media (max-width: 1200px) {
		width: 65%;
		padding: 0 15px;
	}

	ul {
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 6.5%;

		li {
			font-size: 16px;
			font-family: 'Roboto Condensed';
			font-weight: bold;
			cursor: pointer;
		}

		@media (max-width: 1200px) {
			justify-content: center;
		}

		@media (max-width: 1150px) {
			gap: 33px;
		}
	}

	@media (max-width: 1024px) {
		display: none;
	}
}
#menu-desktop {
	height: 100vh;
}
.menu-active-close-icon {
	position: absolute;
	z-index: 2000;
	right: 15px;
	margin-top: 0px;
	cursor: pointer;

	img {
		width: 20px;
	}

	@media (max-width: 1024px) {
		display: none;
	}
}
.menu-active-wrapper {
	position: relative;
	width: 100%;
	max-width: 1200px;
	padding: 35px 15px 80px 15px;
	margin: auto;

	.menu-active {
		display: flex;
		justify-content: space-between;
		gap: 10px;

		.hover-underline-animation {
			display: inline-block;
			position: relative;
		}

		.first-level-categories {
			// margin-left: 150px;
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;
			width: 100%;
			max-height: 70vh;
			overflow-y: auto;

			.first-level-category {
				h3 {
					font-size: 16px;
					margin-bottom: 20px;
					cursor: pointer;

					a {
						color: #0154ff;
					}
				}

				ul {
					display: flex;
					flex-direction: column;
					gap: 10px;

					li {
						font-size: 14px;
						font-weight: 500;
						cursor: pointer;
						margin-bottom: 3px;
					}
				}
			}

			// @media (max-width: 1250px) {
			// 	margin-left: 75px;
			// }

			// @media (max-width: 1075px) {
			// 	margin-left: 25px;
			// }
		}

		.first-level-categories::-webkit-scrollbar {
			width: 10px;
		}

		/* Track */
		.first-level-categories::-webkit-scrollbar-track {
			background: #f1f1f1;
		}

		/* Handle */
		.first-level-categories::-webkit-scrollbar-thumb {
			background: #888;
		}

		/* Handle on hover */
		.first-level-categories::-webkit-scrollbar-thumb:hover {
			background: #555;
		}

		.first-level-categories-img {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			gap: 90px;

			.first-level-category {
				img {
					max-width: 120px;
				}

				span {
					margin-top: 10px;
					text-align: center;
					font-size: 14px;
					display: block;
				}
			}

			// @media (max-width: 1250px) {
			// 	margin-left: 75px;
			// }

			// @media (max-width: 1075px) {
			// 	margin-left: 25px;
			// }
		}

		.category-img {
			max-width: 270px;
			width: 100%;
			margin-left: auto;

			.category-img-title {
				display: block;
				text-align: left;
				font-weight: 500;
				text-decoration: underline;
				margin-top: 20px;
				margin-bottom: 20px;
				cursor: pointer;
			}
		}
	}

	@media (max-width: 1024px) {
		display: none;
	}
}

//Search bar CSS Transition - START
.search-transition-enter {
	opacity: 0.4;
	transform: translateY(-100%);
}

.search-transition-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition:
		opacity 300ms,
		transform 300ms;
}

.search-transition-exit {
	opacity: 1;
	transform: translateY(0);
}

.search-transition-exit-active {
	opacity: 0.2;
	transform: translateY(-100%);
	transition:
		opacity 0s,
		transform 0s;
}
//Search bar CSS Transition - END

.login-register-mobile {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100vw;
	bottom: 0;
	left: 0;
	height: 83px;
	background: white;
	z-index: 2004;
	opacity: 0;
	pointer-events: none;
	transition: 0.2s;

	button {
		width: 46%;
		max-width: 164px;
		height: 45px;
		display: inline-flex;
		border: 1px solid black;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		align-items: center;
		margin: 0 6px;

		&.register-button {
			background: black;
			color: white;
		}
	}

	@media (min-width: 481px) {
		display: none;
	}

	&.active {
		opacity: 1;
		pointer-events: none;

		button {
			pointer-events: auto;
		}
	}
}

#top-home-search {
	opacity: 0;
	pointer-events: none;
	transition: 0.3s;
	display: block;
	position: absolute;
	top: 65px;
	left: 0;
	z-index: 1;
	width: 100%;

	button {
		width: calc(100% - 44px);
		display: flex;
		margin: 0 auto;
		background: #f2f2f2;
		height: 35px;
		padding: 0 15px;
		align-items: center;
		justify-content: space-between;
	}

	&.active {
		opacity: 1;
		pointer-events: initial;
	}

	@media (min-width: 767px) {
		display: none !important;
	}
}

#change-language {
	display: flex;
	align-items: center;
	gap: 5px;
	cursor: pointer;
	display: none;

	img {
		width: 27px;
	}

	.dropdown {
		width: 6px;
		margin-left: 3px;
		margin-top: 1px;
		rotate: 90deg;
	}

	@media (max-width: 1024px) {
		display: none;
	}
}

#default-header.line-header {
	box-shadow: none;
	border-bottom: 1px solid #ababab;
	background: white;

	@media (max-width: 1260px) {
		border-bottom: none;
	}
}

#default-header .header-options {
	display: flex;
	align-items: center;
	column-gap: 13px;
	height: 40px;

	/*@media (min-width: 1201px) {
		width: 29%;
	}
*/
	@media (max-width: 450px) {
		column-gap: 10px;
	}

	@media (max-width: 360px) {
		column-gap: 5px;
	}

	img {
		width: 30px;
		height: 30px;

		@media (max-width: 450px) {
			width: 22px;
			height: 22px;
		}
	}

	#user-login {
		margin-left: 5px;

		@media (max-width: 1200px) {
			margin-left: 0;
		}
	}

	@media (max-width: 1200px) {
		.searchbar-window-predictions {
			display: none;
		}
	}
}

.search {
	display: flex;
	position: relative;

	/*@media (min-width: 1025px) {
		width: 65%;
		max-width: 288px;
	}
*/
	input[type='text'] {
		border-radius: 0px;
		background-color: #f2f4f8;
		height: 40px;
		padding: 13px 8px 12px 12px;

		@media (max-width: 1200px) {
			display: none;
		}
	}

	input:focus {
		border: none;
	}

	input::placeholder {
		color: #525252;
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
		font-size: 12px;
		opacity: 1;
	}

	button {
		border-radius: 0;
		padding: 0 8px;
		background-color: #f2f4f8;

		@media (max-width: 1200px) {
			background: none;
			padding: 0;
		}

		img {
			width: 24px;

			@media (max-width: 450px) {
				width: 22px;
			}
		}
	}

	@media (max-width: 1260px) {
		width: 245px;
	}

	@media (max-width: 1200px) {
		width: auto;
	}
}
.searchbar-window-predictions {
	position: absolute;
	width: 375px;
	background-color: #fff;
	top: 70px;
	right: 100px;
	padding: 31px 45px;
	box-shadow: 0px 10px 30px #00000029;
	display: grid;
	grid-template-columns: 1fr;
	gap: 12px;
	z-index: 10001;

	@media (max-width: 1200px) {
		width: 100%;
		right: 0px;
		left: 0px;
		box-shadow: 0px 0px 0px 0px #000;
	}

	span {
		font-size: 14px;
		cursor: pointer;
	}
}

#menu-modal {
	position: fixed;
	top: 0px;
	bottom: 0;
	background: #fff;
	transition: 0.2s;
	width: 100%;
	z-index: 2010;
	padding: 0 12px;
	height: 100%;
	overflow: scroll;

	@media (min-width: 1261px) {
		z-index: 2010;
	}

	@media (max-width: 1260px) {
		width: 100%;
		max-width: 100vw;
		background: white;
		left: 0;
	}

	@media (max-width: 980px) {
		padding: 0 12px;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 10px 5px;

		.lang-change {
			height: 30px;
			img {
				display: none;
				width: 30px;
				height: 30px;
				cursor: pointer;
			}
		}

		.close-icon {
			cursor: pointer;

			img {
				transform: scale(1.1);
			}
		}
	}

	.search {
		display: flex;
		margin: 0px 5px;

		input {
			border-radius: 0px;
			background-color: #f2f4f8;
			height: 45px;
			display: block;
		}

		input:focus {
			border: none;
		}

		input::placeholder {
			color: #6e7486;
			font-family: 'Roboto';
			font-weight: 400;
			font-size: 14px;
			opacity: 1;
		}

		button {
			border-radius: 0;
			padding-left: 13px;
			padding-right: 13px;
			background-color: #e2e5eb;
		}
	}

	nav {
		margin: 20px 5px;

		.back {
			font-size: 14px;
			padding: 10px;
			color: #ccc;
			text-decoration: underline;
			cursor: pointer;
		}

		.categories {
			.category {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-radius: 10px;
				padding: 5px 5px;
				border: 1px solid #e2e5ea;
				margin-bottom: 5px;
				min-height: 55px;
				cursor: pointer;

				.category-info {
					display: flex;
					align-items: center;

					img {
						margin-left: 5px;
						margin-right: 25px;
					}

					h3 {
						font-size: 16px;
						margin-bottom: 0;
					}
				}

				.category-info:not(.has-image) {
					padding: 12px;
				}

				.browse-icon {
					margin-right: 10px;
				}
			}
		}

		.brands {
			margin-top: 40px;

			.brands-header {
				display: flex;
				justify-content: space-between;

				h4 {
					align-self: end;
					margin-bottom: 2px;
					text-decoration: underline;
					font-weight: 800;
				}
			}

			.brands-content {
				white-space: nowrap;
				overflow-x: scroll;
				margin-top: 5px;
				display: flex;
				padding-bottom: 30px;
				gap: 25px;

				img {
					max-width: 82px;
				}
			}
		}

		.sports {
			margin-top: 40px;
			margin-bottom: 40px;

			.sports-header {
				display: flex;
				justify-content: space-between;

				h4 {
					align-self: end;
					margin-bottom: 2px;
					text-decoration: underline;
					font-weight: 800;
				}
			}

			.sports-content {
				margin-top: 5px;
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
				gap: 8px;

				.sport {
					text-align: center;
					padding-top: 10px;

					span {
						margin-top: 7px;
						text-align: center;
						font-size: 11px;
						display: block;
					}

					img {
						max-width: 82px;
					}
				}
			}
		}
	}
}

#external li a {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
}

#user-options {
	text-align: left;
	padding-left: 18px;
	border-bottom: solid 1px #5b5b5b;
	max-width: 155px;
	margin: auto;
}

#user-options li {
	display: flex;
	align-items: center;
}

#user-options a {
	font-size: 13px;
}

#user-options span {
	margin-right: 10px;
}

/*#lang-flag {
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    background-size: 100%;
}

.lang-gr {
    background-image: url(../images/lang-gr.svg);
}

.lang-en {
    background-image: url(../images/lang-en.svg);
}*/

.left-lang {
	display: none;
}

#lang-name {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	text-decoration: underline;
	font-size: 16px;
	vertical-align: middle;
	position: relative;
	padding-left: 12px;
	line-height: 22px;
}

#lang-name:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0;
	width: 2px;
	height: 22px;
	border-left: 2px solid #c8c8c8;
	bottom: 0;
	margin: auto;
}

#lang-name:hover {
	text-decoration: none;
}

@media (max-width: 670px) {
	.left-lang {
		display: block;
		position: absolute;
		left: 60px;
		margin: 0;
	}

	.right-lang {
		display: none;
	}

	#lang-name {
		padding: 0;
	}

	#lang-name:before {
		content: none;
	}
}

#lang-switch {
	text-decoration: underline;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
}

#lang-switch:hover,
#lang-switch:focus {
	color: #fb4401;
}

#close-modal {
	text-align: right;
	position: absolute;
	right: 0;
	cursor: pointer;
	padding: 5px 18px 5px 5px;
}

.page-heading {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	letter-spacing: -0.44px;
	font-size: 22px;
	text-align: center;
	line-height: 26px;
	margin: 0;
}

/* Checkout Header */
#header-checkout {
	header {
		box-shadow: 0px 10px 30px #00000029;

		.content-wrapper {
			display: flex;
			height: 80px;
			align-items: center;
			justify-content: space-between;

			@media (max-width: 575px) {
				height: 50px;
			}
		}

		#checkout-logo {
			max-width: 218px;
			@media (max-width: 575px) {
				max-width: 160px;
			}
		}
	}

	.steps-container {
		margin: 58px auto 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 575px) {
			margin: 30px auto 30px;
		}

		.back {
			cursor: pointer;
			align-self: center;
			font-size: 16px;

			@media (max-width: 768px) {
				display: none;
			}
		}

		.back::before {
			content: '';
			display: block;
			background: url('../images/arrow-right.svg') no-repeat;
			transform: rotate(180deg);
			width: 10px;
			height: 21px;
			float: left;
			margin: -4px 15px 0px 0px;
			cursor: pointer;
		}

		.checkout-steps {
			display: flex;
			justify-content: center;
			margin: auto;
			width: 100%;
			padding: 0;

			@media (max-width: 768px) {
				padding: 0;
			}

			.checkout-step {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 11px;
				position: relative;
				max-width: 200px;
				width: 100%;
				box-sizing: border-box;

				.step-numb {
					width: 40px;
					height: 40px;
					font-weight: 700;
					border-radius: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: 2;

					&.clickable {
						cursor: pointer;
					}

					&.disabled {
						cursor: not-allowed;
					}

					&.completed {
						border: 1px solid #000;
					}
				}

				.step-description {
					text-align: center;
					color: #b5bac3;
				}
			}

			.step-line {
				height: 2px;
				width: calc(100% - 35px);
				background-color: #b5bac3;
				position: absolute;
				left: calc(50% + 18px);
				z-index: 1;

				&.completed {
					background-color: #0154ff;
				}
			}
		}
	}
}
/* Checkout Header Ends */

// END CHECKOUT PAGE
.checkout-step1-page {
	.breadcrumbs-container {
		display: none;
	}

	.already-loggedin-container {
		max-width: 575px;
		margin: 10px auto 0;

		h2 {
			font-size: 20px;
			@media (max-width: 575px) {
				font-size: 16px;
			}
		}

		.actions {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			margin-top: 20px;
			gap: 15px;

			@media (max-width: 575px) {
				grid-template-columns: 1fr;
			}

			button {
				width: 100%;
			}
		}
	}
}

.checkout-step2-page {
	.wrapper {
		display: flex;
		flex-direction: column;
	}

	.billing-information-container {
		display: flex;
		flex-direction: column;

		@media (max-width: 575px) {
			align-items: center;
		}
	}

	.billing-info-title {
		flex: 1;
		font-size: 20px;
		font-family: 'Roboto Condensed';
		width: 100%;
		text-align: left;
		margin: 0;

		@media (max-width: 575px) {
			text-align: center;
		}
	}

	.select-receipt-invoice {
		padding-left: 15px;
		margin-top: 25px;
		margin-bottom: 30px;
		display: flex;
		gap: 40px;

		@media (max-width: 980px) {
			padding-left: 0px;
			margin-bottom: 25px;
		}

		label {
			align-items: center;
			color: #000;
			cursor: pointer;
			display: flex;
			gap: 8px;

			input[type='radio'] {
				display: block;
				height: 20px;
				width: 20px;

				&:checked {
					background-color: black;
					border-color: black;
				}

				@media (max-width: 980px) {
					margin-left: -1px;
				}
			}
		}
	}

	.step2-container {
		display: grid;
		grid-template-columns: 1fr 450px;
		grid-template-rows: masonry;
		gap: 17px;

		@media (max-width: 980px) {
			grid-template-columns: 1fr;
		}

		.addresses-container {
			max-width: 375px;
			display: flex;
			flex-direction: column;
			gap: 22px;
			margin-bottom: 30px;

			@media (max-width: 980px) {
				max-width: 100%;
			}

			.address-item {
				border: 2px solid #444;
				padding: 0 30px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-bottom: 0px;
				min-height: 80px;
				cursor: pointer;
				gap: 7px;
				transition: 0.5s;

				&:not(.active):hover {
					opacity: 0.5;
				}

				h4 {
					margin: 0px;
					text-align: left;
				}

				span {
					font-size: 14px;
				}
			}

			.address-item.active {
				border: 2px solid #0154ff !important;
			}

			.address-item.create-new {
				background-color: #f2f4f8;
				color: #6e7486;
				border: 2px solid #f2f4f8;
				height: 80px;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				h4 {
					width: 150px;
				}

				img {
					background-color: #fff;
					padding: 7px;
					width: 28px;
					border-radius: 50px;
				}
			}
		}

		.custom-checkbox {
			margin-top: 20px;
			padding-top: 5px;
			font-size: 14px;

			@media (max-width: 575px) {
				padding-top: 0px;
			}
		}

		.billing-info-form {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-auto-rows: auto;
			column-gap: 17px;
			row-gap: 10px;

			@media (max-width: 575px) {
				display: flex;
				flex-direction: column;
				gap: 12px;
			}

			input::placeholder {
				opacity: 1;
				color: #6e7486;
			}

			.input-error {
				font-size: 11px;
				color: red;
				font-weight: 500;
				margin: 3px 0;
			}

			.input-help {
				font-size: 11px;
				color: #6e7486;
				font-weight: 500;
				margin: 3px 0;
			}

			.react-tel-input .form-control {
				position: relative;
				font-size: 14px;
				letter-spacing: 0.01rem;
				margin-top: 0 !important;
				margin-bottom: 0 !important;
				padding-left: 48px;
				margin-left: 0;
				background: #f2f4f8;
				border: 0px !important;
				border-radius: 5px;
				line-height: 25px;
				height: 48px !important;
				width: 100% !important;
				outline: none;
			}

			.react-tel-input input:focus {
				border: 1px solid #0154ff !important;
			}
		}

		.order-synopsis {
			@media (max-width: 980px) {
				order: 2;
				margin-top: 0px;
			}
		}

		.delivery-information-container {
			margin: 50px 0 35px;

			@media (max-width: 980px) {
				order: 0;
				margin: 70px 0 65px;
				max-width: 100%;
			}

			@media (max-width: 575px) {
				margin: 30px 0 30px;
			}

			.delivery-info-title {
				font-size: 20px;
				font-family: 'Roboto Condensed';
				width: 100%;
				text-align: left;
				margin: 0 0 30px;

				@media (max-width: 575px) {
					text-align: center;
				}
			}

			p {
				font-weight: 400;
				color: #6e7486;

				@media (max-width: 575px) {
					text-align: center;
				}
			}

			.shipping-info-form {
				margin-top: 45px;
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-auto-rows: auto;
				column-gap: 17px;
				row-gap: 10px;

				@media (max-width: 575px) {
					display: flex;
					flex-direction: column;
					gap: 12px;
				}

				input::placeholder {
					opacity: 1;
					color: #6e7486;
				}

				.input-error {
					font-size: 11px;
					color: red;
					font-weight: 500;
					margin: 3px 0;
				}

				.react-tel-input .form-control {
					position: relative;
					font-size: 14px;
					letter-spacing: 0.01rem;
					margin-top: 0 !important;
					margin-bottom: 0 !important;
					padding-left: 48px;
					margin-left: 0;
					background: #f2f4f8;
					border: 0px !important;
					border-radius: 5px;
					line-height: 25px;
					height: 48px !important;
					width: 100% !important;
					outline: none;
				}

				.react-tel-input input:focus {
					border: 1px solid #0154ff !important;
				}

				.custom-checkbox {
					margin-top: 100px;
					padding-top: 5px;
					grid-column-start: 1;
					grid-column-end: 3;
					font-size: 14px;

					@media (max-width: 980px) {
						order: -1;
						margin-top: 0px;
					}

					@media (max-width: 575px) {
						padding-top: 0px;
					}
				}
			}

			textarea {
				margin-top: 15px;
			}

			.input-error {
				font-size: 11px;
				color: red;
				font-weight: 500;
				margin: 3px 0;
			}

			button {
				margin-top: 16px;
				width: 100%;
			}
		}

		.next-step {
			width: 100%;
			margin-bottom: 50px;

			button {
				width: 375px;
				@media (max-width: 575px) {
					width: 100%;
				}
			}

			&.desktop-view {
				@media (max-width: 980px) {
					display: none;
				}
			}

			&.mobile-view {
				margin-top: 50px;

				@media (min-width: 981px) {
					display: none;
				}
			}
		}
	}

	// Order synopsis
	#order-synopsis-checkout {
		margin-top: -100px;

		@media (max-width: 980px) {
			margin-top: 0px;
		}

		.coupons-container {
			margin-bottom: 25px;
		}
	}
	// Order synopsis
}

.checkout-step3-page {
	.step3-container {
		display: grid;
		grid-template-columns: 1fr 450px;
		grid-template-rows: masonry;
		gap: 18px;

		@media (max-width: 980px) {
			grid-template-columns: 1fr;
		}

		@media (max-width: 480px) {
			display: block;
		}

		.left {
			max-width: 570px;
			width: 100%;
			margin-bottom: 75px;

			@media (max-width: 980px) {
				margin: auto;
			}
		}

		.shipping-options {
			margin-bottom: 25px;

			.shipping-options-title {
				width: 100%;
				font-size: 20px;
				font-family: 'Roboto Condensed';
				font-weight: 600;
				text-align: left;
				margin: 0;

				@media (max-width: 980px) {
					text-align: center;
				}
			}

			.select-shipping-type {
				padding-left: 15px;
				margin-top: 25px;
				margin-bottom: 30px;
				display: flex;
				gap: 35px;

				@media (max-width: 980px) {
					padding-left: 0px;
					margin-bottom: 25px;
				}

				@media (max-width: 550px) {
					flex-direction: column;
					align-items: center;
					gap: 25px;
				}

				label {
					align-items: center;
					color: #000;
					cursor: pointer;
					display: flex;
					gap: 10px;

					input[type='radio'] {
						display: block;
						height: 20px;
						width: 20px;

						&:checked {
							background-color: black;
							border-color: black;
						}

						@media (max-width: 980px) {
							margin-left: -1px;
						}
					}
				}
			}

			.shipping-options-container {
				display: flex;
				flex-direction: column;
				gap: 8px;

				.shipping-option {
					padding: 9px;
					border: 1px solid #e2e5ea;
					display: flex;
					align-items: center;
				}

				.shipping-option.courier {
					gap: 25px;
					height: 45px;

					@media (max-width: 450px) {
						gap: 12px;
					}

					.custom-checkbox .checkmark {
						top: 0px;

						&:after {
							border-width: 0 1px 1px 0;
						}
					}

					.title {
						width: 100%;
						display: flex;
						align-items: center;
						gap: 25px;

						@media (max-width: 450px) {
							gap: 12px;
						}

						.option-icon {
							width: 55px;
							text-align: center;
						}

						img {
							max-width: 55px;
							max-height: 28px;
							margin: auto;

							@media (max-width: 390px) {
								max-width: 35px;
							}
						}

						h4 {
							margin: 0px;
						}

						span {
							font-size: 15px;
							@media (max-width: 375px) {
								font-size: 14px;
							}
						}
					}
				}

				.shipping-option.store {
					height: 90px;
					gap: 50px;
					padding: 9px 20px;

					@media (max-width: 450px) {
						gap: 30px;
					}

					input[type='radio'] {
						display: block;
						-ms-transform: scale(1.7); /* IE 9 */
						-webkit-transform: scale(1.7); /* Chrome, Safari, Opera */
						transform: scale(1.7);
					}

					.title {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;

						@media (max-width: 450px) {
							gap: 12px;
						}

						h4 {
							font-size: 14px;
							margin: 0px;
						}

						span {
							font-size: 12px;
							@media (max-width: 375px) {
								font-size: 14px;
							}
						}
					}
				}

				.map-outer {
					height: 70vh;
				}
			}
		}

		.payment-options {
			margin-bottom: 25px;

			.payment-options-title {
				width: 100%;
				font-size: 20px;
				font-family: 'Roboto Condensed';
				font-weight: 600;
				text-align: left;
				margin: 0 0 35px;

				@media (max-width: 980px) {
					text-align: center;
				}
			}

			.payment-options-container {
				display: flex;
				flex-direction: column;
				gap: 12px;

				.payment-option {
					height: 90px;
					width: 100%;
					padding: 0 20px;
					display: flex;
					align-items: center;
					gap: 50px;
					border: 1px solid #e2e5ea;

					input[type='radio'] {
						display: block;
						-ms-transform: scale(1.7); /* IE 9 */
						-webkit-transform: scale(1.7); /* Chrome, Safari, Opera */
						transform: scale(1.7);
						cursor: pointer;
					}

					.title {
						width: 100%;
						display: flex;
						align-items: center;
						gap: 50px;

						@media (max-width: 450px) {
							gap: 12px;
						}

						// img {
						//     max-width: 35px;
						// }

						span {
							font-size: 14px;

							@media (max-width: 350px) {
								font-size: 13px;
							}
						}
					}

					&.selected {
						background-color: #f2f4f8;
					}
				}

				.sub-options {
					margin-top: -12px;

					.sub-option {
						height: 50px;
						background-color: #f8fafd;
						display: flex;
						align-items: center;
						border-top: 1px solid #f2f4f8;
						padding: 16px 20px;

						.name {
							width: 160px;
						}

						img {
							margin-right: 0;
							margin-left: auto;
							cursor: pointer;
						}

						@media (max-width: 480px) {
							font-size: 12px;
						}
					}
				}
			}
		}

		.gift-checkbox-container {
			padding: 9px;
			border: 1px solid #e2e5ea;
			display: flex;
			align-items: center;
			gap: 50px;
			height: 45px;
			margin-bottom: 35px;

			@media (max-width: 450px) {
				gap: 12px;
			}

			.custom-checkbox .checkmark {
				top: 0px;

				&:after {
					border-width: 0 1px 1px 0;
				}
			}

			.title {
				width: 100%;
				display: flex;
				align-items: center;
				gap: 15px;

				@media (max-width: 450px) {
					gap: 12px;
				}

				img {
					max-width: 55px;
					max-height: 28px;
				}

				span {
					font-size: 15px;

					@media (max-width: 350px) {
						font-size: 13px;
					}
				}
			}
		}

		.privacy-newsletter-checkbox {
			margin-bottom: 30px;

			.custom-checkbox {
				@media (max-width: 450px) {
					font-size: 14px;
				}

				.checkmark {
					top: 0px;

					@media (max-width: 500px) {
						top: 1px;
					}
				}
			}
		}

		.next-step {
			max-width: 375px;

			@media (max-width: 980px) {
				max-width: 100%;
			}

			button {
				width: 100%;
			}
			&.desktop-view {
				@media (max-width: 980px) {
					display: none;
				}
			}

			&.mobile-view {
				margin-top: 50px;

				@media (min-width: 981px) {
					display: none;
				}
			}
		}
	}
}
// END CHECKOUT PAGE

// Checkout Success Page
.checkout-success-page {
	.order-success-message {
		margin: auto;
		max-width: 475px;
		padding: 0 25px;

		@media (max-width: 435px) {
			padding: 0 8px;
		}

		img {
			margin: auto;
			margin-bottom: 18px;
		}

		h3 {
			font-size: 20px;
			text-align: center;
			margin: 0px;
			line-height: 26px;

			span {
				color: #0154ff;
			}
		}
	}

	.checkout-success-card {
		box-shadow: 0px 10px 30px #0000001a;
		max-width: 965px;
		margin: 75px auto 100px;
		padding: 35px 100px 50px;

		@media (max-width: 600px) {
			padding: 35px 20px 50px;
			margin: 45px auto 80px;
		}

		.order-information {
			h1 {
				font-family: 'Roboto Condensed';
				font-size: 20px;
				text-align: left;
				margin: 0 0 30px;

				@media (max-width: 768px) {
					text-align: center;
					margin: 0 0 20px;
					font-size: 16px;
				}
			}

			ul {
				display: grid;
				grid-template-columns: 1fr 1fr;
				row-gap: 30px;
				column-gap: 30px;
				justify-content: space-between;
				margin-bottom: 75px;

				@media (max-width: 768px) {
					grid-template-columns: 1fr;
				}

				h4 {
					font-size: 14px;
					font-family: 'Roboto Condensed';
					text-align: left;
					margin-bottom: 5px;
				}

				.giftcard-message-column {
					grid-column: 1 / span 2;
				}

				.checkout-receipt .payment {
					margin-bottom: 10px;
				}
			}
		}

		.customer-information-message {
			display: flex;
			justify-content: space-between;

			@media (max-width: 950px) {
				flex-direction: column;
			}

			.message {
				.store-hours {
					max-width: unset;
					padding: 0;

					@media (max-width: 950px) {
						margin-bottom: 40px;
					}
				}

				h1 {
					font-family: 'Roboto Condensed';
					font-size: 20px;
					text-align: left;
					margin: 0 0 30px;

					@media (max-width: 768px) {
						text-align: center;
						margin: 0 0 20px;
						font-size: 16px;
					}
				}

				p {
					color: #6e7486;
					width: 90%;

					@media (max-width: 768px) {
						font-size: 14px;
					}
				}
			}

			.track-order {
				width: 100%;
				max-width: 280px;
				align-self: center;

				@media (max-width: 950px) {
					align-self: flex-start;
				}

				@media (max-width: 480px) {
					max-width: unset;
				}

				button {
					max-width: 100%;
					padding: 0 !important;
					height: 55px;
					width: 100%;
				}
			}
		}
	}
}

// Checkout Failure Page
.checkout-failure-page {
	.order-failure-card {
		padding: 30px 50px;
		max-width: 375px;
		margin: auto;
		box-shadow: 0px 10px 30px #0000001a;
		border-radius: 5px;

		img {
			margin: auto;
		}

		h3 {
			text-align: center;
			font-size: 16px;
			margin: 30px auto 15px;
		}

		p {
			color: #6e7486;
			font-size: 14px;
			margin: 0px;
			text-align: center;
		}

		button {
			width: 100%;
			max-width: 280px;
			margin: 25px auto;
		}
	}
}

h1 {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: var(--bigsize);
	margin: 0;
}

h2 {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: var(--mediumsize);
	text-align: center;
	margin: 0;
}

h3 {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: var(--smallsize);
	line-height: 18px;
	margin: 0 0 6px;
}

h4 {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: var(--microsize);
	line-height: 17px;
	margin: 0 0 20px;
	text-align: center;
}

.top-page {
	position: relative;
	z-index: 200;
	padding-top: 37px;
}

#main-wrapper,
.main-wrapper {
	margin-top: 60px;

	@media (max-width: 767px) {
		&.home {
			margin-top: 0;
		}
	}
}

#anchor-links {
	text-align: center;
	margin: 24px 0 20px;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	color: #040707;
}

#scroll-to-top {
	cursor: pointer;
	margin: 0 8px;
}

#anchor-links a {
	font-size: 13px;
	margin: 0 25px;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	color: #040707;
}

#anchor-links a.active,
#anchor-links span.active {
	color: #fc4400;
	border-bottom: solid 1px #fc4400;
}

#anchor-links a:hover,
#anchor-links a:focus,
#anchor-links span:hover,
#anchor-links span:focus {
	color: #fc4400;
}

#anchor-links.sticky {
	position: fixed;
	left: 0;
	top: 59px;
	right: 0;
	background: #b6bac0;
	z-index: 2000;
	padding: 38px 0 7px;
	margin: 0;
	box-shadow: 0px 10px 30px #76767629;
}

.modal-dialog-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10000;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: baseline;
	overflow: auto;

	.modal-dialog {
		z-index: 10000;
		position: relative;
		animation: fadeIn 0.5s;

		@keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		.close-modal-btn {
			position: absolute;
			top: 20px;
			right: 20px;
			cursor: pointer;

			@media (max-width: 768px) {
				top: 15px;
				right: 15px;
			}
		}
	}
}

/* LOGIN */
#login-page {
	.account-breadcrumbs {
		@media (max-width: 1024px) {
			display: none;
		}
	}

	.login-page-container {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin: 77px auto 50px;

		@media (max-width: 1024px) {
			margin: 35px auto 50px;
		}

		@media (max-width: 575px) {
			gap: 10px;
		}

		.header {
			max-width: 575px;
			width: 100%;
			display: flex;
			justify-content: space-around;
			border-bottom: 3px solid #ebedeb;
			padding-bottom: 25px;
			margin-bottom: 35px;
			position: relative;

			@media (max-width: 575px) {
				gap: 10px;
			}

			h2 {
				font-size: 20px;
				font-weight: 600;
				margin: 0;
				color: #a3a9b9;
				cursor: pointer;

				@media (max-width: 575px) {
					font-size: 16px;
				}
			}

			h2.active {
				color: #000;
			}
		}

		.header:has(.active-first):before {
			content: '';
			position: absolute;
			top: 55px;
			left: 0px;
			margin: auto;
			width: 50%;
			height: 3px;
			background-color: #000;

			@media (max-width: 980px) {
				top: 51px;
			}

			@media (max-width: 600px) {
				width: 45%;
			}

			@media (max-width: 575px) {
				top: 48px;
			}

			@media (max-width: 336px) {
				top: 69px;
			}
		}

		.header:has(.active-second):before {
			content: '';
			position: absolute;
			top: 55px;
			right: 0px;
			margin: auto;
			width: 50%;
			height: 3px;
			background-color: #000;

			@media (max-width: 980px) {
				top: 51px;
			}

			@media (max-width: 600px) {
				width: 55%;
			}

			@media (max-width: 575px) {
				top: 48px;
			}

			@media (max-width: 336px) {
				top: 69px;
			}
		}

		.login-tab {
			max-width: 575px;
			width: 100%;

			.input-container {
				display: flex;
				flex-direction: column;
				gap: 12px;
				margin-bottom: 25px;

				input {
					border-radius: 0px;
					background-color: #f2f4f8;
					outline: none;
					border: none;
					font-size: 14px;
					height: 50px;
					display: flex;
					align-items: center;
					padding: 0 12px;
				}

				input::placeholder {
					opacity: 1;
					color: #666;
					font-size: 14px;
				}

				.login-password-input {
					position: relative;

					span {
						position: absolute;
						width: 35px;
						right: 10px;
						top: 10px;
					}
				}
			}

			.remember-forgot {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 40px;

				.remember-pwd {
					display: flex;
					gap: 8px;

					span {
						font-size: 14px;
					}

					input {
						display: block;
					}
				}

				.forgot-password {
					font-size: 14px;
					text-decoration: underline;
				}
			}

			.login-button {
				button {
					width: 100%;
				}
			}

			.social-providers {
				display: flex;
				justify-content: space-between;
				justify-content: center;
				gap: 10px;

				@media (max-width: 420px) {
					flex-direction: column;
					align-items: center;

					button {
						justify-content: start;

						img {
							margin-right: 25%;
						}
					}
				}

				button {
					width: 100%;
					max-width: 300px;

					img {
						max-width: 32px;
					}
				}
			}
		}

		.register-tab {
			max-width: 1000px;
			margin: auto;
			width: 100%;

			.options {
				display: grid;
				grid-template-columns: 375px 1fr;
				gap: 120px;

				@media (max-width: 980px) {
					grid-template-columns: 1fr;
					gap: 40px;
				}

				.guest-option {
					@media (max-width: 980px) {
						order: 2;
						display: flex;
						flex-direction: column;
						align-items: center;
						max-width: 500px;
						margin: auto;
					}

					p {
						margin: 0 0 20px 0;
						color: #6e7486;
						font-size: 16px;
					}

					.email-input {
						border-radius: 0px;
						background-color: #f2f4f8;
						outline: none;
						border: none;
						font-size: 14px;
						height: 50px;
						display: flex;
						align-items: center;
						padding: 0 12px;
						margin-bottom: 26px;
					}

					.email-input::placeholder {
						opacity: 1;
						color: #666;
						font-size: 14px;
					}

					button {
						max-width: 375px;
						width: 100%;
						border: 2px solid #000 !important;
						font-weight: 800;

						@media (max-width: 980px) {
							max-width: 100%;
						}
					}
				}

				.create-account-option {
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					@media (max-width: 980px) {
						order: 1;
						display: flex;
						flex-direction: column;
						align-items: center;
						max-width: 500px;
						margin: auto;
						text-align: left;
					}

					p {
						font-weight: 600;
						margin: 0 0 5px 0;
						color: #6e7486;
						font-size: 16px;

						@media (max-width: 980px) {
							width: 100%;
						}
					}

					ul {
						margin-left: 17px;

						li {
							font-size: 16px;
							color: #6e7486;
						}

						li::before {
							content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
							color: #6e7486; /* Change the color */
							font-weight: bold; /* If you want it to be bold */
							display: inline-block; /* Needed to add space between the bullet and the text */
							width: 1em; /* Also needed for space (tweak if needed) */
							margin-left: -1em; /* Also needed for space (tweak if needed) */
						}
					}

					button {
						max-width: 375px;
						width: 100%;
						margin-top: 30px;

						@media (max-width: 980px) {
							max-width: 100%;
						}
					}
				}
			}

			.register-form {
				max-width: 575px;
				width: 100%;
				margin: auto;

				.go-back {
					display: block;
					font-weight: 700;
					margin-bottom: 25px;
					cursor: pointer;

					span {
						font-size: 14px;
					}

					span::before {
						content: '';
						display: block;
						background: url('../images/arrow-right.svg') no-repeat;
						transform: rotate(180deg);
						width: 10px;
						height: 21px;
						float: left;
						margin: -4px 8px 0px 0px;
					}
				}

				.register-inputs {
					width: 100%;

					input {
						border-radius: 0px;
						background-color: #f2f4f8;
						color: #6e7486;
						outline: none;
						border: none;
						font-size: 14px;
						height: 50px;
						display: flex;
						align-items: center;
						padding: 0 12px;
						margin-bottom: 12px;
					}

					input::placeholder {
						opacity: 1;
						color: #666;
						font-size: 14px;
					}

					.register-phone-input {
						width: 100%;
						margin: 0 0 12px;

						.form-control {
							width: 100%;
							padding: 12px 12px 12px 50px;
						}
					}

					.register-password-input {
						position: relative;

						span {
							position: absolute;
							width: 35px;
							right: 10px;
							top: 10px;
						}
					}

					.gender-select {
						margin: 12px 0;
					}

					.birth-date .form-input {
						display: flex;
						flex-direction: column;
						background-color: #f2f4f8;

						label {
							margin-bottom: 0px;
							padding: 8px 4px 0px 12px;
							font-size: 12px;
							color: #6e7486;
						}
					}
				}

				.register-checkboxes {
					.accept-send-email {
						display: flex;
						gap: 8px;
						margin-top: 28px;
						margin-bottom: 13px;

						span {
							font-size: 14px;
							line-height: 18px;
						}

						input {
							display: block;
							width: 18px;
							height: 18px;
							border-color: #6f7484;
							margin: 0;
						}
					}

					.accept-policy {
						display: flex;
						gap: 8px;
						margin-bottom: 40px;

						span {
							font-size: 14px;
							line-height: 18px;
						}

						input {
							display: block;
							width: 18px;
							height: 18px;
							border-color: #6f7484;
							margin: 0;
						}
					}
				}

				.register-button {
					button {
						width: 100% !important;
					}
				}

				.social-providers {
					display: flex;
					// justify-content: space-between;
					justify-content: center;
					gap: 10px;

					@media (max-width: 420px) {
						flex-direction: column;
						align-items: center;

						button {
							justify-content: start;

							img {
								margin-right: 25%;
							}
						}
					}

					button {
						width: 100%;
						max-width: 300px !important;

						img {
							width: 27px;
						}
					}
				}
			}
		}
	}
}

/* LOGIN END */

/* GIFTCARD PAGE */
.giftcard-loader {
	.title-option {
		max-width: 250px;
		width: 100%;
		margin: auto;
	}

	.options {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		gap: 18px;

		@media (max-width: 650px) {
			grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
		}

		@media (max-width: 350px) {
			gap: 10px;
		}
	}

	.title-quantity {
		margin-top: 45px;
		max-width: 250px;
		width: 100%;
		margin: auto;
	}

	.quantity {
		margin: auto;
		max-width: 350px;
		width: 100%;
	}
}

#giftcard-page {
	.giftcard-content-wrapper {
		max-width: 600px;
	}

	.giftcard-header-banner {
		background-repeat: no-repeat;
		background-size: cover;
		background-color: #3ebeb9;
		text-align: left;

		.giftcard-container {
			height: 100%;
			position: relative;
			padding: 35px 0;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@media (max-width: 640px) {
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			.details {
				h2 {
					margin-top: 0;
					text-align: left;
					font-size: 32px;
					color: #ffffff;
					margin-bottom: 35px;
					font-family: 'Roboto Condensed';

					@media (max-width: 768px) {
						text-align: center;
					}

					@media (max-width: 450px) {
						font-size: 24px;
					}
				}
			}

			.giftcard-img {
				width: 350px;

				@media (max-width: 640px) {
					width: 215px;
				}
			}
		}
	}

	.giftcard-steps {
		max-width: 600px;
		position: relative;
		padding: 25px 0px 75px;

		@media (max-width: 615px) {
			padding: 45px 12px 75px;
		}

		.prev-step {
			left: -15vw;
			position: absolute;
			display: flex;
			align-items: center;
			gap: 20px;
			cursor: pointer;

			@media (max-width: 920px) {
				left: -100px;
			}

			@media (max-width: 820px) {
				left: -50px;
			}

			@media (max-width: 720px) {
				left: -20px;
			}

			@media (max-width: 650px) {
				left: 10px;
				gap: 10px;
			}

			@media (max-width: 420px) {
				top: 7px;
			}

			img {
				transform: rotate(180deg);
				@media (max-width: 650px) {
					width: 7px;
				}
			}

			span {
				font-size: 16px;

				@media (max-width: 650px) {
					font-size: 12px;
				}
			}
		}

		.giftcard-step {
			width: 100%;

			// Common styles for all steps
			input::placeholder {
				opacity: 1;
			}

			h3 {
				font-size: 24px;
				text-align: center;
				font-family: 'Roboto Condensed';

				@media (max-width: 640px) {
					font-size: 20px;
				}
			}

			.next-step-button {
				width: 100%;
				max-width: 375px;
				margin: 25px auto 0px;

				button {
					width: 100%;
					padding: 17px 0 !important;
				}
			}

			// Specific styles for each step
			.first-step {
				.receiver-tabs {
					width: 100%;
					display: flex;
					justify-content: space-around;
					border-bottom: 3px solid #ebedeb;
					padding-bottom: 17px;
					margin: 45px auto 38px;
					position: relative;

					@media (max-width: 575px) {
						gap: 10px;
					}

					h4 {
						font-size: 16px;
						font-weight: 600;
						margin: 0;
						color: #a3a9b9;
						cursor: pointer;
						@media (max-width: 575px) {
							font-size: 16px;
						}
					}
					h4.active {
						color: #000;
					}
				}

				.receiver-tabs:has(.active-first):before {
					content: '';
					position: absolute;
					top: 34px;
					left: 0px;
					margin: auto;
					width: 50%;
					height: 3px;
					background-color: #000;

					@media (max-width: 615px) {
						left: -12px;
						width: 52%;
					}
				}

				.receiver-tabs:has(.active-second):before {
					content: '';
					position: absolute;
					top: 34px;
					right: 0px;
					margin: auto;
					width: 50%;
					height: 3px;
					background-color: #000;

					@media (max-width: 615px) {
						right: -12px;
					}
				}

				.tab-content input {
					margin-bottom: 12px;
				}
			}

			.second-step {
				.giftcard-options-container {
					margin: 35px auto;
					display: flex;
					justify-content: center;

					@media (max-width: 650px) {
						display: block;
					}

					.giftcard-options {
						display: grid;
						gap: 18px;
						grid-template-columns: repeat(6, 1fr);

						@media (max-width: 650px) {
							grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
						}

						@media (max-width: 350px) {
							gap: 10px;
						}

						.option {
							padding: 14px 27px;
							border-radius: 5px;
							border: 1px solid #f3f3f3;
							cursor: pointer;

							@media (max-width: 650px) {
								padding: 14px 0px;
								display: flex;
								justify-content: center;
								align-items: center;
							}

							h4 {
								font-size: 17px;
								margin: 0;
							}
						}

						.option:hover {
							border: 1px solid #ccc;
							opacity: 0.7;
						}

						.option.active {
							border: 1px solid #000;
						}
					}
				}

				.giftcard-quantity {
					max-width: 180px;
					margin: auto;
					margin-bottom: 45px;
					text-align: center;

					h4 {
						margin: 0;
						font-size: 24px;
						padding-bottom: 16px;
					}
				}
			}

			.third-step {
				.giftcard-message {
					margin-top: 18px;
					height: 195px;
				}
			}

			.fourth-step {
				.input-container {
					margin: 27px 0 37px;

					input {
						margin-bottom: 12px;
					}

					.error {
						border: 1px solid red;
					}
				}
			}

			.fifth-step {
				.input-container {
					margin: 27px 0 37px;

					.billing-input {
						margin-bottom: 12px;

						input {
							color: #000;
						}

						.input-error {
							color: red;
						}
					}

					.phone-input {
						position: relative;

						label {
							position: absolute;
							top: 0;
							left: 48px;
							font-size: 10px;
							line-height: 15px;
							color: #717171;
						}
					}

					#countrySelect {
						margin-bottom: 12px;
					}
				}

				.payment-options {
					margin-bottom: 25px;

					.payment-options-title {
						width: 100%;
						font-family: 'Roboto Condensed';
						font-weight: 600;
						text-align: center;
						margin: 0 0 35px;

						@media (max-width: 980px) {
							text-align: center;
						}
					}

					.payment-options-container {
						display: flex;
						flex-direction: column;
						gap: 12px;

						.payment-option {
							height: 90px;
							width: 100%;
							padding: 0 20px;
							display: flex;
							align-items: center;
							gap: 50px;
							border: 1px solid #e2e5ea;

							input[type='radio'] {
								display: block;
								-ms-transform: scale(1.7); /* IE 9 */
								-webkit-transform: scale(1.7); /* Chrome, Safari, Opera */
								transform: scale(1.7);
							}

							.title {
								width: 100%;
								display: flex;
								align-items: center;
								gap: 50px;

								@media (max-width: 450px) {
									gap: 12px;
								}

								// img {
								//     max-width: 35px;
								// }

								span {
									font-size: 14px;

									@media (max-width: 350px) {
										font-size: 13px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
/* GIFTCARD PAGE ENDS */

/* Cart Page Start */
#cart-page {
	background-color: #f2f4f8;

	.breads {
		@media (max-width: 480px) {
			display: none;
		}
	}

	.cart-header {
		padding: 35px 0px 75px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 768px) {
			padding: 25px 0px 25px;
		}

		@media (max-width: 450px) {
			padding: 15px 0px 25px;
		}

		span {
			cursor: pointer;
			align-self: center;
			font-size: 16px;

			@media (max-width: 768px) {
				font-size: 0px;
			}
		}

		span::before {
			content: '';
			display: block;
			background: url('../images/arrow-right.svg') no-repeat;
			transform: rotate(180deg);
			width: 10px;
			height: 21px;
			float: left;
			margin: -4px 15px 0px 0px;
			cursor: pointer;
		}

		h2 {
			margin: auto;
			padding-right: 100px;
			text-align: left;
			font-size: 20px;

			@media (max-width: 1024px) {
				padding-right: 200px;
			}

			@media (max-width: 768px) {
				padding-right: 30px;
			}

			@media (max-width: 450px) {
				font-size: 16px;
			}
		}
	}

	.cart-body {
		padding-bottom: 80px;
		display: grid;
		grid-template-columns: minmax(450px, 670px) minmax(350px, 475px);
		gap: 16px;

		@media (max-width: 855px) {
			grid-template-columns: 1fr;
		}

		.cart-items-container {
			width: 100%;

			.cart-items {
				display: flex;
				flex-direction: column;
				gap: 17px;

				.cart-item-wrapper {
					position: relative;
					background-color: #fff;
					padding: 23px 15px 20px 13px;
					display: flex;
					gap: 45px;

					@media (max-width: 475px) {
						gap: 20px;
					}

					@media (max-width: 370px) {
						gap: 8px;
					}

					.remove-from-cart {
						position: absolute;
						top: 13px;
						right: 13px;

						@media (max-width: 500px) {
							top: 25px;
						}

						@media (max-width: 475px) {
							position: relative;
							align-self: flex-start;
							top: 0px;
							right: 0px;
							margin-left: auto;
							margin-top: 2px;
						}
					}

					.product-information {
						h4 {
							margin-bottom: 0px;
							font-size: 16px;
							text-align: left;
						}

						p {
							margin: 3px 0px 10px;
							font-size: 12px;
						}

						.color {
							font-size: 13px;
							line-height: 20px;
							display: flex;
							align-items: center;
							gap: 4px;

							.color-hex {
								margin-left: 5px;
							}
						}

						.dimension {
							font-size: 13px;
							line-height: 20px;
							margin-bottom: 10px;
						}
					}

					img {
						max-width: 80px;
					}

					.price {
						margin-left: auto;
						height: 100%;
						align-self: flex-end;
						font-size: 14px;

						.price-before {
							font-size: 12px;
							line-height: 16px;
							text-decoration: line-through;
							margin-right: 5px;
						}

						.actual-price {
							font-weight: bold;
						}

						@media (max-width: 475px) {
							position: absolute;
							height: unset;
							bottom: 18px;
							right: 5px;
						}
					}
					.bottom-right {
						@media (max-width: 475px) {
							padding-right: 5px;
						}
					}
				}
			}
		}

		.cart-synopsis-container {
			background-color: #fff;
			padding: 35px 35px 40px;

			@media (max-width: 960px) {
				padding: 35px 15px 40px;
			}

			@media (max-width: 855px) {
				padding: 35px 50px 40px;
			}

			@media (max-width: 575px) {
				padding: 35px 12px 40px;
			}

			.cart-synopsis-title {
				font-size: 16px;
				text-align: center;
				margin-bottom: 16px;
			}

			.order-totals {
				margin-top: 10px;
				display: flex;
				flex-direction: column;
				gap: 18px;

				.orderline {
					font-size: 16px;
					font-family: 'Roboto Condensed';
					display: flex;
					justify-content: space-between;

					@media (max-width: 420px) {
						font-size: 14px;
					}

					.total {
						font-family: 'Roboto';
						font-weight: 900;
						font-size: 18px;

						@media (max-width: 420px) {
							font-size: 16px;
						}
					}
				}
			}
		}

		.go-checkout {
			margin-top: 25px;
			width: 100%;

			button {
				width: 100% !important;
			}
		}

		.order-features-container {
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			gap: 14px;

			.feature {
				display: flex;
				align-items: center;
				gap: 25px;

				p {
					font-size: 11px;
					margin: 0;
				}
			}
		}
	}

	.no-cartitems {
		padding: 40px 0px 60px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 1024px) {
			flex-direction: column;
		}

		@media (max-width: 600px) {
			padding: 0px 0px 60px;
		}

		.information {
			@media (max-width: 1024px) {
				display: flex;
				align-items: center;
				flex-direction: column;
			}

			h1 {
				font-size: 26px;
				margin-bottom: 35px;

				@media (max-width: 600px) {
					font-size: 20px;
					text-align: center;
				}
			}
		}
	}
	// Order synopsis
	#order-synopsis-checkout {
		margin-top: 0px;

		@media (max-width: 980px) {
			margin-top: 0px;
		}
	}
	// Order synopsis
}
/* Cart Page Start */

// COUPONS CONTAINER ORDER SYNOPSIS
.coupons-container {
	background-color: #f2f4f8;
	padding: 10px 22px 10px;
	margin-bottom: 25px;

	.header {
		position: relative;
		cursor: pointer;

		.title {
			text-align: left;
			margin: 5px 0;
			font-size: 16px;
			color: #0154ff;

			@media (max-width: 450px) {
				font-size: 14px;
			}
		}

		&::after {
			content: '';
			background-image: url('../images/arrow-right.svg');
			background-repeat: no-repeat;
			position: absolute;
			margin: auto;
			width: 20px;
			height: 20px;
			transform: rotate(90deg);
			right: 10px;
			top: 3px;
			transition: 0.15s ease;

			@media (max-width: 400px) {
				right: 0px;
			}
		}

		&.active::after {
			content: '';
			background-image: url('../images/arrow-right.svg');
			background-repeat: no-repeat;
			position: absolute;
			margin: auto;
			width: 20px;
			height: 20px;
			transform: rotate(-90deg);
			right: 5px;
			top: -5px;
			transition: 0.15s ease;

			@media (max-width: 400px) {
				top: -8px;
				right: 3px;
			}
		}
	}

	.coupons-badges {
		margin-top: 25px;
		display: flex;
		gap: 19px;
		flex-wrap: wrap;

		.badge {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			border-radius: 21px;
			font-size: 14px;
			font-family: 'Roboto Condensed';
			font-weight: 700;
			color: #0154ff;
			padding: 7px 19px;
			border: 1px solid #0154ff;
			cursor: pointer;
			transition: 0.5s;

			img {
				cursor: pointer;
				width: 11px;
			}
		}

		.badge:hover {
			opacity: 0.7;
		}
	}

	.coupon-submit {
		margin-top: 16px;
		h4 {
			text-align: left;
			font-size: 16px;
			margin-bottom: 14px;
			font-weight: 400;
		}

		.coupon-input {
			display: flex;
			align-items: center;

			input {
				padding: 14px;
				background-color: #fff;
				height: 55px;
			}
			input::placeholder {
				opacity: 1;
			}

			button {
				width: 58px;
				height: 55px;
				padding: 0px !important;

				img {
					display: block;
					margin: auto;
				}
			}
		}
	}
}
// COUPONS CONTAINER ORDER SYNOPSIS

// GIFTCARDS CONTAINER ORDER SYNOPSIS
.giftcards-container {
	margin-bottom: 30px;
	background-color: #f2f4f8;
	padding: 10px 22px 10px;

	.header {
		position: relative;
		cursor: pointer;

		.title {
			text-align: left;
			margin: 5px 0;
			font-size: 16px;
			color: #0154ff;

			@media (max-width: 450px) {
				font-size: 14px;
			}
		}

		&::after {
			content: '';
			background-image: url('../images/arrow-right.svg');
			background-repeat: no-repeat;
			position: absolute;
			margin: auto;
			width: 20px;
			height: 20px;
			transform: rotate(90deg);
			right: 10px;
			top: 3px;
			transition: 0.15s ease;
			@media (max-width: 400px) {
				right: 0px;
			}
		}

		&.active::after {
			content: '';
			background-image: url('../images/arrow-right.svg');
			background-repeat: no-repeat;
			position: absolute;
			margin: auto;
			width: 20px;
			height: 20px;
			transform: rotate(-90deg);
			right: 5px;
			top: -5px;
			transition: 0.15s ease;
			@media (max-width: 400px) {
				top: -8px;
				right: 3px;
			}
		}
	}

	.giftcards-badges {
		margin-top: 25px;
		display: grid;
		gap: 19px;
		grid-template-columns: 1fr 1fr;

		@media (max-width: 500px) {
			grid-template-columns: 1fr;
		}

		.badge {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
			border-radius: 0px;
			font-size: 14px;
			font-family: 'Roboto Condensed';
			font-weight: 700;
			color: #000;
			padding: 7px 19px;
			border: 1px solid #000;
			transition: 0.3s;
			cursor: pointer;

			img {
				cursor: pointer;
				width: 11px;
			}
		}

		.badge:hover {
			opacity: 0.7;
		}
	}

	.giftcard-submit {
		margin-top: 16px;
		h4 {
			color: #0154ff;
			text-align: left;
			font-size: 16px;
			margin-bottom: 14px;
			font-weight: 400;
		}

		.giftcard-input {
			display: flex;
			align-items: center;

			input {
				padding: 14px;
				background-color: #fff;
				height: 55px;
			}
			input::placeholder {
				opacity: 1;
			}

			button {
				width: 58px;
				height: 55px;
				padding: 0px !important;

				img {
					display: block;
					margin: auto;
				}
			}
		}
	}
}
// GIFTCARDS CONTAINER ORDER SYNOPSIS

// LOYALTY POINTS CONTAINER ORDER SYNOPSIS
.loyalty-points-container {
	margin-top: 15px;
	padding: 20px;
	background-color: #f2f4f8;

	h5 {
		text-align: center;
		font-size: 12px;
		margin: 0px auto;
	}

	p {
		margin: 8px auto;
		text-align: center;
		font-size: 12px;
		display: flex;
		justify-content: center;
		gap: 2px;
	}

	.details-indicator {
		width: 18px;
		display: inline-block;
		cursor: pointer;
		margin-left: 4px;
		margin-bottom: -4px;

		&:hover {
			opacity: 0.7;
		}
	}

	.add {
		margin: 15px auto 0px;
		max-width: 175px;
		display: flex;
		justify-content: space-around;
		border: 1px solid #0154ff;
		padding: 10px 0px;

		span {
			font-size: 14px;
		}

		span:first-child {
			font-size: 20px;
			cursor: pointer;
		}

		span:nth-child(3) {
			font-size: 20px;
			cursor: pointer;
		}
	}
}
// LOYALTY POINTS CONTAINER ORDER SYNOPSIS

.cart-widget {
	.cart-close-icon {
		position: absolute;
		top: 40px;
		right: 25px;
		cursor: pointer;
		img {
			width: 30px;
		}
	}

	.cart-items-container {
		width: 100%;

		.cart-items {
			display: flex;
			flex-direction: column;
			gap: 17px;

			.cart-item-wrapper {
				position: relative;
				background-color: #fff;
				padding: 23px 15px 20px 13px;
				display: flex;
				gap: 20px;

				@media (max-width: 475px) {
					gap: 20px;
				}

				@media (max-width: 370px) {
					gap: 8px;
				}

				.remove-from-cart {
					position: absolute;
					top: 13px;
					right: 13px;

					@media (max-width: 500px) {
						top: 25px;
					}

					@media (max-width: 475px) {
						position: relative;
						align-self: flex-start;
						top: 0px;
						right: 0px;
						margin-left: auto;
						margin-top: 2px;
					}
				}

				.product-information {
					display: flex;
					flex-direction: column;
					gap: 15px;

					h4 {
						margin-bottom: 0px;
						font-size: 16px;
						text-align: left;
					}

					p {
						display: none;
					}

					.color {
						font-size: 13px;
						line-height: 20px;
						display: flex;
						align-items: center;
						gap: 4px;
						color: #6e7486;

						.color-hex {
							margin-left: 5px;
						}
					}

					.dimension {
						font-size: 13px;
						line-height: 20px;
						margin-bottom: 10px;
						color: #6e7486;
					}
				}

				img {
					max-width: 150px;
				}

				.price {
					margin-left: auto;
					height: 100%;
					align-self: flex-end;
					font-size: 14px;

					.price-before {
						font-size: 12px;
						line-height: 16px;
						text-decoration: line-through;
						margin-right: 5px;
					}

					.actual-price {
						font-weight: bold;
					}

					@media (max-width: 475px) {
						position: absolute;
						height: unset;
						bottom: 10px;
						right: 5px;
					}
				}

				.cart-item-error span {
					font-size: 10px;
				}
			}
		}
	}
}

/* HOMEPAGE */

#homepage {
	.espa-banner {
		display: flex;
		z-index: 9999;
		max-height: 100%;
		position: fixed;
		bottom: 6px;
		left: 6px;
		height: 50px;
		width: 260px;
		box-shadow: 1px 1px 6px 4px rgba(0, 0, 0, 0.221);
		border-radius: 3px;

		a,
		img {
			width: 100%;
			height: 100%;
			border-radius: 3px;
		}

		@media (max-width: 900px) {
			display: none;
		}
	}
}

.content-wrapper-home {
	max-width: 1160px;
}

.light-gray {
	background-color: rgba(244, 246, 249, 0.5);
}

.heroslider {
	position: relative;

	.swiper-button-prev {
		color: #fff;
		left: 60px;

		@media (max-width: 1024px) {
			left: 10px;
		}

		@media (max-width: 768px) {
			display: none;
		}
	}

	.swiper-button-prev:after {
		font-size: 20px;
	}

	.swiper-button-next {
		color: #fff;
		right: 60px;

		@media (max-width: 1024px) {
			right: 10px;
		}

		@media (max-width: 768px) {
			display: none;
		}
	}

	.swiper-button-next:after {
		font-size: 20px;
	}

	.swiper-pagination-bullet {
		width: 9px;
		height: 9px;
		background: #fff;
	}

	.content-overlay {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		.content {
			max-width: 1400px;
			height: 100%;
			margin: 0 auto;
			padding: 0 15px;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			padding: 0px 120px;
			color: #fff;

			.subtitle {
				font-size: 18px;
				margin: 10px 0;
				line-height: 35px;

				@media (max-width: 960px) {
					font-size: 16px;
					margin: 0;
					font-weight: 400;
				}

				@media (max-width: 450px) {
					font-size: 16px;
					margin: 0;
					font-weight: 400;
				}
			}

			.title {
				text-align: left;
				font-size: 34px;
				margin: 0 0 20px;
				line-height: 35px;

				@media (max-width: 960px) {
					font-size: 26px;
					margin: 10px 0 10px 0;
				}

				@media (max-width: 450px) {
					font-size: 20px;
					margin: 10px 0 10px 0;
				}
			}

			@media (max-width: 1200px) {
				padding: 0px 60px;
			}

			@media (max-width: 100px) {
				padding: 0px 35px;
			}

			@media (max-width: 960px) {
				padding: 0px 0px 0px 80px;
			}

			@media (max-width: 768px) {
				padding: 0px 0px 0px 40px;
			}

			@media (max-width: 650px) {
				padding: 12px;
			}

			@media (max-width: 450px) {
				display: flex;
				flex-flow: column;
				justify-content: flex-end;
				align-items: center;
				text-align: center;
				padding: 0px 16px 75px 16px;

				&:has(.boxnow-heroslider) {
					justify-content: center;
				}
			}

			@media (max-width: 650px) {
				.button {
					display: flex;
					justify-content: center;
				}
			}
			button {
				margin: 35px auto;

				@media (max-width: 650px) {
					margin: 5px auto;
				}
			}
		}
	}

	.home-video {
		pointer-events: none;
	}

	video,
	.home-video iframe {
		height: 100%;
		width: 100%;
		object-fit: cover;
		aspect-ratio: 0.8 / 1;

		@media (min-width: 651px) {
			aspect-ratio: 2 / 1;
			// max-height: 700px;
		}
	}

	img {
		width: 100%;

		@media (min-width: 651px) {
			height: 50vw;
			// max-height: 700px; // make it only when slides contains a video
		}
	}

	.boxnow-heroslider {
		@media (max-width: 1024px) and (min-width: 650px) {
			max-width: 350px;
		}
		@media (max-width: 650px) {
			margin-top: 225px;
		}
		h1 {
			font-size: 34px;
			line-height: 45px;
			font-family: 'Roboto Condensed';
			margin-bottom: 30px;
			text-align: left;
			max-width: unset;
			color: #fff;

			@media (max-width: 650px) {
				font-size: 24px;
				text-align: center;
				margin-bottom: 10px;
			}
		}

		p {
			font-size: 14px;
			line-height: 24px;
			text-align: left;
			margin: 0 0 24px 0;
			max-width: 475px;
			color: #fff;

			@media (max-width: 650px) {
				max-width: unset;
				text-align: center;
			}
		}
	}
}

.puzzle-banner-loader {
	margin: 30px 0 70px 0;
	.container {
		display: grid;
		column-gap: 16px;
		grid-template-columns: repeat(2, 1fr);
	}
}
.puzzlebanners {
	margin: 30px 0 70px 0;
	display: grid;
	// row-gap: 1px;
	column-gap: 16px;
	grid-template-columns: repeat(2, 1fr);

	.banner {
		position: relative;
		cursor: pointer;
		width: 100%;
		transition: all 0.1s ease-in-out;

		img {
			max-width: 100%;
			width: 100%;
			height: auto;
		}

		.info-overlay {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;

			.info-container {
				height: 100%;
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				h3 {
					padding: 0px 0px 8px 10px;
					font-size: 24px;
					font-family: 'Roboto Condensed';
					z-index: 10;
					font-weight: 400;
					color: #fff;

					@media (max-width: 600px) {
						font-size: 20px;
					}
				}

				.browse-icon {
					padding: 0px 10px 8px 0px;
				}
			}

			.info-container:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				background: linear-gradient(
					to bottom,
					rgba(255, 255, 255, 0) 0%,
					rgba(255, 255, 255, 0) 88%,
					rgba(255, 255, 255, 0.4) 100%,
					rgb(255, 255, 255) 100%
				);
			}
		}
	}

	.banner:hover {
		transform: scale(1.03);
	}

	@media (max-width: 700px) {
		.banner:nth-child(1) {
			order: 1;
		}
		.banner:nth-child(3) {
			order: 2;
		}

		.banner:nth-child(2) {
			order: 3;
		}

		a:nth-child(4) {
			order: 4;
		}

		a:nth-child(1) {
			order: 1;
		}
		a:nth-child(3) {
			order: 2;
		}

		a:nth-child(2) {
			order: 3;
		}

		a:nth-child(4) {
			order: 4;
		}
	}

	@media (max-width: 700px) {
		margin: 30px auto;
		row-gap: 9px;
		column-gap: 0px;
		grid-template-columns: unset;
	}
}
.sports-slider-loader {
	margin-bottom: 60px;
	.container {
		display: grid;
		column-gap: 16px;
		grid-template-columns: repeat(3, 1fr);

		@media (max-width: 992px) {
			gap: 8px;
			grid-template-columns: repeat(2, 1fr);
		}

		:nth-child(-n + 3) {
			height: 376px;
		}

		:nth-child(n + 4) {
			display: none;
		}
		@media (max-width: 992px) {
			:nth-child(-n + 3) {
				height: 172px;
			}
			:nth-child(n + 4) {
				display: block;
				height: 172px;
			}
		}
	}
}

.sportsslider {
	margin-bottom: 60px;
	position: relative;

	@media (max-width: 600px) {
		margin: 30px auto 50px;
		row-gap: 9px;
		column-gap: 0px;
		grid-template-columns: unset;
	}

	.title {
		font-family: 'Roboto Condensed';
		font-weight: 600;
		text-align: center;
		font-size: 34px;
		margin: 36px 0;

		@media (max-width: 500px) {
			font-size: 24px;
		}
	}

	.sports-swiper {
		height: 100%;
		padding-bottom: 40px;

		.swiper-wrapper {
			cursor: grab;
		}

		.swiper-slide {
			text-align: center;
			font-size: 18px;
			background: #fff;
			max-width: 375px;
			width: 100%;
			/* Center slide text vertically */
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			-webkit-align-items: center;
			align-items: center;
		}

		.swiper-slide img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			max-width: 100%;
			width: 100%;
			height: auto;
		}

		.swiper-button-prev,
		.swiper-button-next {
			background: #fff;
			border-radius: 100%;
			height: 32px;
			padding: 0 16px;

			&:after {
				font-size: 14px;
				font-weight: 800;
			}
		}

		.swiper-pagination {
			left: 1vw;
		}
	}

	.sports-container {
		display: grid;
		gap: 8px;
		grid-template-columns: repeat(2, 1fr);

		.sport {
			width: 100%;
			height: auto;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				display: block;
				object-fit: cover;
				max-width: 100%;
				width: 100%;
				height: auto;
			}
		}
	}
}

.brandshome {
	margin-bottom: 55px;

	.brands-container {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 65px;
		align-items: center;

		.brand-item {
			max-width: 127px;
			width: 100%;
			height: auto;

			img {
				display: block;
				object-fit: contain;
				max-width: 100%;
				width: 100%;
				height: auto;
			}

			@media (max-width: 768px) {
				max-width: 87px;
			}
		}

		@media (max-width: 900px) {
			gap: 25px;
			justify-content: space-around;
			flex-wrap: wrap;
		}
	}
}

.recommended-products {
	background-color: #f2f4f8;
	padding-top: 55px;
	padding-bottom: 60px;
	min-height: 730px;
	height: 100%;

	.recommended-products-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 900px) {
			justify-content: center;
			flex-direction: column;
			align-items: center;
			row-gap: 22px;
		}

		h2 {
			font-size: 34px;
			font-family: 'Roboto Condensed';
			@media (max-width: 600px) {
				font-size: 24px;
			}
		}

		.tab-buttons {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 11px;

			@media (max-width: 450px) {
				gap: 5px;
			}

			button {
				padding: 10px 20px 8px 20px;
				font-size: 16px;
				border-radius: 100px;
				border: 2px solid #6f7484;
				color: #6f7484;
				font-weight: 600;

				@media (max-width: 450px) {
					font-size: 14px;
					padding: 10px 15px 8px 15px;
				}

				@media (max-width: 350px) {
					font-size: 14px;
					padding: 10px 12px 8px 12px;
				}
			}

			button.active {
				background: #6f7484;
				color: #fff;
			}
		}
	}

	.recommended-products-container {
		margin: 50px 0px 40px 0px;
		// display: flex;
		// justify-content: center;
		// flex-wrap: wrap;
		// gap: 16px;

		.swiper {
			padding: 0 0 50px 0;

			.swiper-pagination-bullet {
				width: 13px;
				height: 13px;
			}

			.swiper-pagination-bullet-active {
				background-color: #6e7486;
			}

			.swiper-slide {
				text-align: center;
				font-size: 18px;
				background: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				height: auto;
				max-width: 275px;
				width: 100%;
			}

			.product-card {
				height: 100%;
			}
		}

		@media (max-width: 550px) {
			margin: 35px 0px 40px 0px;
			// display: grid;
			// grid-template-columns: repeat(2, 1fr);
			// gap: 7px;
		}

		@media (max-width: 350px) {
			// display: flex;
			// flex-direction: column;
			// justify-content: center;
			// align-items: center;
			// gap: 6px;
		}

		.product-card:hover {
			transform: scale(1.01);
		}
	}

	.recommended-products-footer {
		text-align: center;

		button {
			padding: 13px 50px;
			h3 {
				font-size: 21px;
				margin: 0px;

				@media (max-width: 500px) {
					font-size: 16px;
				}
			}
		}
	}
}

/* PRODUCT LIST PAGE STARTS HERE */

/*filters loader skeleton styles*/
.catalogfilter-loading-container {
	max-width: 265px;
	@media (max-width: 1024px) {
		display: none;
	}
}
.catalogfilter-loading-container.content-wrapper {
	margin: 0;
}
/*filters loader skeleton styles end*/

.category-page .breadcrumbs-container {
	height: 40px;
}

.category-list-breadcrumbs-border {
	background-color: #a4a9b8;
	height: 1px;
	border: none;
	margin: 0;

	@media (max-width: 1024px) {
		display: none;
	}
}

.filters-button {
	display: none;
	position: fixed;
	bottom: 120px;
	right: 18px;
	background-color: #000;
	border-radius: 50%;
	width: 55px;
	height: 55px;
	z-index: 10;

	@media (max-width: 1024px) {
		display: block;
	}
}
.filters-button::after {
	content: '';
	display: block;
	background: url('../images/filters-white.svg') no-repeat;
	width: 40px;
	height: 40px;
	margin: 19px 0px 0px 15px;
}

.category-list-container {
	display: flex;
	justify-content: space-between;
	padding: 50px 0;
	gap: 25px;

	@media (max-width: 1024px) {
		display: block;
		padding: 20px 0 50px 0;
	}

	@media (max-width: 600px) {
		padding: 35px 0 50px 0;
	}

	@media (max-width: 350px) {
		display: block;
	}

	.filters-dialog-overlay {
		width: 100%;
		max-width: 265px;

		@media (max-width: 1024px) {
			max-width: 100%;
		}

		@media (max-width: 1024px) {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: baseline;
			overflow: auto;
		}
		.filters-section {
			position: relative;
			@media (max-width: 1024px) {
				max-width: 768px;
				width: 100%;
				margin: 20px 0;
				background-color: #fff;
				padding: 30px 12px 30px 12px;
			}

			@media (max-width: 768px) {
				margin: 0;
				min-height: 100%;
			}

			.close-modal-btn {
				display: none;
				position: absolute;
				top: 13px;
				right: 17px;
				@media (max-width: 1024px) {
					display: block;
				}
			}

			.filter-action-buttons {
				display: none;
				bottom: 25px;
				gap: 8px;
				width: 100%;
				margin: 0 auto;
				justify-content: space-evenly;
				grid-template-columns: repeat(2, 200px);

				@media (max-width: 1024px) {
					display: grid;
				}

				@media (max-width: 450px) {
					grid-template-columns: repeat(2, 1fr);
				}

				button {
					font-size: 16px;
					padding: 20px 0 !important;
				}
			}

			.filters-title {
				margin: 0px 0px 15px;
				font-size: 24px;
				font-family: 'Roboto Condensed';
				font-weight: 600;
				text-align: left;

				@media (max-width: 1024px) {
					text-align: center;
					margin: 0px 0px 20px;
					padding-bottom: 20px;
					border-bottom: 2px solid #f3f3f3;
				}
			}

			.filters-clear {
				margin: 0px 0px 45px;
				font-size: 16px;
				font-weight: 500;
				text-align: left;
				text-decoration: underline;
				cursor: pointer;

				@media (max-width: 1024px) {
					display: none;
				}
			}

			.filter-category {
				margin: 0px 40px 45px 0px;

				@media (max-width: 1050px) {
					margin: 0px 10px 45px 0px;
				}

				.filter-category-name {
					font-weight: 'Roboto';
					margin: 0px 0px 18px;
					width: 100%;
					font-size: 16px;
					font-weight: 600;
					text-align: left;
					position: relative;
					cursor: pointer;

					.visible-values {
						cursor: pointer;
						padding: 10px;
						margin-right: 25px;
					}
				}
				.filter-category-name .visible-values:before {
					content: '';
					position: absolute;
					background-color: #959595;
					top: 0;
					bottom: 0;
					right: 0;
					margin: auto;
					width: 14px;
					height: 2px;
				}

				.filter-category-name .visible-values:after {
					transition: transform 0.5s;
					content: '';
					position: absolute;
					background-color: #959595;
					top: 0;
					bottom: 0;
					right: 0.5px;
					margin: auto;
					width: 13px;
					height: 2px;
					transform: rotate(90deg);
				}

				.filter-category-name .visible-values.active-filter:after {
					transform: rotate(0deg);
				}

				.filter-category-values {
					.disabled {
						pointer-events: none;
						opacity: 0.5;
					}
					.filter-value {
						display: block;
						position: relative;
						padding-left: 30px;
						margin-bottom: 17px;
						margin-left: 8px;
						cursor: pointer;
						font-size: 16px;
						color: #6e7486;
						-webkit-user-select: none;
						-moz-user-select: none;
						-ms-user-select: none;
						user-select: none;

						/* Hide the browser's default checkbox */
						input {
							position: absolute;
							opacity: 0;
							cursor: pointer;
							height: 0;
							width: 0;
						}

						/* Create a custom checkbox */
						.checkmark {
							position: absolute;
							top: 0;
							left: 0;
							height: 20px;
							width: 20px;
							border: 1px solid #b5bac3;
							border-radius: 3px;
						}

						/* When the checkbox is checked, add a blue background */
						input:checked ~ .checkmark {
							border: 1px solid #b5bac3;
							background-color: #fff;
							border-radius: 3px;
						}

						/* Create the checkmark/indicator (hidden when not checked) */
						.checkmark:after {
							content: '';
							position: absolute;
							display: none;
						}

						/* Show the checkmark when checked */
						input:checked ~ .checkmark:after {
							display: block;
						}

						/* Style the checkmark/indicator */
						.checkmark:after {
							left: 6px;
							top: 2px;
							width: 6px;
							height: 10px;
							border: solid #000;
							border-width: 0 1px 1px 0;
							-webkit-transform: rotate(45deg);
							-ms-transform: rotate(45deg);
							transform: rotate(45deg);
						}
					}

					.filter-value-color {
						padding: 8px 15px 8px 8px;
						margin-bottom: 4px;
						display: flex;
						align-items: center;
						gap: 15px;
						cursor: pointer;

						.colorbox {
							width: 20px;
							height: 20px;
							border-radius: 3px;
							border: 1px solid #ccc;
						}

						.color-title {
							font-size: 16px;
							color: #6e7486;
						}
					}

					.active {
						background-color: #f2f4f8;
					}
				}
				.unselect-all {
					font-family: 'Roboto Condensed';
					font-size: 14px;
					margin: 15px 0;
					text-decoration: underline;
					font-weight: 600;
					cursor: pointer;
				}
				.show-more {
					font-family: 'Roboto Condensed';
					font-size: 14px;
					margin: 15px 0;
					margin-left: 8px;
					text-decoration: underline;
					font-weight: 600;
					cursor: pointer;
				}
			}
		}
	}

	.products-section {
		width: 100%;
		.category-header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			@media (max-width: 1024px) {
				margin-bottom: 15px;
			}

			@media (max-width: 600px) {
				flex-direction: column;
				align-items: start;
			}

			.category-title {
				font-size: 24px;
				font-family: 'Roboto Condensed';
				font-weight: 600;
			}

			.view-options {
				display: flex;
				gap: 15px;

				@media (max-width: 700px) {
					margin-top: 12px;
					width: 100%;
					flex-direction: row;
					justify-content: flex-end;
				}

				@media (max-width: 360px) {
					gap: 8px;
					flex-wrap: wrap;
					justify-content: space-between;
				}

				.filters {
					display: none;
					background-color: transparent;
					border: 1px solid #e2e5ea;
					border-radius: 10px;
					font-weight: 600;
					font-size: 14px;
					padding: 12px 6px 11px 15px;
					margin-left: -2px;

					@media (max-width: 1024px) {
						display: block;
					}

					@media (max-width: 600px) {
						margin-right: auto;
					}

					@media (max-width: 360px) {
						flex: 0 0 100%;
					}
				}

				.filters::after {
					content: '';
					display: block;
					background: url('../images/filters.svg') no-repeat;
					width: 20px;
					height: 20px;
					float: right;
					margin: 1px 5px 0px 11px;
					padding: 0 5px;
				}

				.number-select {
					background-color: none;
					border: 1px solid #e2e5ea;
					border-radius: 10px;
					font-weight: 600;
					font-size: 14px;
					padding: 5px 10px;

					@media (max-width: 360px) {
						flex: 1;
					}
				}

				.sorting-select {
					background-color: none;
					border: 1px solid #e2e5ea;
					border-radius: 10px;
					font-weight: 600;
					font-size: 14px;
					padding: 5px 10px;

					@media (max-width: 360px) {
						flex: 1;
					}
				}
			}
		}

		.category-products-count {
			font-size: 15px;
			margin: 10px 0px;
			color: #333;
			text-align: left;

			@media (max-width: 600px) {
				font-size: 13px;
			}

			@media (max-width: 360px) {
				margin-top: 35px;
			}
		}

		.category-desc {
			p {
				font-size: 16px;
				color: #6e7486;
				max-width: 600px;
			}

			// @media (max-width: 1024px) {
			// 	display: none;
			// }
		}

		.product-list {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 10px;
			margin-top: 45px;

			@media (max-width: 1024px) {
				.product-card {
					max-width: 100% !important;
				}
			}

			@media (max-width: 800px) {
				grid-template-columns: repeat(2, 1fr);
				margin: auto;
			}

			@media (max-width: 360px) {
				margin-top: 25px;
			}

			@media (max-width: 350px) {
				grid-template-columns: repeat(1, 1fr);
				margin: 25px auto;
			}
		}
	}
}
/* PRODUCT LIST PAGE ENDS HERE */

/* PRODUCT CARD STARTS HERE */
.product-card {
	flex: 1;
	max-width: 280px;
	min-width: 250px;
	width: 100%;
	padding: 16px 20px 30px 15px;
	background-color: #ffffff;
	position: relative;
	transition: all 0.08s ease-in-out;
	box-shadow: 2px 5px 5px -1px rgba(232, 232, 232, 0.75);

	@media (max-width: 800px) {
		padding: 16px 20px 8px 15px;
	}

	@media (max-width: 575px) {
		padding: 16px 5px 8px 5px;
	}

	@media (max-width: 550px) and (min-width: 351px) {
		min-width: auto;
	}

	@media (max-width: 350px) {
		min-width: 100%;
	}

	.tags-container {
		width: calc(100% - 10px);
		position: absolute;
		left: 10px;
		z-index: 1;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		padding: 0 10px;

		.new-container {
			color: #0154ff;
			font-size: 16px;
			font-weight: 700;
			font-family: 'Roboto';
			margin-right: 14px;
		}

		.discount-tag {
			font-size: 12px;
			border-radius: 24px;
			background-color: #ff0145;
			padding: 2px 10px;
			color: #fff;
			font-family: 'Roboto';
			font-weight: 700;

			@media (max-width: 500px) {
				font-size: 12px;
				padding: 2px 10px;
				margin-left: -5px;
			}
		}

		.statuses-container {
			display: flex;
			flex-direction: column;
			gap: 3px;
			margin: 0 0 0 auto;
		}

		.tag {
			font-size: 10px;
			border-radius: 16px;
			padding: 0px 10px;
			line-height: 18px;
			color: #1c5d3f;
			background-color: #cef5e3;
		}

		&:has(.discount-container) {
			.new-container {
				display: none;
			}
		}
	}

	.product-img {
		position: relative;
		height: auto;
		margin-left: 15px;
		display: flex;
		justify-content: center;

		@media (max-width: 550px) and (min-width: 351px) {
			margin: auto;
			max-width: 144px;
			width: 100%;
		}

		@media (max-width: 450px) and (min-width: 351px) {
			padding: 20px 0 10px 0;
		}

		@media (max-width: 350px) {
			margin: auto;
			max-width: auto;
		}

		.img {
			display: block;
			object-fit: cover;
			max-width: 100%;
			width: 100%;
			height: auto;
		}

		.wishlist-icon {
			position: absolute;
			bottom: 0px;
			right: 0px;

			@media (max-width: 550px) and (min-width: 430px) {
				bottom: -10px;
				right: -30px;
			}

			@media (max-width: 430px) and (min-width: 351px) {
				bottom: -10px;
				right: -8%;
			}

			@media (max-width: 375px) and (min-width: 351px) {
				bottom: 0px;
				right: -4%;
			}

			button {
				background-color: #f3f3f3;
				padding: 8px;
				border-radius: 50%;
				transition: all 0.05s ease-in-out;
				position: relative;
				z-index: 1;

				@media (max-width: 450px) and (min-width: 351px) {
					padding: 4px;
				}

				img.active {
					fill: #000;
				}
			}

			button:hover {
				transform: scale(1.1);
			}
		}
	}

	.product-info {
		display: flex;
		justify-content: flex-end;
		flex-direction: column;

		a {
			padding-top: 15px;
		}

		h3 {
			line-height: 20px;
			font-size: 16px;
			margin: 0 0 12px 0;
			font-family: 'Roboto Condensed';
			font-weight: 600;

			@media (max-width: 550px) and (min-width: 351px) {
				font-size: 14px;
			}
		}

		h4 {
			text-align: left;
			font-size: 16px;
			font-weight: 400;
			margin: 0 0 10px 0;

			@media (max-width: 550px) and (min-width: 351px) {
				font-size: 12px;
			}

			p {
				margin: 0;
			}
		}

		.price-info {
			display: flex;
			flex-direction: column;
			margin-bottom: 15px;

			@media (max-width: 450px) and (min-width: 351px) {
				margin-bottom: 5px;
			}

			.price-before {
				font-size: 12px;
				font-weight: 500;
				padding-right: 10px;

				@media (max-width: 450px) and (min-width: 351px) {
					font-size: 12px;
				}
			}

			.price {
				font-size: 18px;
				font-weight: 700;

				@media (max-width: 450px) and (min-width: 351px) {
					font-size: 14px;
				}
			}
		}

		.dimension-info {
			.color-info {
				display: flex;
				gap: 3px;
				align-items: center;
				height: 20px;

				.color-img {
					display: block;
					width: 16px;
					height: 16px;
					border-radius: 100%;
				}

				.color-count {
					font-size: 10px;
					color: #6e7486;
				}
			}

			.display-colors {
				margin-top: 15px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				gap: 5px;

				.color {
					display: block;
					width: 16px;
					height: 16px;
					border-radius: 100%;
					transition: all 0.05s ease-in-out;
				}

				.color:hover {
					transform: scale(1.1);
				}
			}

			.sizes {
				align-self: baseline;
				margin-top: 30px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				column-gap: 3px;
				row-gap: 12px;
				justify-content: start;

				@media (max-width: 800px) {
					row-gap: 3px;
				}

				@media (max-width: 450px) {
					margin-top: 15px;
				}

				.size {
					font-size: 13px;
					background-color: #f2f4f8;
					border-radius: 7px;
					font-weight: 700;
					height: 18px;
					padding: 0 2px;
					text-align: center;
					min-width: 30px;
					letter-spacing: -0.39px;
					// transition: all .05s ease-in-out;
				}
				// .size:hover {
				//     transform: scale(1.1);;
				// }

				.size.disabled {
					opacity: 0.5;
					cursor: pointer;
					pointer-events: none;
				}
			}
		}
	}
}
/* PRODUCT CARD ENDS HERE */

.filters-banner-loader {
	.container {
		display: flex;
		justify-content: space-between;
		gap: 15px;
		flex-wrap: nowrap;
		overflow-x: auto;
		overflow-y: hidden;

		@media (max-width: 500px) {
			gap: 8px;
		}

		:nth-child(-n + 3) {
			height: 420px;
			width: 100%;
			max-width: 375px;
			min-width: 265px;
		}
	}
}
.filters-banner {
	margin: 60px 0;

	.filters-banner-container {
		display: flex;
		justify-content: space-between;
		gap: 15px;
		flex-wrap: nowrap;
		overflow-x: auto;

		@media (max-width: 500px) {
			gap: 8px;
		}

		.category {
			max-width: 375px;
			min-width: 265px;
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;

			@media (max-width: 1024px) {
				border: 1px solid #0154ff;
				padding: 10px 0;
			}

			h2 {
				text-align: center;
				font-size: 26px;
				background: transparent linear-gradient(290deg, #6b19f9 0%, #2253f5 100%) 0% 0% no-repeat padding-box;
				-webkit-background-clip: text;
				background-clip: text;
				-webkit-text-fill-color: transparent;

				@media (max-width: 1024px) {
					margin-bottom: 0px;
				}
			}

			.category-img {
				height: 350px;
				position: relative;
				display: flex;
				align-items: center;
				flex-direction: column;
				border: 1px solid #0154ff;
				padding: 10px 60px;

				@media (max-width: 1024px) {
					border: none;
					padding: 0px 0px 10px 0px;
				}

				.overflow-button {
					position: absolute;
					margin-top: -100px;
					bottom: 30px;
					z-index: 100;

					button {
						font-weight: 700;
						padding: 20px 40px !important;
					}
				}
			}
			.category-img:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 90%;
				background: linear-gradient(
					to bottom,
					rgba(255, 255, 255, 0) 0%,
					rgba(255, 255, 255, 0) 70%,
					rgba(255, 255, 255, 0.9) 100%,
					rgba(255, 255, 255, 1) 100%
				);
			}
		}
	}
}

.filtersModal {
	max-width: 768px;
	width: 100%;
	margin: 20px 0;
	background-color: #fff;

	@media (max-width: 768px) {
		margin: 0;
	}

	.filtersModal-container {
		padding: 40px 0 40px;
		background-color: #fff;
		.header {
			border-bottom: 1px solid #e2e5ea;
			padding-bottom: 22px;
			margin-bottom: 30px;

			.title {
				text-align: center;
				font-size: 24px;
				font-family: 'Roboto Condensed';
				font-weight: 600;

				span {
					background: transparent linear-gradient(290deg, #6b19f9 0%, #2253f5 100%) 0% 0% no-repeat padding-box;
					-webkit-background-clip: text;
					background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
		}

		.body {
			padding: 0 75px 0 80px;

			@media (max-width: 768px) {
				padding: 0 12px;
			}

			.filter-type-section {
				margin-bottom: 30px;

				h3 {
					font-size: 20px;
					font-family: 'Roboto Condensed';
					font-weight: 600;
				}

				.filter-values-container {
					margin-top: 16px;
					display: flex;
					flex-wrap: wrap;
					gap: 13px;

					.filter-value {
						font-size: 16px;
						font-family: 'Roboto Condensed';
						font-weight: 600;
						border-radius: 10px;
						border: 1px solid #e2e5ea;
						padding: 9px 12px 7px;
						cursor: pointer;
					}

					.filter-value.active {
						border: 1px solid #0154ff;
						background: transparent linear-gradient(290deg, #6b19f9 0%, #2253f5 100%) 0% 0% no-repeat padding-box;
						-webkit-background-clip: text;
						background-clip: text;
						-webkit-text-fill-color: transparent;
					}

					.filter-value.disabled {
						opacity: 0.5;
						pointer-events: none;
					}

					.filter-value:hover {
						// border: 1px solid #0154ff;
						// background: transparent linear-gradient(290deg, #6b19f9 0%, #2253f5 100%) 0% 0% no-repeat padding-box;
						// -webkit-background-clip: text;
						// background-clip: text;
						// -webkit-text-fill-color: transparent;
						opacity: 0.7;
					}
				}
			}
		}

		.footer {
			display: flex;
			justify-content: center;
			gap: 25px;
			margin-top: 40px;

			@media (max-width: 768px) {
				padding: 0 12px;
			}

			@media (max-width: 450px) {
				gap: 5px;
			}

			@media (max-width: 350px) {
				flex-direction: column;
				gap: 10px;
			}

			button {
				padding: 20px 0 !important;
				max-width: 205px;
				width: 100%;

				@media (max-width: 370px) {
					padding: 15px 0 !important;
					font-size: 13px;
				}

				@media (max-width: 350px) {
					max-width: 100%;
				}
			}
		}
	}
}

.coupon-poupup-modal {
	.modal-dialog-overlay {
		align-items: center;
	}

	@media (max-width: 768px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: auto;
		}
	}
	@media (max-width: 575px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: flex-end;
			overflow: auto;
		}
	}

	.coupon-popup {
		max-width: 575px;
		width: 100%;
		margin: 20px 0;
		background-color: #fff;

		@media (max-width: 575px) {
			margin: 0;
			border-radius: 20px 20px 0 0;
		}

		.coupon-popup-container {
			padding: 40px 0 40px;
			max-width: 375px;
			margin: auto;

			@media (max-width: 420px) {
				padding: 40px 12px 40px;
			}

			.header {
				margin-bottom: 25px;

				.title {
					margin-left: -10px;
					font-size: 24px;
					font-family: 'Roboto Condensed';
					font-weight: 600;

					@media (max-width: 420px) {
						margin-left: 1px;
					}

					@media (max-width: 360px) {
						font-size: 20px;
					}

					span {
						background: transparent linear-gradient(290deg, #6b19f9 0%, #2253f5 100%) 0% 0% no-repeat padding-box;
						-webkit-background-clip: text;
						background-clip: text;
						-webkit-text-fill-color: transparent;
						font-size: 34px;
					}
				}
			}

			.body {
				@media (max-width: 768px) {
					padding: 0 12px;
				}

				h4 {
					font-size: 16px;
					font-family: 'Roboto Condensed';
					margin: 0;
					text-align: left;
				}

				.coupon-features {
					margin: 20px 0 30px;
					display: flex;
					flex-direction: column;
					gap: 14px;

					li {
						display: flex;
						align-items: center;
						gap: 20px;

						h5 {
							font-size: 14px;
							margin: 0;
							text-align: left;
							font-weight: 400;
						}
					}
				}

				.email {
					border: 1px solid #0154ff;
				}

				input::placeholder {
					opacity: 1;
					color: #4d4d4d;
				}

				.checkboxes-container {
					margin: 20px 0;

					.custom-checkbox {
						font-size: 12px !important;
					}
				}
			}

			.footer {
				width: 100%;
				button {
					width: 100% !important;
					max-width: 100% !important;
				}
			}
		}
	}
}

.discounts-poupup-modal {
	.modal-dialog-overlay {
		align-items: center;
	}

	@media (max-width: 768px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: auto;
		}
	}
	@media (max-width: 575px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: flex-end;
			overflow: auto;
		}
	}

	.discounts-popup {
		max-width: 575px;
		width: 100%;
		margin: 20px 0;
		background-color: #fff;

		@media (max-width: 575px) {
			margin: 0;
			border-radius: 20px 20px 0 0;
		}

		.discounts-popup-container {
			padding: 40px 20px 40px;
			max-width: 450px;
			margin: auto;

			@media (max-width: 420px) {
				padding: 40px 20px 40px;
			}

			.header {
				margin-bottom: 45px;

				.title {
					font-size: 24px;
					font-family: 'Roboto Condensed';
					font-weight: 600;

					@media (max-width: 420px) {
						margin-left: 1px;
					}

					@media (max-width: 360px) {
						font-size: 20px;
					}

					span {
						background: transparent linear-gradient(290deg, #6b19f9 0%, #2253f5 100%) 0% 0% no-repeat padding-box;
						-webkit-background-clip: text;
						background-clip: text;
						-webkit-text-fill-color: transparent;
						font-size: 34px;
					}
				}
			}

			.body {
				h4 {
					font-size: 16px;
					font-family: 'Roboto Condensed';
					margin: 0;
					text-align: left;
				}

				.discounts-features {
					margin: 20px 0 30px;
					display: flex;
					flex-direction: column;
					gap: 14px;

					li {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 20px;
						margin-bottom: 20px;

						h5 {
							font-size: 17px;
							margin: 0;
							text-align: left;
							font-weight: 400;
						}

						span {
							font-size: 17px;
							color: #0154ff;
						}
					}
				}

				.email {
					border: 1px solid #0154ff;
				}

				input::placeholder {
					opacity: 1;
					color: #4d4d4d;
				}

				.checkboxes-container {
					margin: 20px 0;

					.custom-checkbox {
						font-size: 12px !important;
					}
				}
			}

			.footer {
				width: 100%;
				button {
					width: 100% !important;
					max-width: 100% !important;
				}
			}
		}
	}
}

.reorder-popup-modal {
	.modal-dialog-overlay {
		align-items: center;
	}

	@media (max-width: 768px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: auto;
		}
	}
	@media (max-width: 575px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: flex-end;
			overflow: auto;
		}
	}

	.reorder-popup {
		max-width: 575px;
		width: 100%;
		margin: 20px 0;
		background-color: #fff;

		@media (max-width: 575px) {
			margin: 0;
			border-radius: 20px 20px 0 0;
		}

		.reorder-popup-container {
			padding: 40px 20px 40px;
			max-width: 450px;
			margin: auto;

			@media (max-width: 420px) {
				padding: 40px 20px 40px;
			}

			.header {
				margin-bottom: 45px;

				.title {
					font-size: 24px;
					font-family: 'Roboto Condensed';
					font-weight: 600;

					@media (max-width: 420px) {
						margin-left: 1px;
					}

					@media (max-width: 360px) {
						font-size: 20px;
					}

					span {
						background: transparent linear-gradient(290deg, #6b19f9 0%, #2253f5 100%) 0% 0% no-repeat padding-box;
						-webkit-background-clip: text;
						background-clip: text;
						-webkit-text-fill-color: transparent;
						font-size: 34px;
					}
				}
			}

			.body {
				h4 {
					font-size: 16px;
					font-family: 'Roboto Condensed';
					margin: 0;
					text-align: left;
				}

				.discounts-features {
					margin: 20px 0 30px;
					display: flex;
					flex-direction: column;
					gap: 14px;

					li {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 20px;
						margin-bottom: 20px;

						h5 {
							font-size: 17px;
							margin: 0;
							text-align: left;
							font-weight: 400;
						}

						span {
							font-size: 17px;
							color: #0154ff;
						}
					}
				}

				.email {
					border: 1px solid #0154ff;
				}

				input::placeholder {
					opacity: 1;
					color: #4d4d4d;
				}

				.checkboxes-container {
					margin: 20px 0;

					.custom-checkbox {
						font-size: 12px !important;
					}
				}
			}

			.footer {
				width: 100%;
				button {
					width: 100% !important;
					max-width: 100% !important;
				}
			}
		}
	}
}

.loyaltyPoints-poupup-modal {
	.modal-dialog-overlay {
		align-items: center;
	}

	@media (max-width: 768px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: auto;
		}
	}
	@media (max-width: 575px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: flex-end;
			overflow: auto;
		}
	}

	.loyaltyPoints-popup {
		max-width: 575px;
		width: 100%;
		margin: 20px 0;
		background-color: #fff;

		@media (max-width: 575px) {
			margin: 0;
			border-radius: 20px 20px 0 0;
		}

		.loyaltyPoints-popup-container {
			padding: 40px 20px 40px;
			// max-width: 500px;
			margin: auto;

			@media (max-width: 420px) {
				padding: 40px 20px 40px;
			}

			.header {
				margin-bottom: 45px;

				.title {
					font-size: 24px;
					font-family: 'Roboto Condensed';
					font-weight: 600;

					@media (max-width: 420px) {
						margin-left: 1px;
					}

					@media (max-width: 360px) {
						font-size: 20px;
					}
				}
			}

			.body {
				h4 {
					font-size: 16px;
					font-family: 'Roboto Condensed';
					margin: 0;
					text-align: left;
				}

				.loyaltyPoints-features {
					margin: 20px 0 30px;
					display: flex;
					flex-direction: column;
					gap: 14px;

					li {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 20px;
						margin-bottom: 20px;
						border-bottom: 1px solid #0154ff;

						span {
							font-size: 17px;
							&.value {
								color: #0154ff;
							}
						}
					}
				}
			}

			.footer {
				width: 100%;
				button {
					width: 100% !important;
					max-width: 100% !important;
				}
			}
		}
	}
}

.giftcard-banner {
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #3ebeb9;
	display: flex;
	flex-direction: column;
	text-align: left;
	min-height: 405px;

	@media (max-width: 768px) {
		margin-bottom: 0px;
	}

	.giftcard-content-wrapper {
		max-width: 965px;

		.giftcard-container {
			height: 100%;
			position: relative;
			padding: 100px 0;

			@media (max-width: 768px) {
				padding: 30px 0 130px 0;
			}

			.details {
				max-width: 320px;

				@media (max-width: 800px) {
					max-width: 280px;
				}

				@media (max-width: 768px) {
					text-align: center;
					max-width: 100%;
				}

				h2 {
					margin-top: 0;
					text-align: left;
					font-size: 32px;
					color: #ffffff;
					margin-bottom: 35px;
					font-family: 'Roboto Condensed';

					@media (max-width: 768px) {
						text-align: center;
					}
				}

				p {
					font-size: 18px;
					line-height: 24px;
					color: #ffffff;
					margin-bottom: 35px;
				}

				button {
					font-weight: 700;

					@media (max-width: 768px) {
						position: absolute;
						bottom: 20px;
						left: 0px;
						right: 0px;
						max-width: 300px;
						margin: auto;
					}
				}
			}

			.giftcard-img {
				position: absolute;
				top: 60px;
				right: 0px;

				@media (max-width: 768px) {
					position: relative;
					top: 0px;
					right: 0px;
					max-width: 250px;
					margin: auto;
				}

				img {
					transform: matrix(0.99, 0.16, -0.16, 0.99, 0, 0);
					margin: auto;
				}
			}
		}
	}
}

.salesproducts {
	margin-bottom: 55px;
	background-color: #fff;

	@media (max-width: 750px) {
		margin-bottom: 25px;
	}

	.content-wrapper {
		@media (max-width: 750px) {
			padding: 0;
		}
	}

	.salesproducts-loader {
	}

	.banner-img {
		width: 100%;
		position: relative;
		aspect-ratio: 72/25;

		@media (max-width: 750px) {
			height: 230px;
		}

		img {
			width: 100%;
			height: 100%;
			aspect-ratio: 72/25;

			@media (max-width: 750px) {
				object-fit: cover;
			}
		}

		.triangle-up {
			display: none;
			position: absolute;
			background-image: linear-gradient(rgba(#fff, 1), rgba(#fff, 1));
			clip-path: polygon(50% 0, 100% 100%, 0 100%);
			width: 100%;
			height: 40px;
			bottom: 0px;

			@media (max-width: 750px) {
				display: block;
			}
		}
	}

	.salesproducts-slider-container {
		min-height: 415px;
		height: 100%;
	}

	.swiper {
		width: 100%;
		height: 100%;
		max-width: 850px;
		margin-top: -80px;
		position: relative;

		@media (max-width: 750px) {
			margin-top: 0px;
			max-width: 100%;
			margin-bottom: 40px;
		}

		.swiper-wrapper {
			background: transparent;

			.swiper-slide {
				height: auto;
				text-align: center;
				font-size: 18px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				-webkit-justify-content: center;
				justify-content: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				-webkit-align-items: center;
				align-items: center;
				position: relative;
				margin: 0 0 50px 0;

				@media (max-width: 750px) {
					margin: 0 0 20px 0;
				}
			}
		}
		.swiper-button-prev {
			@media (max-width: 750px) {
				display: none;
			}
		}
		.swiper-button-prev:after {
			font-size: 20px;
		}

		.swiper-button-next {
			@media (max-width: 750px) {
				display: none;
			}
		}
		.swiper-button-next:after {
			font-size: 20px;
		}

		.swiper-pagination-bullet-active {
			background: #6e7486;
		}

		.swiper-pagination {
			@media (max-width: 750px) {
				margin-top: 20px;
			}
		}
	}

	.product-large-card {
		position: relative;
		max-width: 730px;
		padding: 60px 85px 70px 100px;
		width: 100%;
		height: 100%;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px -10px 35px #00000029;
		display: flex;
		justify-content: space-between;

		@media (max-width: 750px) {
			padding: 40px 12px 0 12px;
			box-shadow: none;
			display: block;
		}

		.product-details {
			max-width: 320px;
			width: 320px;
			position: relative;

			@media (max-width: 750px) {
				max-width: 100%;
				width: 100%;
			}

			h2 {
				margin: 0;
				text-align: left;
				font-size: 32px;
				font-family: 'Roboto Condensed';

				@media (max-width: 750px) {
					font-size: 24px;
					max-width: 75%;
				}
			}

			h4 {
				margin: 10px 0 0 0;
				text-align: left;
				font-size: 18px;
				line-height: 24px;
				max-height: 48px;
				font-family: 'Roboto Condensed';
				font-weight: 400;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				display: -webkit-box;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-bottom: 24px;

				@media (max-width: 750px) {
					max-width: 75%;
				}
			}

			@media (max-width: 750px) {
				font-size: 16px;
			}

			p {
				margin: 0;
			}
		}

		.img-container {
			display: flex;
			gap: 25px;

			img {
				width: 150px;
			}

			.tags-prices {
				align-self: flex-end;
				margin-bottom: 25px;

				@media (max-width: 750px) {
					position: absolute;
					top: -15px;
					right: 5px;
				}

				.tags-container {
					display: flex;
					align-items: baseline;
					justify-content: space-between;
					font-size: 16px;
					font-weight: 700;
					font-family: 'Roboto';

					.new-container {
						color: #0154ff;
						margin-right: 14px;
						order: 1;
					}

					.discount-container {
						order: 2;

						.discount-tag {
							padding: 3px 8px;
						}
					}

					.statuses-container {
						display: none;
					}

					@media (max-width: 480px) {
						flex-direction: column;
						align-items: center;
						gap: 6px;

						.new-container {
							margin: 0;
						}
					}
				}

				.price-info {
					margin-top: 20px;
					display: flex;
					align-items: center;

					@media (max-width: 750px) {
						display: flex;
						flex-direction: column;
						margin-top: 8px;
						gap: 8px;
					}

					.price-before {
						font-size: 18px;
						text-decoration: line-through;
						padding-right: 10px;

						@media (max-width: 750px) {
							font-size: 18px;
						}
					}

					.price {
						font-size: 24px;
						font-weight: 700;

						@media (max-width: 400px) {
							font-size: 21px;
						}
					}
				}
			}
		}

		.sizes-container {
			@media (max-width: 750px) {
				display: flex;
				flex-direction: column;
				align-items: center;
				background-color: #f5f7f6;
				z-index: 1000;
				width: 100%;
				min-height: 30px;
				justify-content: center;
				left: 0;
				bottom: 0;
				border-radius: 8px;
				margin-bottom: 20px;
			}

			.toggle-sizes {
				display: none;
				margin-top: 5px;
				width: 100%;
				justify-content: center;

				img {
					width: 10px;
				}

				.show {
					transform: rotate(90deg);
				}

				.close {
					transform: rotate(-90deg);
				}

				@media (max-width: 750px) {
					display: flex;
				}
			}

			.sizes {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				column-gap: 11px;
				row-gap: 15px;

				@media (max-width: 450px) {
					margin-top: 15px;
				}

				.size {
					font-size: 16px;
					border: 1px solid #e2e5ea;
					width: 50px;
					height: 35px;
					text-align: center;
					padding: 7px 9px 8px 9px;
					border-radius: 10px;
					font-weight: 700;
				}

				.size.disabled {
					opacity: 0.5;
					cursor: pointer;
					pointer-events: none;
				}

				@media (max-width: 750px) {
					margin: 0 auto;
					padding: 35px 10px 30px 10px;
					max-width: 350px;
					width: 100%;
					display: grid;
					grid-template-columns: repeat(5, 1fr);
				}
			}
		}
	}
}

.discount-code {
	width: 100%;
	height: 350px;
	background-image: url(../images/discount-bg.svg) !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
	margin-bottom: 60px;
	position: relative;
	background-color: #0154ff;

	.discount-icon {
		position: absolute;
		width: 100%;
		top: -30px;
		display: flex;
		justify-content: center;
	}

	@media (max-width: 600px) {
		background-size: cover;
		margin-bottom: 20px;
	}

	.discount-code-container {
		margin: auto;
		padding: 60px 0 50px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 575px;
		width: 100%;

		@media (max-width: 600px) {
			padding: 55px 0 20px 0;
		}

		.discount-code-title {
			font-size: 32px;
			color: #ffffff;
			font-family: 'Roboto Condensed';
			margin: 20px 0;
			font-weight: 700;

			@media (max-width: 500px) {
				font-size: 26px;
			}
		}

		.discount-code-description {
			font-size: 18px;
			color: #ffffff;
			font-weight: 300;
			margin: 0 0 20px;

			@media (max-width: 500px) {
				font-size: 20px;
				line-height: 24px;
			}
		}

		.input-discount {
			width: 100%;

			input {
				background: #f2f4f8 0% 0% no-repeat padding-box;
				border: 1px solid #5171b4;
				border-radius: 0px;
				padding: 0px 20px;
				height: 55px;
			}

			input::placeholder {
				opacity: 1;
			}
		}

		button {
			width: 100%;
			margin-top: 17px;
		}
	}
}

.fixed-buttons-homepage {
	position: fixed;
	bottom: 100px;
	right: 18px;
	z-index: 5000;

	@media (max-width: 1024px) {
		bottom: 100px;
	}

	.buttons-container {
		display: flex;
		flex-direction: column;
		gap: 30px;

		button {
			border-radius: 100%;
			padding: 10px;
			width: 63px;
			height: 63px;
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow: 0px 3px 6px #00000029;
			&:hover {
				opacity: 0.7;
			}

			@media (max-width: 1024px) {
				width: 55px;
				height: 55px;
			}
		}

		button.telephone {
			background: transparent linear-gradient(222deg, #6b19f9 0%, #2253f5 100%) 0% 0% no-repeat padding-box;
		}

		button.scrollToTop {
			background-color: #fff;

			img {
				transform: rotate(-90deg);
			}
		}
	}
}

.navigation-page {
	margin: 17px 0;
	h1 {
		font-size: 20px;
		font-weight: 800;
		line-height: 24px;
		color: #000;
		text-align: left;
		margin: 0 0 7px;
	}
	h4 {
		font-size: 16px;
		font-weight: 600;
		margin: 0 0 20px;
		text-align: left;

		span {
			font-weight: 800;
			color: #26b287;
		}
	}

	.sidebar a {
		width: 100%;
		font-family: 'Roboto Condensed';
		font-size: 16px;
		font-weight: 800;
		line-height: 25px;
		letter-spacing: 0.01rem;
		color: #000;
		padding: 12px 0px 17px;
		border-radius: 0px;
		border-bottom: 1px solid #f3f3f3;
		display: flex;
		gap: 24px;
		transition: 0.1s ease-out;
	}

	.sidebar a:hover {
		background-color: #fff;
		font-weight: 700;
		box-shadow: 0px 10px 30px #00000029;
	}
	.active-side {
		background-color: #fff;
		font-weight: 700;
		box-shadow: 0px 10px 30px #00000029;
		padding: 17px 5px 18px 20px !important;
	}

	.logout-button button {
		width: 100%;
		font-weight: 800;
		font-family: 'Roboto Condensed';
		padding: 17px 0 16px;
		border: 1px solid #000;
		margin: 30px 0;
	}
}
/* ACCOUNT PAGE */

#account-page {
	background-color: #f2f4f8;

	@media (max-width: 575px) {
		background-color: #fff;
	}

	.account-page {
		padding: 25px 0 60px;

		@media (max-width: 575px) {
			padding: 0 0 60px;
		}

		.account-page-container {
			margin-top: 37px;
			display: flex;
			justify-content: space-between;
			gap: 35px;

			@media (max-width: 900px) {
				justify-content: center;
			}

			@media (max-width: 575px) {
				margin-top: 22px;
			}

			.sidenavigation {
				max-width: 375px;
				width: 100%;
				@media (max-width: 900px) {
					display: none;
				}

				.greeting-user {
					padding-left: 20px;
				}

				.sidebar a {
					width: 100%;
					font-family: 'Roboto Condensed';
					font-size: 16px;
					font-weight: 800;
					line-height: 22px;
					letter-spacing: 0.01rem;
					color: #000;
					padding: 12px 5px 12px 15px;
					margin-bottom: 15px;
					border-radius: 0px;
					display: flex;
					gap: 24px;
					transition: 0.1s ease-out;
				}

				.sidebar a:hover {
					background-color: #fff;
					font-weight: 700;
					box-shadow: 0px 10px 30px #00000029;
				}
				.active-side {
					background-color: #fff;
					font-weight: 700;
					box-shadow: 0px 10px 30px #00000029;
					padding: 17px 5px 18px 15px !important;
				}
			}

			.logout-button button {
				width: 100%;
				font-weight: 800;
				font-family: 'Roboto Condensed';
				padding: 17px 0 16px;
				border: 1px solid #000;
				margin-top: 5px;
			}

			.account-page-content {
				width: 100%;
				min-width: 550px;
				max-width: 670px;

				@media (max-width: 900px) {
					min-width: unset;
				}
			}

			.main-content {
				background: #fff;
				padding: 36px 25px;
				box-shadow: 0px 10px 30px #00000029;
				border-radius: 0px;

				@media (max-width: 575px) {
					box-shadow: none;
					padding: 0px;
				}

				.action-button {
					margin-top: 10px;
					width: 100%;
					display: flex;
					justify-content: end;

					@media (max-width: 900px) {
						display: block;
						margin-top: 25px;
					}

					button {
						min-width: 270px;
						padding: 17 0 16px !important;

						@media (max-width: 900px) {
							width: 100%;
						}
					}
				}
			}

			.main-content:nth-child(n + 2) {
				margin-top: 30px;
			}
		}
	}

	h1 {
		font-size: 16px;
		font-weight: 800;
		line-height: 24px;
		color: #000;
		text-align: center;
	}

	h2 {
		font-size: 20px;
		font-weight: 800;
		margin: 0 0 5px;
		text-align: left;
	}

	h4 {
		font-size: 16px;
		font-weight: 600;
		margin: 0 0 20px;
		text-align: left;

		span {
			color: #26b287;
		}
	}
}
/* ACCOUNT PAGE */

/* CMS LAYOUT PAGE */

#cms-page {
	background-color: #f2f4f8;

	.cms-page {
		padding: 25px 0 60px;

		@media (max-width: 575px) {
			padding: 5px 0 60px;
		}

		.cms-page-container {
			margin-top: 37px;
			display: flex;
			justify-content: space-between;
			gap: 35px;

			@media (max-width: 900px) {
				flex-direction: column;
				align-items: center;
				margin-top: 0px;
			}

			@media (max-width: 575px) {
				margin-top: 22px;
			}

			.sidenavigation {
				max-width: 275px;
				width: 100%;
				@media (max-width: 900px) {
					max-width: 100%;
				}

				.cms-heading {
					padding: 0 0 40px 10px;

					@media (max-width: 900px) {
						padding: 0 0 10px 5px;
					}
				}

				.sidebar a {
					width: 100%;
					font-family: 'Roboto Condensed';
					font-size: 16px;
					font-weight: 800;
					line-height: 22px;
					letter-spacing: 0.01rem;
					color: #000;
					padding: 12px 5px 12px 15px;
					margin-bottom: 15px;
					border-radius: 0px;
					display: flex;
					gap: 24px;
					transition: 0.1s ease-out;
				}

				.sidebar a:hover {
					background-color: #fff;
					font-weight: 700;
					box-shadow: 0px 10px 30px #00000029;
				}
				.active-side {
					background-color: #fff;
					color: #0154ff !important;
					font-weight: 700;
					box-shadow: 0px 10px 30px #00000029;
					padding: 17px 5px 18px 15px !important;
				}
			}

			.logout-button button {
				width: 100%;
				font-weight: 800;
				font-family: 'Roboto Condensed';
				padding: 17px 0 16px;
				border: 1px solid #000;
				margin-top: 5px;
			}

			.cms-page-content {
				width: 100%;
				min-width: 550px;
				max-width: 750px;

				@media (max-width: 900px) {
					min-width: unset;
				}
			}

			.main-content {
				background: #fff;
				padding: 36px 25px;
				box-shadow: 0px 10px 30px #00000029;
				border-radius: 0px;

				@media (max-width: 575px) {
					box-shadow: none;
					padding: 0px;
				}

				.action-button {
					margin-top: 10px;
					width: 100%;
					display: flex;
					justify-content: end;

					@media (max-width: 900px) {
						display: block;
						margin-top: 25px;
					}

					button {
						min-width: 270px;
						padding: 17 0 16px !important;

						@media (max-width: 900px) {
							width: 100%;
						}
					}
				}
			}

			.main-content:nth-child(2) {
				margin-top: 30px;
			}
		}
	}

	h1 {
		font-size: 16px;
		font-weight: 800;
		line-height: 24px;
		color: #000;
		text-align: center;
	}

	h2 {
		font-size: 20px;
		font-weight: 800;
		margin: 0 0 5px;
		text-align: left;
	}

	h4 {
		font-size: 16px;
		font-weight: 600;
		margin: 0 0 20px;
		text-align: left;

		span {
			color: #26b287;
		}
	}
}
/* CMS PAGE END*/

.registation-heading {
	font-size: 15px;
}

.registation-table {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
}

.registation-cell {
	font-size: 10px;
}

.registation-cell span {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: 20px;
	display: block;
	border-left: solid 1px #040707;
	padding: 2px 12px;
	width: 50px;
}

.registation-cell:first-child span {
	border-left: none;
}

.owl-carousel .owl-item img {
	margin: auto;
	max-width: 100%;
	height: 300px;
	object-fit: contain;
}

.raffle-carousel .owl-dots {
	text-align: center;
	margin-top: -40px;
	position: relative;
}

.raffle-carousel .owl-dot {
	background: #f1f1f1 !important;
	width: 16px;
	height: 16px;
	margin: 0 5px;
	border-radius: 50%;
	cursor: pointer;
}

.raffle-carousel .owl-dot.active {
	background: #fc4400 !important;
}

.raffle-carousel .owl-nav button {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 225px;
	background-image: url(../images/slider-arrow.svg) !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
}

.raffle-carousel .owl-nav button.owl-prev {
	transform: rotate(-180deg);
	left: 120px;
}

.raffle-carousel .owl-nav button.owl-next {
	right: 120px;
}

.raffle-category {
	padding: 34px 0 45px;
}

.raffle-products {
	margin-top: 35px;
}

.raffle-prod {
	display: flex;
	align-items: center;
	margin-bottom: 35px;
	position: relative;
}

.raffle-prod a {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
}

.raffle-prod-img {
	background-color: #f4f6f9;
	border-radius: 25px;
	box-shadow: 2px 7px 29px #434b5759;
	width: 108px;
	height: 108px;
	padding: 6px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.raffle-prod-img img {
	max-width: 100%;
}

.raffle-prod-img .brand-icon {
	margin-bottom: 4px;
}

.raffle-prod-details {
	margin-left: 42px;
	flex: 1;
}

.default-btn {
	width: 127px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 11px;
	line-height: 13px;
	border-radius: 13px;
	box-shadow: 2px 7px 29px #434b5759;
}

.default-btn span {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	margin-left: 5px;
}

.black-btn {
	color: #fff;
	background-color: #040707;
}

.white-btn {
	color: #040707;
	background-color: #fff;
}

.raffle-prod-price {
	font-size: 15px;
	margin-bottom: 8px;
}

.go-to-prod {
	margin-left: auto;
	width: 77px;
	height: 72px;
	background-image: url(../images/arrow-right-shadow.svg);
	background-position: center;
	background-repeat: no-repeat;
}

.raffle-prod:hover > .go-to-prod {
	-webkit-animation: bounceright 0.3s alternate ease 4 forwards;
	animation: bounceright 0.3s alternate ease 4 forwards;
}

.raffle-prod:hover > .go-to-prod {
	display: block;
}

.raffle-finished .raffle-prod-details,
.raffle-finished img {
	opacity: 0.3;
}

#recent {
	background-color: #f4f6f9;
}

#recent .raffle-prod-img {
	background-color: #fff;
}

.load-more {
	text-align: center;
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: 16px;
	cursor: pointer;
	margin-top: 58px;
	display: none;
}

.load-more.show {
	display: block;
}

.load-more span {
	display: block;
	margin-top: 10px;
}

/* PRODUCT PAGE */
.product-loading-container {
	margin: 40px auto 80px;
	.img-info {
		margin: 40px auto 80px;
		display: grid;
		grid-template-columns: 50% 40%;
		gap: 100px;
		margin-top: 45px;

		@media (max-width: 920px) {
			grid-template-columns: 100%;
		}
	}

	.colors {
		display: grid;
		grid-template-columns: 45px 45px;
		gap: 15px;
	}

	.sizes {
		display: grid;
		grid-template-columns: repeat(7, 1fr);
		gap: 15px;
	}
}
#product {
	.top-wrapper {
		display: flex;
		justify-content: space-between;

		@media (max-width: 920px) {
			flex-flow: column nowrap;
			padding: 0 22px;
		}
	}

	.product-section {
		margin: 60px auto 25px;
		display: flex;
		justify-content: space-between;

		@media (max-width: 875px) {
			margin: 10px auto 25px;
		}

		@media (max-width: 875px) {
			flex-direction: column;
			align-items: center;
		}

		.product-images {
			max-width: 550px;
			width: 100%;
			position: relative;

			@media (max-width: 992px) {
				max-width: 390px;
			}

			@media (max-width: 875px) {
				margin: 25px auto 0px;
				max-width: 90%;
			}

			.tags-container {
				width: 100%;
				position: relative;
				position: absolute;
				display: flex;
				justify-content: space-between;
				align-items: baseline;
				z-index: 10;

				.new-container {
					color: #0154ff;
					font-size: 16px;
					font-weight: 700;
					font-family: 'Roboto';
					margin-right: 14px;
				}

				.discount-tag {
					font-size: 16px;
					border-radius: 24px;
					background-color: #ff0145;
					padding: 2px 10px;
					color: #fff;
					font-family: 'Roboto';
					font-weight: 700;

					@media (max-width: 500px) {
						font-size: 12px;
						padding: 2px 10px;
						margin-left: -5px;
					}
				}

				.statuses-container {
					display: flex;
					flex-direction: column;
					gap: 3px;
					margin: 0 0 0 auto;
				}

				.tag {
					font-size: 12px;
					border-radius: 24px;
					background-color: #cef5e3;
					padding: 4px 14px;
					color: #1c5d3f;
					text-align: center;

					@media (max-width: 500px) {
						font-size: 10px;
						padding: 2px 10px;
						line-height: 14px;
					}
				}

				&:has(.discount-container) {
					.new-container {
						display: none;
					}
				}
			}

			.active-image {
				width: 100%;
				position: relative;

				img {
					max-width: 470px;
					width: 100%;
					margin: auto;
				}
			}

			.swiper-button-prev:after,
			.swiper-button-next:after {
				font-size: 26px;

				@media (max-width: 500px) {
					font-size: 22px;
				}
			}
		}

		.product-info {
			max-width: 375px;
			width: 100%;
			margin: 0 80px 0 15px;

			@media (max-width: 935px) {
				margin: 0 30px 0 15px;
			}

			@media (max-width: 875px) {
				margin: 25px auto 0px;
				max-width: 80%;
			}

			@media (max-width: 550px) {
				margin: 25px auto 0px;
				max-width: 100%;
			}

			.product-title {
				font-size: 24px;
				font-weight: 600;
				font-family: 'Roboto';
				position: relative;
				line-height: 32px;

				@media (max-width: 920px) {
					margin: 0px 0 5px 0;
				}

				@media (max-width: 500px) {
					font-size: 16px;
					line-height: 21px;
					padding-right: 30px;
				}

				.wishlist-icon {
					position: absolute;
					top: -7px;
					right: -40px;

					@media (max-width: 550px) {
						top: -7px;
						right: 0px;
					}

					button {
						background-color: #f3f3f3;
						padding: 4px;
						border-radius: 50%;
						transition: all 0.05s ease-in-out;

						@media (max-width: 500px) {
							padding: 3px;
						}

						img {
							width: 18px;

							@media (max-width: 500px) {
								width: 22px;
							}
						}

						img.active {
							fill: #000;
						}
					}

					button:hover {
						transform: scale(1.1);
					}
				}
			}

			.product-small-desc {
				font-size: 16px;
				color: #000000;
				margin-top: 18px;
				margin-bottom: 15px;

				@media (max-width: 920px) {
					font-size: 14px;
					margin: 0 0 19px 0;
				}
			}

			.prod-code {
				margin: 15px 0;

				span {
					font-weight: bold;
				}
			}

			.prices-container {
				display: flex;
				justify-content: space-between;

				@media (max-width: 450px) {
					justify-content: start;
					gap: 15px;
				}

				.prices {
					display: flex;
					flex-direction: column;
					gap: 4px;
					font-weight: 700;
					font-size: 16px;
					margin-bottom: 25px;

					span {
						font-weight: 500;
						font-size: 14px;
						margin-right: 5px;
						color: #000;

						@media (max-width: 767px) {
							margin: 0 7px 0 0;
						}
					}

					b {
						font-size: 22px;
					}

					@media (max-width: 767px) {
						margin: 0 0 15px 0;
					}
				}

				.loyalty-points span {
					font-size: 10px;
					color: #57af8a;
					border: 1px solid #57af8a;
					border-radius: 100px;
					padding: 5px;
					font-weight: 600;
				}
			}

			.color-container {
				margin-bottom: 26px;
				font-size: 16px;
				font-weight: 600;
				font-family: 'Roboto Condensed';

				h2 {
					text-align: left;
					font-family: 'Roboto Condensed';
					font-size: 16px;
					font-weight: 600;
					margin: 0;
				}

				.color-images {
					display: grid;
					gap: 10px;
					margin-top: 7px;
					grid-template-columns: 1fr 1fr 1fr 1fr;

					@media (max-width: 375px) {
						grid-template-columns: 1fr 1fr 1fr;
					}

					.color-img {
						max-width: 82px;
						display: block;
						width: 100%;
						border: 2px solid #f3f3f3;
						cursor: pointer;

						img {
							width: 100%;
						}
					}

					.color-img.active {
						border-color: #0154ff;
					}

					.color-box {
						border: 1px solid #ccc;
						width: 45px;
						height: 45px;
						cursor: pointer;
					}

					.color-box.active {
						border: 2px solid #0154ff;
					}
				}

				@media (max-width: 920px) {
					margin: 0 0 20px 0;
				}
			}

			.sizes-container {
				margin-bottom: 25px;

				.sizes-title {
					display: flex;
					justify-content: space-between;

					.title {
						font-family: 'Roboto Condensed';
						font-size: 16px;
						font-weight: 600;
						margin: 0;
					}

					.title-help {
						font-size: 15px;
						text-decoration: underline;
					}
				}

				.sizes-values {
					margin-top: 25px;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					column-gap: 20px;
					row-gap: 12px;

					@media (max-width: 550px) {
						column-gap: 15px;
						row-gap: 14px;
					}

					.size {
						font-size: 16px;
						border-radius: 7px;
						font-weight: 700;
						height: 30px;
						padding: 0 11px;
						text-align: center;
						align-items: center;
						display: flex;
						border: 1px solid #f2f4f8;
						cursor: pointer;
					}

					.size:hover {
						border: 1px solid #0154ff;
						transition: 0.5s ease;
					}

					.size.selected {
						border: 1px solid #0154ff;
						border-radius: 0px;
						transition: 0.5s ease;
					}

					.size.disabled {
						background-color: #f2f4f8;
						color: #6e7486;
						pointer-events: none;
					}
				}
			}

			.recommended-size {
				font-size: var(--microsize);
				text-decoration: underline;
				margin-bottom: 27px;
				cursor: pointer;

				span {
					font-weight: 700;
				}

				@media (max-width: 920px) {
					margin-bottom: 14px;
				}
			}

			.buy-section {
				button {
					font-size: 16px !important;
					width: 100% !important;
				}
			}

			.product-features {
				margin: 22px 0 25px 0px;
				display: flex;
				flex-direction: column;
				gap: 10px;

				@media (max-width: 875px) {
					margin: 12px 0 15px 0px;
				}

				.feature {
					display: flex;
					gap: 11px;
					align-items: center;
				}
			}
		}
	}

	.description-section {
		background-color: #f2f4f8;
		display: flex;
		justify-content: center;

		@media (max-width: 768px) {
			background-color: #fff;
		}

		.content-wrapper {
			display: flex;
			justify-content: center;
		}

		.description-container {
			max-width: 965px;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 50px 0 70px;

			@media (max-width: 768px) {
				align-items: start;
				padding: 10px 0 20px;
			}

			.description-types-header {
				max-width: 800px;
				display: flex;
				justify-content: space-around;
				gap: 85px;
				border-bottom: 3px solid #ebedeb;
				padding: 0 25px 0;
				position: relative;

				@media (max-width: 768px) {
					flex-direction: column;
					gap: 35px;
					border-bottom: none;
					padding: 0 0 25px;
					width: 100%;
				}

				.type {
					position: relative;

					h2 {
						display: block;
						position: relative;
						cursor: pointer;
						font-size: 18px;
						font-family: 'Roboto Condensed';
						font-weight: 600;
						padding-bottom: 25px;
						margin: 0 0 6px;
						line-height: 18px;

						@media (max-width: 768px) {
							width: 100%;
							padding-bottom: 10px;
							border-bottom: 3px solid #ebedeb;
						}
					}
				}

				@media (max-width: 768px) {
					.type::after {
						content: '';
						background-image: url('../images/arrow-right.svg');
						background-repeat: no-repeat;
						position: absolute;
						margin: auto;
						width: 20px;
						height: 20px;
						transform: rotate(90deg);
						right: 10px;
						top: 10px;
						transition: 0.25s ease;
					}

					.type.active::after {
						content: '';
						background-image: url('../images/arrow-right.svg');
						background-repeat: no-repeat;
						position: absolute;
						margin: auto;
						width: 20px;
						height: 20px;
						transform: rotate(-90deg);
						right: 5px;
						top: 0px;
						transition: 0.25s ease;
					}
				}
			}

			@media (min-width: 769px) {
				.description-types-header:has(.active-first):before {
					content: '';
					position: absolute;
					top: 48px;
					left: 0px;
					margin: auto;
					width: 140px;
					height: 3px;
					background-color: #6f7484;
					transition: 0.25s ease-in;
				}

				.description-types-header:has(.active-second):before {
					content: '';
					position: absolute;
					top: 48px;
					left: 170px;
					margin: auto;
					width: 240px;
					height: 3px;
					background-color: #6f7484;
					transition: 0.25s ease-in;
				}

				.description-types-header:has(.active-third):before {
					content: '';
					position: absolute;
					top: 48px;
					left: 435px;
					margin: auto;
					width: 300px;
					height: 3px;
					background-color: #6f7484;
					transition: 0.25s ease-in;
				}
			}

			.content {
				display: block;

				@media (max-width: 768px) {
					display: none;
				}
			}

			.content-mobile {
				display: none;

				@media (max-width: 768px) {
					display: block;
				}
			}

			.content,
			.content-mobile {
				padding-top: 50px;
				width: 100%;

				p {
					color: #6e7486;
				}

				@media (max-width: 768px) {
					padding: 10px 0 35px;
				}

				.attributes-container {
					display: flex;
					flex-direction: column;
					gap: 25px;

					@media (max-width: 768px) {
						gap: 0px;
					}

					.attribute {
						display: flex;
						width: 100%;

						@media (max-width: 768px) {
							flex-direction: column;
						}

						.title {
							width: 50%;
							background-color: #f2f4f8;
							font-size: 16px;
							font-weight: 600;

							@media (max-width: 768px) {
								width: 100%;
								padding: 18px;
							}
						}

						.value {
							width: 50%;
							color: #6e7486;
							font-size: 16px;
							text-align: right;

							@media (max-width: 768px) {
								width: 100%;
								padding: 18px;
								text-align: left;
							}
						}
					}
				}
			}
		}
	}
}

.sizeguide-dialog-overlay {
	position: fixed;
	inset: 0;
	z-index: 10000;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;

	@media (max-width: 575px) {
		align-items: end;
	}

	.sizeguide-dialog {
		z-index: 10000;
		position: relative;
		animation: fadeIn 0.5s;

		@keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		.close-modal-btn {
			position: absolute;
			top: 20px;
			right: 20px;
			cursor: pointer;

			@media (max-width: 768px) {
				top: 15px;
				right: 15px;
			}
		}
	}

	.sizeGuideModal {
		max-width: 575px;
		width: 100%;
		max-height: 100vh;
		background-color: #fff;
		position: relative;

		@media (max-width: 575px) {
			margin: 0;
		}

		.sizeGuide-container {
			padding: 40px 0 40px;
			background-color: #fff;

			@media (max-width: 575px) {
				padding: 40px 12px;
			}

			h2 {
				font-size: 24px;
				margin-top: 8px;
				font-family: 'Roboto Condensed';
				font-weight: 600;
				span {
					background: transparent linear-gradient(290deg, #6b19f9 0%, #2253f5 100%) 0% 0% no-repeat padding-box;
					-webkit-background-clip: text;
					background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}

			h4 {
				text-align: center;
				font-size: 16px;
				font-family: 'Roboto Condensed';
				font-weight: 600;
				margin: 24px auto 22px;
			}

			.countries-sizes {
				margin-top: 22px;
				padding: 0 56px;
				height: 285px;
				display: grid;
				grid-template-columns: 52px 1fr;
				position: relative;
				align-items: center;

				@media (max-width: 575px) {
					padding: 0;
				}

				.countries {
					display: flex;
					height: 100%;
					flex-direction: column;
					justify-content: center;
					background-color: #a4a9b8;
					border-radius: 15px;
					color: #fff;
				}

				.sizes {
					display: flex;
					height: 100%;
					flex-direction: column;
					justify-content: space-between;
					max-width: 575px;
					overflow-x: scroll;

					.row {
						width: 100%;
						display: flex;
						margin-top: 2px;
						flex-wrap: nowrap;
						align-items: center;
						gap: 35px;
						padding-left: 35px;
						font-size: 16px;
					}

					.row:nth-child(2n) {
						background-color: #f2f4f8;
					}
				}
				.sizes::-webkit-scrollbar {
					width: 2px;
					height: 5px;
					bottom: -100px;
				}

				/* Track */
				.sizes::-webkit-scrollbar-track {
					background: #f1f1f1;
				}

				/* Handle */
				.sizes::-webkit-scrollbar-thumb {
					background: #888;
				}

				/* Handle on hover */
				.sizes::-webkit-scrollbar-thumb:hover {
					background: #555;
				}
			}
		}
	}
}

#combine-products {
	padding: 40px 0 55px;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: 768px) {
		background-color: #f2f4f8;
	}

	.swiper {
		padding: 0 0 50px 0;

		.swiper-pagination-bullet {
			width: 13px;
			height: 13px;
		}

		.swiper-pagination-bullet-active {
			background-color: #6e7486;
		}
	}

	.combine-title {
		width: 100%;
		text-align: left;
		font-weight: 700;
		font-size: 34px;
		font-family: 'Roboto Condensed';
		padding: 0 0 45px;

		@media (max-width: 920px) {
			font-size: 24px;
			text-align: center;
			padding: 0 0 30px;
		}
	}
}

.personalized-products-wrapper {
	height: 650px;
}

#personalized-products {
	background-color: #f2f4f8;
	padding: 40px 0 55px;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: 768px) {
		background-color: #fff;
	}

	.product-card {
		@media (max-width: 768px) {
			border: 2px solid #f3f3f3;
			padding-left: 5px;
			padding-right: 5px;
		}
	}

	.swiper {
		padding: 0 0 50px 0;

		.swiper-pagination-bullet {
			width: 13px;
			height: 13px;
		}

		.swiper-pagination-bullet-active {
			background-color: #6e7486;
		}

		.swiper-slide {
			height: auto;

			.product-card {
				height: 100%;
			}
		}
	}

	.personalized-title {
		width: 100%;
		text-align: left;
		font-weight: 700;
		font-size: 34px;
		font-family: 'Roboto Condensed';
		padding: 0 0 45px;
		margin: 0 0 6px;
		line-height: 18px;
		display: block;

		@media (max-width: 920px) {
			font-size: 24px;
			text-align: center;
			padding: 0 0 30px;
		}
	}
}

#recently-viewed-products {
	background-color: #fff;
	padding: 40px 0 55px;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: 768px) {
		background-color: #f2f4f8;
	}

	.recently-viewed-title {
		width: 100%;
		text-align: left;
		font-weight: 700;
		font-size: 34px;
		font-family: 'Roboto Condensed';
		padding: 0 0 45px;
		display: block;
		margin: 0 0 6px;

		@media (max-width: 920px) {
			font-size: 24px;
			text-align: center;
			padding: 0 0 30px;
		}
	}

	.products {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 20px;

		@media (max-width: 1024px) {
			grid-template-columns: repeat(3, 1fr);
			gap: 10px;
		}

		@media (max-width: 600px) {
			grid-template-columns: repeat(2, 1fr);
			gap: 10px;
		}

		.product {
			margin: auto;
			max-width: 200px;
			position: relative;

			@media (max-width: 728px) {
				background-color: #fff;
				padding: 25px;
			}

			.wishlist-icon {
				position: absolute;
				right: 0px;
				bottom: 0px;

				button {
					background-color: #f3f3f3;
					padding: 4px;
					border-radius: 50%;
					transition: all 0.05s ease-in-out;
					@media (max-width: 500px) {
						padding: 3px;
					}

					img {
						width: 18px;

						@media (max-width: 500px) {
							width: 22px;
						}
					}

					img.active {
						fill: #000;
					}
				}

				button:hover {
					transform: scale(1.1);
				}

				@media (max-width: 728px) {
					right: 20px;
					bottom: 10px;
				}
			}
		}

		img {
			width: 100%;
		}
	}
}

/* BLOG PAGE */
#blog-page {
	background-color: #f2f4f8;

	.blog-content-wrapper {
		max-width: 965px;
		margin: auto;
	}

	.bloglist-title {
		font-size: 34px;
		font-family: 'Roboto Condensed';
		width: 100%;
		text-align: center;
		padding: 45px 0 20px;

		@media (max-width: 768px) {
			font-size: 24px;
			padding: 25px 0 0;
		}
	}

	.blog-categories {
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		gap: 20px;
		padding: 15px 45px;

		@media (max-width: 768px) {
			justify-content: start;
			flex-wrap: nowrap;
			overflow-x: scroll;
			padding: 30px 0 0;
			gap: 11px;
		}

		.blog-category {
			border-radius: 100px;
			border: 2px solid #6f7484;
			display: flex;
			align-items: center;
			transition: 0.1s;
			cursor: pointer;

			&:hover {
				background-color: #6f7484;
				h4 {
					color: #fff;
				}
			}

			h4 {
				color: #6f7484;
				margin: 0;
				padding: 7px 23px;

				@media (max-width: 768px) {
					font-size: 16px;
					white-space: nowrap;
				}
			}
		}

		.blog-category.active {
			background-color: #6f7484;

			h4 {
				color: #fff;
			}
		}
	}

	.featured-blogpost {
		width: 100%;
		margin-top: 40px;
		margin-bottom: 45px;

		img {
			width: 100%;
		}

		.underimage-tag {
			display: block;
			background-color: #0154ff;
			color: #fff;
			font-size: 20px;
			width: 100%;
			padding: 8px 25px;
		}

		.blogpost-title {
			margin: 18px 0 0;
			text-align: left;
			font-size: 24px;
			@media (max-width: 768px) {
				font-size: 16px;
			}
		}

		p {
			font-size: 16px;
			color: #6e7486;
			margin: 10px 0;
			@media (max-width: 768px) {
				font-size: 12px;
			}
		}

		.author {
			font-size: 12px;
			font-family: 'Roboto Condensed';
		}
	}
}
.bloglist-container {
	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 16px;
	padding-bottom: 45px;

	@media (max-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 500px) {
		gap: 9px;
	}
}
.blogitem {
	max-width: 280px;

	img {
		width: 100%;
	}

	.underimage-tag {
		display: block;
		background-color: #0154ff;
		color: #fff;
		font-size: 14px;
		width: 100%;
		padding: 8px 25px;
	}

	.blogpost-title {
		text-align: left;
		margin: 10px 0 0;
		font-size: 16px;
		font-family: 'Roboto Condensed';
		@media (max-width: 450px) {
			font-size: 14px;
		}
	}

	p {
		font-size: 14px;
		color: #6e7486;
		text-overflow: ellipsis;
		max-width: 75ch;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;

		@media (max-width: 450px) {
			font-size: 12px;
		}
	}

	.author {
		font-size: 12px;
		font-family: 'Roboto Condensed';
	}
}

.blog-entry-page {
	.relative-articles-title {
		font-size: 34px;
		@media (max-width: 980px) {
			font-size: 24px;
		}
		@media (max-width: 768px) {
			font-size: 16px;
		}
	}
	.bloglist-container {
		grid-template-columns: repeat(4, 1fr) !important;
		@media (max-width: 980px) {
			grid-template-columns: repeat(2, 1fr) !important;
		}
	}
}
/* BLOG PAGE ENDS */

.suggested-products {
	@media (max-width: 480px) {
		display: none;
	}
}

#suggested-products {
	display: flex;
	justify-content: space-between;
	width: 380px;

	@media (max-width: 480px) {
		display: none;
	}
}

#suggested-products .product-item {
	display: inline-block;
	max-width: 180px;
}

#suggested-products .product-item .image-container {
	width: 100%;
	height: 245px;
	margin-bottom: 13px;
}

#suggested-products .product-item .image-container img {
	width: 100%;
	height: inherit;
	object-fit: cover;
	object-position: center;
}

#suggested-products .product-title {
	display: block;
	font-size: var(--nanosize);
	font-weight: 400;
	color: black;
	margin: 0;
	line-height: 1;
	margin: 0 0 8px 0;
}

#suggested-products .product-price {
	display: block;
	font-size: var(--nanosize);
	font-weight: 500;
	color: black;
	line-height: 1;
}

.images-area {
	display: flex;

	@media (max-width: 480px) {
		position: relative;
		max-width: 331px;
		margin: auto;
	}

	.thumb-column {
		display: flex;
		flex-flow: column nowrap;
		row-gap: 24px;
		margin-right: 24px;

		.thumb-item {
			width: 78px;
			height: 78px;
			border-radius: 5px;
			overflow: hidden;
			cursor: pointer;
			border: 1px solid transparent;

			&.active {
				border: 1px solid black;
			}

			img {
				width: 100%;
				height: 100%;
				display: inline-block;
				object-fit: cover;
				object-position: center;
			}
		}

		@media (max-width: 480px) {
			.thumb-item {
				width: 16px;
				height: 16px;
				border-radius: 100%;
				border-color: white;
				border-width: 2px;

				&.active {
					border-color: white;
					background: white;
				}

				img {
					display: none;
				}
			}

			z-index: 1;
			position: absolute;
			margin: 0 0 0 auto;
			right: 10px;
			top: 0;
			height: 100%;
			width: fit-content;
			max-width: 310px;
			row-gap: 7px;
			justify-content: center;
			align-items: flex-end;
		}
	}

	#images-control-overlay {
		.images-column {
			display: flex;
			row-gap: 15px;
			flex-flow: column nowrap;
			height: 780px;
			overflow-y: scroll;
			scrollbar-width: none;
			scroll-behavior: smooth;
			position: relative;

			@media (max-width: 1200px) {
				height: 535px;
			}

			@media (max-width: 920px) {
				height: 444px;
			}

			&::-webkit-scrollbar {
				width: 0;
			}

			.image-item {
				width: 588px;
				height: 774px;

				@media (max-width: 1200px) {
					width: 400px;
					height: 527px;
				}

				@media (max-width: 920px) {
					width: 331px;
					height: 436px;
				}

				img {
					width: inherit;
					height: inherit;
					display: inline-block;
					object-fit: cover;
					object-position: center;
				}
			}
		}
	}

	@media (max-width: 920px) {
		margin-bottom: 9px;
		justify-content: center;
	}
}

.breadcrumbs-container {
	border-top: 1px solid #a4a9b8;
	border-bottom: 1px solid #a4a9b8;

	@media (max-width: 1024px) {
		border: none;
	}
}

#breadcrumbs {
	display: flex;
	align-items: center;
	width: 100%;
	font-size: 14px;
	white-space: nowrap;
	flex-wrap: nowrap;
	overflow: auto !important;
	text-overflow: ellipsis;
	font-weight: 500;
	padding: 0px 15px;
	max-width: 1160px;
	height: 44px;
	margin: auto;

	.bread-item {
		position: relative;
		padding-right: 5px;
		margin-right: 12px;
		white-space: nowrap;

		&:after {
			content: '>';
			position: absolute;
			display: inline-block;
			left: 100%;
		}

		&:nth-last-child(1) {
			color: #a3a9b9;
			white-space: nowrap;
			// overflow: hidden !important;
			// text-overflow: ellipsis;

			&:after {
				display: none;
			}
		}

		a {
			color: #000000;
		}
	}

	// @media (max-width: 1024px) {
	// 	display: none;
	// }
}

.go-back {
	display: none;

	@media (max-width: 1024px) {
		// display: block;
		font-weight: 700;
		padding: 14px 22px;
		cursor: pointer;

		span {
			font-size: 14px;
		}

		span::before {
			content: '';
			display: block;
			background: url('../images/arrow-right.svg') no-repeat;
			transform: rotate(180deg);
			width: 10px;
			height: 21px;
			float: left;
			margin: -4px 8px 0px 0px;
		}
	}
}

#heritage {
	text-align: center;
	margin-top: 40px;
	font-size: 13px;
}

.img-container-double {
	display: flex;
	justify-content: space-between;
	padding: 15px 0 30px;
	flex-wrap: wrap;
}

.img-container-double > div {
	width: 48.25%;
	position: relative;
	overflow: hidden;
}

.img-container-double > div img,
.img-gallery img {
	width: 100%;
}

.img-container-double > div:hover {
	box-shadow: 2px 7px 29px #434b5759;
	border-radius: 50px;
}

.img-container-double > div:hover .zoom-overlay,
.img-gallery > div:hover .zoom-overlay {
	background-color: rgba(244, 246, 248, 0.4);
	content: '';
	background-image: url(../images/plus-icon.svg);
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	opacity: 1;
}

.panzoom {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.panzoom__viewport {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 1px;
	margin: auto;
}

.panzoom__content {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
	transform: translate3d(0, 0, 0) scale(1);
	transform-origin: 0 0;
	transition: none;
	touch-action: none;
	user-select: none;
}

.is-draggable {
	cursor: move;
	cursor: grab;
}

.is-dragging {
	cursor: grabbing;
}

.img-gallery {
	display: flex;
	justify-content: space-between;
	margin-top: 25px;
	padding: 15px 0 30px;
	flex-wrap: wrap;
}

.img-gallery > div {
	width: 50%;
	position: relative;
	overflow: hidden;
}

.product-box {
	text-align: center;
}

ol li::marker {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
}

#raffle-ends,
#raffle-starts {
	font-size: 15px;
	padding-left: 8%;
	margin: 30px 0;
}

#raffle-ends span,
#raffle-starts span {
	margin-right: 32px;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
}

.product-bottom form {
	max-width: 404px;
	margin: auto;
}

.custom-select {
	position: relative;
	cursor: pointer;
}

.custom-select select {
	display: none;
}

.select-selected {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: 14px;
	padding: 18.5px 16px 18.5px 30px;
	background-color: #fff;
	box-shadow: 2px 7px 29px #434b5759;
	border-radius: 28px;
}

.select-selected span {
	display: block;
	width: calc(100% - 36px);
	overflow-x: hidden;
}

.select-selected span.new {
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
}

.select-selected.select-arrow-active {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select-selected:after {
	position: absolute;
	content: '';
	top: 10px;
	right: 10px;
	width: 47px;
	height: 39px;
	background-image: url(../images/button-arrow-orange.svg);
	background-size: cover;
	background-position: center;
}

.select-items div {
	color: #040707;
	padding: 3px 8px;
	cursor: pointer;
	line-height: 21px;
}

.select-items div:hover {
	background: #ff0000;
	color: #fff;
}

.select-items {
	padding: 0 30px 10px;
	max-height: 170px;
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;
	background: #fff;
	width: 100%;
	border-bottom-right-radius: 28px;
	border-bottom-left-radius: 28px;
	z-index: 100;
}

.select-hide {
	display: none;
}

.same-as-selected {
	background-color: rgba(0, 0, 0, 0.1);
}

@-webkit-keyframes bounceright {
	from {
		-webkit-transform: translateX(0);
	}

	to {
		-webkit-transform: translateX(10px);
	}
}

#go-to-form-wrapper {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	text-align: center;
	padding: 20px 15px;
	background: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(50px);
}

#go-to-form-wrapper.hidden {
	opacity: 0;
	z-index: -1;
}

#go-to-form {
	max-width: 404px;
	margin: 0 auto;
}

.faq-item:not(:last-child) {
	border-bottom: solid 1px rgba(2, 3, 3, 0.07);
}

.faq-heading {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	position: relative;
	padding: 25px 5px;
	cursor: pointer;
}

.faq-heading:after {
	position: absolute;
	content: '';
	top: 15px;
	right: 10px;
	width: 47px;
	height: 39px;
	background-image: url(../images/button-arrow-orange.svg);
	background-size: cover;
	background-position: center;
	transition: 0.3s;
}

.faq-item.active .faq-heading:after {
	transform: rotate(180deg);
}

.faq-content {
	display: none;
	padding: 0 40px 20px 5px;
}

.faq-content .orange {
	color: #ea762e;
}

.faq-content a {
	color: inherit;
	text-decoration: underline;
}

.faq-content a:hover {
	color: #fc4400;
}

/* PRODUCT PAGES */
#popup-top {
	height: 42px;
	display: flex;
	align-items: center;
}

#popup-main {
	padding: 25px 15px 38px;
}

#popup-main .custom-select {
	margin-bottom: 13px;
}

#popup-main .select-selected {
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
}

#popup-main.has-background {
	border-radius: 25px;
}

#popup-main.checkout.has-background {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

#popup-top #back-to {
	margin: 0 0 0 13px;
}

#close-popup {
	margin-left: auto;
	cursor: pointer;
	margin-right: 11px;
}

#close-popup.hidden {
	display: none;
}

#close-popup:hover svg path {
	fill: #fff;
	stroke: #fff;
}

#fast-checkout-wrapper {
	margin-top: 25px;
	padding-top: 30px;
	border-top: solid 1px #b6bac0;
}

.option-button {
	background-color: #fff;
	box-shadow: 2px 7px 29px #434b5759;
	border-radius: 50px;
	height: 101px;
	padding: 15px 8px 15px 36px;
	display: flex;
	align-items: center;
	text-align: left;
	width: 290px;
	margin: auto auto 20px;
	cursor: pointer;
}

.option-button:last-of-type {
	margin-bottom: 47px;
}

.button-inner {
	width: 160px;
}

.button-contents {
	display: flex;
	width: 100%;
	align-items: center;
}

.button-icon {
	background-position: center;
	background-repeat: no-repeat;
	margin-right: 18px;
	margin-bottom: auto;
}

.pin-icon {
	width: 22px;
	height: 29px;
	background-image: url(../images/pin-icon.svg);
}

.home-icon {
	width: 26px;
	height: 24px;
	background-image: url(../images/home-icon.svg);
	margin-top: 8px;
}

.form-arrow-right {
	width: 53px;
	height: 48px;
	background-image: url(../images/form-arrow-right.svg);
	background-position: center;
	background-repeat: no-repeat;
	margin: auto;
}

.button-title {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
}

.button-shipping-cost {
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	color: #5fc993;
	margin-top: 6px;
}

#popup-main .select-selected {
	padding: 12px 16px 12px 30px;
}

#popup-main .select-selected:after {
	top: 4px;
}

.option-button.selected {
	border: 1px solid #fc4400;
}

#popup-main .select-items {
	margin-top: -8px;
}

.min-margin input[type='text'],
.min-margin input[type='email'],
.min-margin input[type='password'],
.min-margin input[type='tel'],
input[type='number'] {
	margin-bottom: 13px;
}

.product-part {
	padding: 0 0 24px;
}

.cost-details-part {
	padding: 24px 0 18px;
}

.total-part {
	padding: 18px 0;
}

.product-part .raffle-prod {
	margin-bottom: 3px;
}

table {
	width: 100%;
	line-height: 17px;
}

table.checkout-table th {
	text-align: left;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	padding: 6px 0;
}

table.checkout-table td {
	text-align: right;
	padding: 6px;
	font-size: 15px;
	vertical-align: bottom;
}

.checkout-part p {
	font-size: 12px;
	letter-spacing: 0.24px;
	line-height: 14px;
	margin-bottom: 0;
}

.cost-details-part p {
	margin-top: 9px;
}

.shipping-part p {
	margin-top: 3px;
}

table#shipping-details td {
	color: #5fc993;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
}

.square {
	background-color: #ff0000;
	width: 9px;
	height: 9px;
	display: inline-block;
	margin-left: 5px;
	position: relative;
	top: 1px;
}

table#total th,
table#total td {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
}

table#total td {
	color: #fc4400;
}

.info {
	background: #000000;
	width: 18px;
	height: 18px;
	text-align: center;
	color: #fff;
	border-radius: 50%;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	font-size: 10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-left: 4px;
	position: relative;
	cursor: pointer;
}

.info span {
	position: relative;
	top: 1px;
}

#cost-details .info {
	top: -3px;
}

#info-subscription,
#info-withholding {
	display: none;
}

/* SUCCESS PAGE */
.full-background {
	background: #f4f6f9;
}

main#success {
	padding: 35px 0 42px;
	text-align: center;
}

.personal-heading {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: 26px;
	line-height: 31px;
}

.success-subheading {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 20px;
}

main#success p {
	margin: 25px 0;
}

/* ACCOUNT PAGE */
#popup-main .birth-date-options {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 35px;
}

.birth-date-options.profile-edit input {
	width: 31%;
}

.birth-date-options .select-selected {
	padding-left: 20px !important;
}

.birth-date-options .select-day,
.birth-date-options .select-year {
	width: 27%;
}

.birth-date-options .select-month {
	width: 42%;
}

.birth-date-options .select-items {
	padding: 0 13px 10px;
}

#account-info-wrapper .simple-link {
	margin: 60px 0 0;
}

#footer-top-boxes {
	display: flex;
	justify-content: center;
	margin-top: 10px;
	margin-bottom: 110px;

	@media (max-width: 600px) {
		margin-top: 0px;
		margin-bottom: 25px;
	}

	.footer-top-boxes-container {
		margin: 0 5px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;
		min-height: 120px;
		height: 100%;

		@media (max-width: 1130px) {
			grid-template-columns: repeat(3, 1fr);
			// min-height: 270px;
			height: 100%;
		}

		@media (max-width: 600px) {
			gap: 10px;
			margin: 0 0 40px;
			min-height: 215px;
			grid-template-columns: repeat(1, 1fr);
			height: 100%;
		}

		@media (max-width: 350px) {
			grid-template-columns: repeat(1, 1fr);
		}

		.border-linear {
			@media (max-width: 600px) {
				position: relative;
				border: 2px solid #0154ff;
				padding: 2px;
				border-radius: 8px;
			}
		}

		.footer-box {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			@media (max-width: 600px) {
				border-radius: 6px;
				background-color: #fff;
				padding: 10px;
			}

			@media (max-width: 400px) {
				padding: 5px;
			}

			@media (max-width: 350px) {
				padding: 15px 0;
			}

			img {
				@media (max-width: 600px) {
					width: 41px;
				}

				@media (max-width: 350px) {
					width: 60px;
				}
			}

			.title {
				font-weight: 700;
				font-size: 22px;
				text-transform: capitalize;
				margin: 20px 0 5px;
				font-family: 'Roboto Condensed';

				@media (max-width: 600px) {
					font-size: 14px;
					margin: 0;
				}

				@media (max-width: 350px) {
					font-size: 16px;
				}
			}

			.subtitle {
				font-size: 22px;
				font-weight: 400;
				margin: 0;

				@media (max-width: 600px) {
					font-size: 12px;
				}

				@media (max-width: 350px) {
					font-size: 16px;
				}
			}
		}
	}
}

.application-form {
	.terms {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.gender-container {
		margin-bottom: 40px;

		select {
			background-color: #f3f3f3;
		}
	}

	.dimensions-container {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(50px, 70px));
		gap: 25px;
		margin-bottom: 40px;

		.dimension-item {
			height: 50px;
			background-color: #f3f3f3;
			font-size: 16px;
			font-weight: bold;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			transition: 0.2s;
			box-shadow: 5px 5px 5px -1px rgba(168, 168, 168, 0.74);
			-webkit-box-shadow: 5px 5px 5px -1px rgba(168, 168, 168, 0.74);
			-moz-box-shadow: 5px 5px 5px -1px rgba(168, 168, 168, 0.74);

			&.selected {
				background-color: #0154ff;
				color: #fff;
			}
		}

		.dimension-item:not(.selected):hover {
			opacity: 0.8;
			color: #0154ff;
			border-radius: 4px;
		}
	}
}

.confirmation-modal-container {
	.confirmation-modal {
		background-color: #fff;
		margin: 20px 0;
		max-width: 575px;
		width: 100%;

		@media (max-width: 575px) {
			margin: 0px;
			position: fixed;
			bottom: 0px;
			padding: 30px 14px 0 14px;

			.modal-dialog-overlay {
				align-items: flex-end;
				background: rgba(0, 0, 0, 0.6);
				bottom: 0;
				display: flex;
				justify-content: center;
				left: 0;
				overflow: auto;
				position: fixed;
				right: 0;
				z-index: 10000;
			}
		}

		.confirmation-container {
			margin: auto;
			max-width: 375px;
			padding: 40px 0;
		}

		button {
			margin-top: 25px;
			width: 100%;
		}
	}
}

footer {
	border-top: 1px solid #a4a9b8;
	width: 100%;
	background: linear-gradient(90deg, #0154ff 45%, #fff 0);

	.content-wrapper {
		padding: 0px;

		@media (max-width: 1200px) {
			max-width: 100%;
		}
	}

	.footer-top {
		display: flex;
		justify-content: space-between;

		.newsletter-section {
			color: #ffffff;
			max-width: 450px;
			padding: 25px 0;

			@media (max-width: 1200px) {
				display: flex;
				align-items: center;
				flex-direction: column;
				text-align: left;
				padding: 0px;
				max-width: 100%;
				background: #0154ff;
			}

			.newsletter-section-title {
				display: block;
				text-align: center;
				font-size: 32px;
				font-family: 'Roboto Condensed';
				font-weight: bold;
				margin: 22px 0 20px;

				@media (max-width: 475px) {
					font-size: 24px;
				}
			}

			.newsletter-section-description {
				display: block;
				margin: 15px 0 20px;
				text-align: left;
				font-size: 18px;
				font-weight: 400;
				line-height: 25px;
				color: #fcfcfc;

				@media (max-width: 1200px) {
					padding: 0px 12px;
				}

				@media (max-width: 475px) {
					font-size: 16px;
				}
			}

			.form-input {
				width: 375px;
				position: relative;

				span {
					position: absolute;
					top: 15px;
					right: 15px;
				}

				@media (max-width: 1200px) {
					width: 95%;
				}
			}

			.newsletter-email-input {
				width: 100%;
				border-radius: 0px;
				border: 0px;
				background-color: #f2f4f8;
				padding: 14px;
			}

			.newsletter-email-input::placeholder {
				opacity: 1;
			}

			p {
				font-size: 14px;
				line-height: 25px;

				@media (max-width: 1200px) {
					padding: 0 12px;
				}

				@media (max-width: 475px) {
					font-size: 12px;
					line-height: 17px;
				}
			}

			.newsletter-acceptance-container {
				margin-bottom: 0px;

				@media (max-width: 1200px) {
					margin-bottom: 30px;
					padding: 0 12px;
				}
			}

			.newsletter-acceptance {
				.input-label {
					font-size: 12px;
					margin-left: auto;
					width: fit-content;
					margin-top: 28px;
					margin-bottom: 25px;
					margin-left: 0;
					color: #ffffff;

					a {
						color: #ffffff !important;
						text-decoration: none;
					}

					@media (max-width: 1200px) {
						margin-top: 10px;
						margin-bottom: 10px;
					}
				}

				@media (max-width: 1200px) {
					align-self: flex-start;
				}
			}

			.support-mobile {
				display: none;
				width: 100%;
				background-color: #fff;
				padding: 16px 12px;
				justify-content: center;
				margin-top: 40px;

				.container {
					height: 60px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 8px;
					background-color: #f2f4f8;
					padding: 0px 22px;

					.support-title {
						color: #000;
						margin-bottom: 0px;
						font-size: 16px;
						font-weight: 700;
					}

					img {
						max-width: 32px;
					}
				}

				.container:first-child {
					background-color: #0154ff;

					.support-title {
						color: #fff;
					}
				}

				@media (max-width: 1200px) {
					display: block;
				}

				.container.track-order {
					background-color: #f2f4f8;
					.support-title {
						color: #000;
					}
					img {
						width: 100%;
						height: auto;
						max-width: 33px;
					}
				}
			}

			.social-media {
				display: flex;
				justify-content: start;
				gap: 12px;
				margin-top: 40px;

				@media (max-width: 1200px) {
					background-color: #f2f4f8;
					width: 100%;
					padding-top: 20px;
					justify-content: center;
					margin-top: 0px;
				}

				a {
					svg {
						g {
							transition: 0.3s ease-in-out;

							@media (max-width: 1200px) {
								fill: #000;
							}

							path {
								transition: 0.3s ease-in-out;

								@media (max-width: 1200px) {
									fill: #fff;
								}
							}
						}
					}

					&:hover {
						@media (min-width: 1201px) {
							svg {
								g {
									fill: #000;

									path {
										fill: #fff;
									}
								}
							}
						}
					}
				}
			}
		}

		.links-section {
			position: relative;
			margin-left: 85px;
			margin-top: 80px;
			display: flex;
			justify-content: space-evenly;
			gap: 20px;

			strong {
				display: block;
				margin: 20px 0;
				text-align: left;
				font-family: 'Roboto Condensed';
				font-family: 600;
			}

			.links {
				display: flex;
				justify-content: space-evenly;
				margin-left: 20px;
				gap: 50px;

				@media (max-width: 1200px) {
					display: none;
				}
			}

			ul {
				li {
					font-size: 16px;
					margin-bottom: 5px;
					color: #6e7486;

					a {
						color: #6e7486;
					}
				}
			}

			.support {
				width: 195px;
				align-self: flex-start;

				p {
					color: #6e7486;
					margin-bottom: 20px;

					a {
						display: block;
						text-decoration: none;
						color: #6e7486;
					}
					// anatolie
					span {
						display: block;
						margin: 8px 0;
					}
				}

				@media (max-width: 1200px) {
					display: none;
				}
			}

			.payments {
				height: 200px;
				position: absolute;
				display: flex;
				flex-wrap: wrap;
				gap: 20px;
				bottom: 50px;
				left: 50px;
				padding: 0 10px;
				.applePay,
				.googlePay,
				.klarna {
					width: 60px;
					height: 30px;
				}

				@media (max-width: 1230px) {
					position: absolute;
					padding: 20px 35px;
					display: flex;
					gap: 5px;
					flex-wrap: wrap;
				}

				@media (max-width: 1200px) {
					position: relative;
					padding: 20px 25px 0px 25px;
					display: flex;
					justify-content: center;
					bottom: 0px;
					left: 0px;
					gap: 5px;
					flex-wrap: wrap;
					background-color: #f2f4f8;
					width: 100%;
				}
				@media (max-width: 740px) {
					height: 220px;
					padding: 10px 0;
				}
				@media (max-width: 380px) {
					height: 240px;
				}

				img {
					width: 80px;
					height: 40px;

					@media (max-width: 1200px) {
						width: 72px;
					}
				}
			}

			.espa-banner {
				position: absolute;
				display: block;
				height: auto;
				width: 100%;
				max-width: 250px;
				left: 45px;
				margin-left: 20px;
				box-shadow: 1px 1px 6px 4px rgba(0, 0, 0, 0.221);
				border-radius: 3px;
				bottom: 10px;

				@media (max-width: 1200px) {
					left: 0;
					right: 0;
					margin: auto;
				}

				@media (max-width: 575px) {
					bottom: -10px;
				}

				a,
				img {
					width: 100%;
					height: 100%;
					border-radius: 3px;
				}

				// @media (min-width: 900px) {
				// 	display: none;
				// }
			}

			@media (max-width: 1200px) {
				flex-direction: column;
				margin: 0px;
				gap: 30px;
				background-color: #f2f4f8;
				align-items: center;
			}
		}

		@media (max-width: 1200px) {
			justify-content: center;
			flex-direction: column;
		}
	}

	.footer-bottom {
		border-top: 2px solid #ccc;
		height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 26px;
		background-color: #ffffff;
		font-size: 14px;

		@media (max-width: 1200px) {
			height: auto;
			border-top: 0;
			background-color: #f2f4f8;
			flex-direction: column;
			align-items: center;
			justify-content: flex-end;
			text-align: center;
			padding: 25px 0;
			font-size: 12px;
			color: #6e7486;
		}

		div {
			margin-top: 5px;
		}

		.devcompany {
			a {
				text-decoration: underline;
				color: #26b287;
			}
		}

		.terms-cookies {
			display: flex;
			align-items: center;
			gap: 10px;

			.cookies {
				cursor: pointer;
				height: 30px;
				width: 30px;
				background-color: #0154ff;
				display: flex;
				align-items: center;

				img {
					max-width: 25px;
					margin: auto;
				}

				@media (max-width: 1200px) {
					display: none;
				}
			}
		}
	}

	@media (max-width: 1200px) {
		background: none;
	}
}

.subscription {
	position: relative;
	width: 100%;
	height: 48px;
	border-radius: 10px;
	border-top-left-radius: 0;
	border: none;
}

.subscription .add-email {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	padding: 0 120px 0 20px;
	background: #f4f4f4;
	border-radius: 10px;
	border-top-left-radius: 0;
	border-bottom-right-radius: 0;
}

.Klaviyo-content {
	height: 295px;
}

.Klaviyo-content div[role='radiogroup'] {
	gap: 10px;
}

#klaviyo-newsletter,
#klaviyo-newsletter-en #klaviyo-newsletter input,
#klaviyo-newsletter-en input {
	border: none !important;
	outline: none;
	padding: 0 20px !important;
	border-radius: 10px !important;
	border-top-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

#klaviyo-newsletter button,
#klaviyo-newsletter-en button {
	padding: 0 20px !important;
	border-radius: 10px !important;
	border-top-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	position: relative;
	left: -5px;
}

#klaviyo-newsletter div.needsclick,
#klaviyo-news div.needsclick,
#klaviyo-newsletter-en div.needsclick {
	margin-right: 0 !important;
	font-size: 12px !important;
}

#klaviyo-news button {
	position: relative;
	font-size: 0 !important;
	width: 34px !important;
}

#klaviyo-news button:after {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	height: 9px;
	width: 9px;
	background: url(../images/arrow-white.svg) no-repeat 100%;
}

.subscription .submit-email {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 100px;
	border: none;
	border-radius: 0;
	border-bottom-left-radius: 10px;
	border-top-right-radius: 10px;
	outline: none;
	margin: 0;
	padding: 0 20px;
	cursor: pointer;
	background: black;
	color: #ffffff;
	transition:
		width 0.35s ease-in-out,
		background 0.35s ease-in-out;
}

.subscription.done .submit-email {
	width: 100%;
	background: black;
}

.subscription .submit-email .before-submit,
.subscription .submit-email .after-submit {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	line-height: 38px;
	transition:
		visibility 0.35s ease-in-out,
		opacity 0.35s ease-in-out;
	border-radius: 0;
	border-bottom-left-radius: 10px;
	border-top-right-radius: 10px;
	line-height: 46px;
}

.subscription.done .submit-email .before-submit,
.subscription:not(.done) .submit-email .after-submit {
	visibility: hidden;
	opacity: 0;
}

.subscription .submit-email .after-submit {
	transition-delay: 0.35s;
}

.subscription:not(.done) .submit-email .before-submit,
.subscription.done .submit-email .after-submit {
	visibility: visible;
	opacity: 1;
}

#social {
	margin: 20px 0 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#social a {
	display: block;
	margin: 0 13px;
}

#scrollToTopBtn {
	position: fixed;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	border: 2px solid #ff4500;
	bottom: 90px;
	right: 31px;
	background: #fc4400;
	z-index: 99999999 !important;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
}

#scrollToTopBtn span {
	display: flex;
	transform: rotate(-90deg);
}

#scrollToTopBtn:hover {
	background: #fff;
	-moz-box-shadow: rgba(0, 0, 0, 0.4) 0 4px 8px;
	-webkit-box-shadow: rgb(0 0 0 / 40%) 0 4px 8px;
	box-shadow: rgb(0 0 0 / 40%) 0 4px 8px;
}

#scrollToTopBtn:hover span svg path {
	fill: #fc4400;
}

body.compensate-for-scrollbar {
	overflow: auto !important;
}

.fancybox__thumbs .carousel__slide .fancybox__thumb::after {
	border-color: var(--fancybox-accent-color, rgba(252, 68, 0, 0.96)) !important;
}

.loading-spinner {
	display: none;
	width: 80px;
	height: 80px;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: 999;
	background: #fff;
	border-radius: 50%;
}

.loading-spinner.visible {
	display: inline-block;
}

.loading-spinner div {
	position: absolute;
	width: 6px;
	height: 6px;
	background: #000;
	border-radius: 50%;
	animation: loading-spinner 1.2s linear infinite;
}

.loading-spinner div:nth-child(1) {
	animation-delay: 0;
	top: 37px;
	left: 66px;
}

.loading-spinner div:nth-child(2) {
	animation-delay: -0.1s;
	top: 22px;
	left: 62px;
}

.loading-spinner div:nth-child(3) {
	animation-delay: -0.2s;
	top: 11px;
	left: 52px;
}

.loading-spinner div:nth-child(4) {
	animation-delay: -0.3s;
	top: 7px;
	left: 37px;
}

.loading-spinner div:nth-child(5) {
	animation-delay: -0.4s;
	top: 11px;
	left: 22px;
}

.loading-spinner div:nth-child(6) {
	animation-delay: -0.5s;
	top: 22px;
	left: 11px;
}

.loading-spinner div:nth-child(7) {
	animation-delay: -0.6s;
	top: 37px;
	left: 7px;
}

.loading-spinner div:nth-child(8) {
	animation-delay: -0.7s;
	top: 52px;
	left: 11px;
}

.loading-spinner div:nth-child(9) {
	animation-delay: -0.8s;
	top: 62px;
	left: 22px;
}

.loading-spinner div:nth-child(10) {
	animation-delay: -0.9s;
	top: 66px;
	left: 37px;
}

.loading-spinner div:nth-child(11) {
	animation-delay: -1s;
	top: 62px;
	left: 52px;
}

.loading-spinner div:nth-child(12) {
	animation-delay: -1.1s;
	top: 52px;
	left: 62px;
}

@keyframes loading-spinner {
	0%,
	20%,
	80%,
	100% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.5);
	}
}

p.error-message {
	color: red;
	font-size: 16px;
	text-align: center;
	font-weight: 700;
}

#sizeSelect .css-6j8wv5-Input:before {
	content: none;
}

#sizeSelect .css-14el2xx-placeholder {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
}

#sizeSelect .css-1s2u09g-control,
#sizeSelect .css-1pahdxg-control {
	height: 56px;
	border-radius: 30px !important;
	margin-bottom: 0;
}

#sizeSelect .css-1pahdxg-control {
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

#sizeSelect.css-b62m3t-container.hide .css-1pahdxg-control {
	border-radius: 30px !important;
}

.birth-date-options .css-1s2u09g-control,
.birth-date-options .css-1pahdxg-control {
	margin-bottom: 0;
}

.fancybox__content {
	max-width: 600px;
}

.fancybox__content .orange {
	color: #ea762e;
}

.fancybox__content a {
	color: inherit;
	text-decoration: underline;
}

.fancybox__content a:hover {
	color: #fc4400;
}

.pass-visibillity-icon {
	width: 28px;
	position: absolute;
	right: 12px;
	top: 10px;
	padding: 4px;
	cursor: pointer;
}

#hidePassIcon {
	display: none;
}

.my-account-form input {
	font-size: 14px !important;
}

.PhoneInputCountry {
	position: absolute !important;
	top: 0;
	bottom: 13px;
	left: 32px;
}

input.PhoneInputInput {
	padding-left: 70px;
}

.menu-logo {
	max-width: 122px;
}

/*404 Not Found Page*/
#notfound {
	padding: 46px 0 26px;

	@media (max-width: 800px) {
		padding: 25px 0 40px;
	}

	.content-wrapper {
		@media (max-width: 800px) {
			flex-direction: column;
			gap: 14px;
		}

		> div {
			width: 50%;

			@media (max-width: 800px) {
				width: 100%;

				img {
					margin: auto;
				}
			}
		}

		.content-text {
			max-width: 300px;
			margin: 50px auto auto;
			text-align: center;

			@media (max-width: 800px) {
				margin: auto;
			}

			h1 {
				line-height: 1;
				font-size: 100px;
				line-height: 120px;
				color: #6e7486;

				@media (max-width: 800px) {
					display: none;
				}
			}

			p {
				font-size: 24px;
				line-height: 32px;
				margin: 0 0 21px;
				color: #a3a9b9;
				font-weight: bold;
			}

			button {
				width: 100%;
			}
		}
	}
}

/* Out of stock */
#outofstock {
	padding: 75px 0 75px;

	@media (max-width: 800px) {
		padding: 25px 0 40px;
	}

	.flex-col {
		align-items: center;
	}

	.content-wrapper {
		@media (max-width: 800px) {
			flex-direction: column;
		}

		> div {
			width: 50%;

			@media (max-width: 800px) {
				width: 100%;

				img {
					margin: auto;
				}
			}
		}

		.animation-container {
			display: flex;
			justify-content: center;
		}

		@media (max-width: 800px) {
			svg {
				max-width: 450px;
				max-height: 350px;
				margin-left: 20px;
			}
		}

		.content-text {
			max-width: 300px;
			margin: auto;
			text-align: center;

			h1 {
				line-height: 1;
				font-size: 24px;
				color: #000;
				font-family: 'Roboto Condensed';
			}

			p {
				font-size: 16px;
				margin: 20px 0 21px;
				color: #a3a9b9;
				font-weight: bold;

				@media (max-width: 800px) {
					font-size: 14px;
				}
			}

			button {
				width: 100%;
			}
		}
	}
}

/* cms content */
.page-title {
	text-align: left;
	margin-bottom: 30px;
}

.cms-content .rounded {
	border-radius: 50px;
}

.cms-content p {
	margin: 25px 0;
}

.cms-content .orange-btn {
	display: block;
	max-width: 405px;
	margin: 50px auto;
}

.cms-content .icons-wrapper {
	display: flex;
	justify-content: space-between;
	margin: 30px auto;
}

.cms-content .icons-wrapper .icon-col {
	display: flex;
	align-items: center;
	width: 32%;
}

.cms-content .icons-wrapper .icon-col .icon {
	margin-right: 10px;
}

.cms-content .icons-wrapper .icon-col .text {
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.cms-content .images-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.cms-content .images-grid img:nth-child(1),
.cms-content .images-grid img:nth-child(2) {
	width: 48.5%;
	margin-bottom: 35px;
}

.cms-content .images-grid img:nth-child(3) {
	max-width: 100%;
}
/* cms content end */

.embla.homepage-slider {
	overflow: hidden;
	position: relative;

	.embla__container {
		display: flex;
	}

	.embla__slide {
		position: relative;
		flex: 0 0 100%;

		img {
			width: 100%;
			height: 50vw;
			min-height: 550px;
			max-height: 1200px;
			object-fit: cover;
			object-position: top center;

			@media (max-width: 767px) {
				height: 110vw;
				min-height: unset;
			}

			@media (max-width: 480px) {
				height: 742px;
			}
		}

		.slide-overlay {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: linear-gradient(to left, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 110%);

			.content-wrapper {
				max-width: 1300px;
				height: 100%;
				margin: 0 auto;
				padding: 0 15px;
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				padding: 0px 120px;

				* {
					max-width: 325px;
				}

				@media (max-width: 767px) {
					padding: 120px 50px 0 50px;
					justify-content: flex-start;
					align-items: center;
					text-align: center;
				}

				@media (max-width: 425px) {
					padding-top: 135px;
				}
			}

			.slider-subtext {
				font-size: var(--microsize);
				margin: 0 0 15px 0;
			}

			.slider-text {
				font-weight: 700;
				font-size: var(--mediumsize);
				line-height: 1.3;
				margin-bottom: 18px;

				p {
					margin: 0;
				}
			}

			.viewall-button {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 48px;
				min-width: 170px;
				width: fit-content;
				border-radius: 0;
				border-bottom-left-radius: 10px;
				border-top-right-radius: 10px;
				outline: none;
				margin: 0;
				padding: 0 38px 0 20px;
				background: black;
				color: #ffffff;
				transition: box-shadow 0.2s;
				font-size: 16px;

				&:hover {
					box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
				}

				&:after {
					right: 20px;
				}

				&:hover:after {
					width: 18px;
					right: 12px;
				}

				@media (max-width: 480px) {
					font-size: 14px;
					line-height: 16px;
					min-width: 160px;
					height: 45px;
				}
			}
		}
	}

	.embla_controls {
		@media (max-width: 980px) {
			padding: 0;
		}
	}
}

.embla_controls {
	display: flex;
	justify-content: space-between;
	padding: 0 58px;
	position: absolute;
	width: 100%;
	top: calc(50% - 21.5px);
	left: 0;

	.embla_control {
		width: 43px;
		height: 43px;
		border-radius: 25px;
		transition: 0.3s;
		display: inline-flex;
		align-items: center;
		padding: 0 15px;

		.text {
			display: none;
			width: 110px;
			text-align: left;
			font-family: Ubuntu, sans-serif;
			font-weight: 400;
			font-size: 12px;
			white-space: nowrap;
			overflow: hidden !important;
			text-overflow: ellipsis;
		}

		&:hover {
			background: white;
			width: 163px;
		}

		&.embla__prev {
			justify-content: flex-start;

			svg {
				transform: rotate(180deg);
			}

			.text {
				padding-left: 15px;
			}

			&:hover {
				padding-right: 30px;

				.text {
					display: initial;
				}
			}
		}

		&.embla__next {
			justify-content: flex-end;

			.text {
				padding-right: 15px;
			}

			&:hover {
				padding-left: 30px;

				.text {
					display: initial;
				}
			}
		}
	}
}

#double-banner {
	display: flex;

	.banner-item {
		width: 50%;
		/* height: 50vw;*/
		position: relative;
		overflow: hidden;

		img {
			display: block;
			width: 100%;
			object-fit: contain;
			transition: transform 0.5s;

			@media (max-width: 767px) {
				object-fit: cover;
				width: 100vw;
				height: 100vw;
			}
		}

		.title-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: rgba(0, 0, 0, 0.15);

			h3 {
				font-size: var(--bigsize);
				font-weight: 400;
				color: white;
				transition: 0.5s;
			}
		}

		&:hover {
			img {
				transform: scale(1.01);
			}
		}

		@media (max-width: 767px) {
			width: 100%;
			height: 100vw;
		}
	}

	@media (max-width: 767px) {
		flex-direction: column;
	}
}

#collection-banner {
	display: flex;
	align-items: flex-start;
	height: 810px;
	margin-bottom: 80px;

	@media (max-width: 980px) {
		flex-flow: column-reverse nowrap;
		height: unset;
		max-width: 100%;
	}

	@media (max-width: 480px) {
		margin-bottom: 0;
	}

	.banner-item {
		width: 50%;
		height: inherit;

		&:nth-child(1) {
			background: #f4f4f4;

			.grid-container {
				display: flex;
				flex-flow: column nowrap;
				max-width: 516px;
				margin: 0 auto;
				padding: 0 15px;
				justify-content: center;
				height: inherit;

				@media (max-width: 980px) {
					padding: 24px 15px;
				}
			}

			.category-intro {
				font-size: var(--microsize);
				margin: 0 0 10px 0;
			}

			h3 {
				display: block;
				font-size: var(--mediumsize);
				margin: 0 0 15px 0;
			}

			p {
				margin: 0 0 40px 0;
			}

			.images-container {
				display: flex;
				justify-content: space-between;
				column-gap: 20px;
				height: 34.7vw;
				max-height: 500px;

				.first-part {
					width: 57.6%;
					height: inherit;
					max-height: 500px;

					.img-container {
						width: 100%;
						height: 100%;

						img {
							object-fit: cover;
							object-position: center;
							width: 100%;
							height: inherit;
						}
					}

					@media (max-width: 980px) {
						max-width: 100%;
						width: 100%;
						height: 587px;
						max-height: unset;
						margin-bottom: 10px;
					}
				}

				.second-part {
					width: 37%;
					height: 100%;
					display: flex;
					flex-flow: column nowrap;
					justify-content: space-between;
					row-gap: 10px;

					.img-container {
						width: 100%;
						height: 100%;

						img {
							object-fit: cover;
							object-position: center;
							width: 100%;
							height: inherit;
						}
					}

					@media (max-width: 980px) {
						flex-flow: row nowrap;
						max-width: 100%;
						width: 100%;
						gap: 10px;
						height: 218px;
					}
				}

				@media (max-width: 980px) {
					height: unset;
					flex-flow: column nowrap;
					max-height: unset;
				}
			}
		}

		&:nth-last-child(1) {
			position: relative;
			overflow: hidden;

			img {
				display: block;
				width: 100%;
				height: inherit;
				object-fit: cover;
				transition: transform 0.5s;
			}

			.title-container {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				align-items: center;
				background-color: rgba(0, 0, 0, 0.15);
				color: white;

				.category-intro {
					font-size: var(--microsize);
					line-height: 1;
					margin: 0 0 14px 0;
				}

				h3 {
					font-size: var(--smallsize);
					font-weight: 400;
					color: white;
					transition: 0.5s;
					line-height: 1;
				}
			}
		}

		@media (max-width: 980px) {
			width: 100%;
		}
	}
}

#homepage-motto {
	padding: 176px 0;

	.content-wrapper {
		.motto-title {
			position: relative;
			font-weight: 700;
			font-size: calc(var(--bigsize) + 2px);
			padding: 0 48px;
			display: block;
			text-align: left;
			line-height: 1.2;

			.hashtag {
				position: absolute;
				left: 0;
				top: 0;
				font-weight: 700;
				font-size: 48px;
			}
		}

		& > div {
			font-size: calc(var(--bigsize) - 1px);
			line-height: 1.4;
			max-width: 828px;

			strong {
				font-weight: 500;
				transition: 0.3s ease-out;

				&:hover {
					font-style: italic;
				}
			}
		}
	}

	.motto-link {
		color: black;
		display: inline-block;
		font-size: 16px;
		position: relative;
		padding-right: 24px;
		transition: 0.3s ease-out;
		line-height: 18px;
		color: #000;

		&::after {
			content: '';
			position: absolute;
			background: url(../images/arrow-black.svg) no-repeat right;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 12px;
			height: 12px;
			right: 0;
			transition: 0.3s ease-out;

			@media (max-width: 480px) {
				width: 10px !important;
				height: 11px;
				background-size: cover;
			}
		}

		&:hover {
			padding-right: 26px;
		}

		&:hover:after {
			width: 18px;
			right: -5px;
		}

		@media (max-width: 480px) {
			padding-right: 16px !important;
			font-size: 14px;
		}
		/*        .arrow {
            display: inline-block;
            padding: 0 10px;
            transform: translateY(10%);
        }*/
	}

	@media (max-width: 575px) {
		padding: 32px 0;

		.content-wrapper {
			.motto-title {
				padding: 0 32px;

				.hashtag {
					top: -2px;
					font-size: unset;

					svg {
						width: 24px !important;
						height: 35px !important;
					}
				}
			}

			p {
				margin: 12px 0;
			}
		}
	}
}

.homepage-insta {
	margin-bottom: 50px;

	.title {
		text-align: center;
		h1 {
			font-size: 34px;
			margin-bottom: 20px;

			@media (max-width: 900px) {
				font-size: 24px !important;
			}

			@media (max-width: 480px) {
				font-size: 16px !important;
				line-height: 18px !important;
				margin-bottom: 17px;
			}
		}
	}

	@media (max-width: 575px) {
		margin-bottom: 50px;
	}
}

.product-carousel-container {
	max-width: 100%;
	min-height: 481px;
	position: relative;

	@media (max-width: 1343px) {
		min-height: 35.82vw;
	}

	@media (max-width: 1220px) {
		min-height: 481px;
	}

	@media (max-width: 670px) {
		min-height: 326px;
		height: 88vw;
	}
}

.product-tabs {
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;

	.content-wrapper {
		display: flex;
		justify-content: space-between;
		padding: 63px 0;
		align-items: center;
		position: relative;
		max-width: unset;

		@media (max-width: 1220px) {
			flex-direction: column !important;
			gap: 30px 0;
		}

		@media (max-width: 680px) {
			padding: 45px 0;
		}
	}

	.embla {
		overflow: hidden;
		position: relative;
		padding: 0 20px;
		display: none;

		&.active {
			display: block;
		}

		.embla_controls {
			padding: 0;
			width: 100%;
			top: 0;
			z-index: 3;
			position: initial;

			@media (max-width: 680px) {
				display: none;
			}

			.embla_control {
				padding: 0 15px;
				position: absolute;
				top: calc(44% - 21.5px);
				justify-content: center;
				transition: unset;

				&:hover {
					background: white;
					width: 43px;
					padding: 0;
					justify-content: center;
					box-shadow: 0 0 8px rgba(0, 0, 0, 0.11);
				}

				&.embla__prev {
					left: 3px;
				}

				&.embla__next {
					right: 3px;
				}
			}
		}
	}

	.embla__container {
		display: flex;
		margin: 0 -7px;
	}

	.embla__slide {
		position: relative;
		flex: 0 0 50%;

		img {
			width: 100%;
			height: 50vw;
			object-fit: cover;
			object-position: bottom center;
		}
	}

	.tabs-container {
		max-width: 680px;
		width: 50%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		column-gap: 24px;
		flex-wrap: wrap;

		.mobile-tabs-header {
			display: none;
			width: 100%;
			text-align: center;
			margin-bottom: 20px;

			.mobile-header {
				font-size: 16px;
				margin-bottom: 15px;
			}

			select {
				width: 160px;
				border: 1px solid black;
				background: transparent;
				border-radius: 5px;
				padding: 8px 17px 8px 12px;
				line-height: 1;
				font-size: 12px;
				color: #040707;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
			}

			@media (max-width: 680px) {
				display: block;
				width: 100%;
			}
		}

		.tabs-header {
			display: flex;
			width: 100%;
			margin-bottom: 24px;
			justify-content: center;

			button {
				display: inline-block;
				font-size: var(--microsize);
				font-weight: 700;
				color: #9d9d9d;
				margin: 0 20px;
				border-bottom: 2px solid transparent;
				border-radius: 0;

				&.active {
					color: black;
					border-color: black;
				}

				&:hover {
					color: black;
				}
			}

			@media (max-width: 680px) {
				display: none;
			}
		}

		.product-box {
			display: inline-flex;
			flex-flow: column nowrap;
			width: auto;
			padding: 0px 20px;
			max-width: unset;

			.add-wish {
				top: 8px;
				right: 24px;

				@media (max-width: 680px) {
					top: 0px;
					right: 20px;
				}

				@media (max-width: 575px) {
					right: 9px;
				}
			}

			& > svg {
				top: 15px;
				right: 35px;
			}

			.prod-details {
				background: unset;
			}

			.prod-img {
				position: relative;

				a {
					display: block;
					position: relative;

					img {
						width: 100%;
						height: 100%;

						&.on-hover {
							display: block;
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;

							@media (max-width: 900px) {
								display: none !important;
							}
						}
					}
				}
			}

			@media (max-width: 575px) {
				padding: 0px 5px;
			}
		}

		@media (max-width: 1220px) {
			width: 100%;
			margin: 0;
		}
	}

	.main-image-container {
		max-width: 42.1%;
		width: 49%;

		.image-wrapper {
			position: relative;
			display: inline-block;
			float: right;

			.viewall-button {
				position: absolute;
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 48px;
				font-size: 16px;
				min-width: 170px;
				width: fit-content;
				border-radius: 0;
				border-bottom-left-radius: 10px;
				border-top-right-radius: 10px;
				outline: none;
				margin: 0;
				padding: 0 38px 0 20px;
				background: black;
				color: #ffffff;
				left: calc(50% - 85px);
				bottom: 120px;
				transition: box-shadow 0.2s;

				&:hover {
					box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
				}

				&:after {
					right: 20px;
				}

				&:hover:after {
					width: 18px;
					right: 12px;
				}
			}
		}

		@media (max-width: 1220px) {
			max-width: unset;
			width: unset;

			.image-wrapper {
				.viewall-button {
					position: initial;
				}

				img {
					display: none;
				}
			}
		}

		@media (max-width: 480px) {
			.image-wrapper {
				.viewall-button {
					font-size: 14px;
					line-height: 16px;
					min-width: 160px;
					height: 45px;
				}
			}
		}
	}

	&.righttoleft {
		.content-wrapper {
			flex-direction: row-reverse;
		}

		.main-image-container {
			.image-wrapper {
				float: left;
			}
		}
	}
}

@media (min-width: 1440px) {
	.product-tabs .content-wrapper {
		max-width: 1440px;
	}
}

.popup {
	display: none;
}

#info-page {
	background-color: #f4f4f4;

	& > .content-wrapper {
		display: flex;
		justify-content: space-between;
		max-width: 1026px;
		margin: auto;
		padding: 75px 15px 120px 15px;

		@media (max-width: 860px) {
			flex-flow: column nowrap;
			padding: 30px 0;
		}

		#pages-sidebar {
			display: inline-flex;
			flex-flow: column nowrap;
			width: 190px;

			@media (max-width: 860px) {
				display: none;
				/* flex-flow: column nowrap;
                padding: 15px 0 25px 0;
                width: 100%;*/
			}

			.page-button {
				display: block;
				width: 100%;
				padding: 18px 5px 18px 18px;
				line-height: 1;
				margin: 0 0 4px 0;
				border-radius: 5px;
				color: black;
				font-weight: 400;
				text-align: left;

				&.active,
				&:hover {
					background: white;
					font-weight: 700;
				}

				@media (max-width: 860px) {
					padding: 20px 7%;
					border-radius: 0;
				}
			}
		}

		.main-content {
			width: calc(100% - 190px - 15px);
			max-width: 720px;
			background: white;
			padding: 30px 23px;
			border-radius: 5px;
			box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

			@media (max-width: 860px) {
				width: 95%;
				margin: 0 auto;
				max-width: unset;
			}

			.back-section {
				display: none;
			}

			.page-heading {
				font-weight: 400;
				font-size: calc(var(--smallsize) - 4px);
				text-align: center;
				margin: 0 0 20px 0;
			}

			.cms-content > p {
				display: block;
				text-align: center;
				max-width: 485px;
				color: #717171;
				margin: 0 auto 50px auto;
			}

			span {
				margin: 3px 10px 0 0;
			}

			&.return-policy {
				position: relative;
				display: grid;
				padding-bottom: 0;

				p {
					strong {
						display: block;
						margin: 15px 0;
					}
				}

				.contact-section {
					display: block;
					background-color: #f4f4f4;
					margin: 0 -23px;

					@media (max-width: 640px) {
						margin: 0;

						span {
							margin: 0;
						}
					}

					.content-wrapper {
						max-width: 430px;
						display: flex;
						flex-flow: row wrap;
						padding: 10px 0 32px 0;

						& > p {
							display: block;
							width: 100%;
							text-align: center;

							@media (max-width: 640px) {
								margin-top: 0;
								padding: 0 10%;
							}

							@media (max-width: 575px) {
								text-align: left;
							}
						}

						.half-part {
							width: 49%;
							display: flex;
							justify-content: center;

							@media (max-width: 640px) {
								flex-flow: column nowrap;
								align-items: center;
							}

							p {
								margin: 0;

								@media (max-width: 640px) {
									text-align: center;
								}

								@media (max-width: 575px) {
									font-size: 12px;
								}
							}

							&.first {
								border-right: 1px solid #ababab;
							}

							a {
								font-weight: 700;
								color: black;
							}
						}
					}
				}

				.image-container {
					display: flex;
					height: 64px;
					align-items: center;
					justify-content: center;
				}

				.content-buttons {
					display: flex;
					flex-flow: row wrap;
					justify-content: space-around;
					max-width: 390px;
					margin: 0 auto;
					margin-bottom: 78px;

					.button {
						width: 180px;
						height: 100px;
						display: flex;
						flex-flow: column nowrap;
						border-radius: 5px;
						text-align: center;
						align-items: center;
						justify-content: center;
						box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
						margin-bottom: 20px;
						cursor: pointer;
						background: white;

						.title {
							font-weight: 700;
							font-size: var(--nanosize);
						}
					}
				}

				.clicked-content {
					display: none;
				}

				&.clicked {
					.back-section {
						display: block;

						@media (max-width: 640px) {
							padding-top: 20px;
						}
					}

					& > .content-wrapper > .page-heading,
					.cms-content > p,
					.content-buttons {
						display: none;
					}
				}
			}
		}
	}

	& > .content-wrapper.simple-page {
		display: block;
		max-width: 1244px;
		width: 100%;
		padding: 50px 0;
		border-radius: 5px;

		& > .content-wrapper {
			max-width: 836px;
			padding: 0 22px;
			margin: 0 auto 0 0;
		}

		img {
			display: inline-block;
			max-width: 48%;
			margin: 0 1% 10px 0;
			border: 1px solid black;

			&.main {
				width: 100%;
				max-width: 100%;
				height: auto;
				margin: 0 0 35px 0;
			}
		}

		h1 {
			max-width: 100%;
			font-size: var(--mediumsize);
			display: block;
			padding: 0 0 25px 0;
			margin: 0 0 25px 0;
			border-bottom: 1px solid black;
		}

		.cms-content {
			font-size: var(--microsize);
			line-height: 1.5;
		}
	}
}

#contact {
	background-color: #f2f2f2;

	.breadcrumbs-container {
		display: flex;
		height: 34px;
		align-items: center;
		width: 100%;
		background-color: white;
		background-color: white;

		#breadcrumbs {
			max-width: 1230px;
			margin: 0 auto;
			padding: 0 15px;
		}
	}

	.header-banner {
		display: block;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 16vw;
		min-height: 233px;
		max-height: 280px;
	}

	.cms-content {
		display: flex;
		justify-content: space-between;
		max-width: 1200px;
		padding-bottom: 110px;
		gap: 51px 20px;
	}

	.form-area {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		padding: 45px 22px 55px;
		width: 100%;
		max-width: 792px;
		box-shadow: 0px 10px 30px #0000001a;
		background: white;
		margin-top: -43px;
		border-radius: 5px;

		.form-container {
			width: 100%;
		}

		.form-container form {
			display: flex;
			margin: auto;
			flex-flow: row wrap;
			max-width: 590px;

			.form-title {
				display: block;
				width: 100%;
				text-align: center;
				font-weight: 400;
				font-size: 20px;
				margin: 0 0 35px 0;
			}

			select {
				width: 100%;
				padding: 14.5px 35px 14.5px 13px;
				border-radius: 5px;
				background: none;
				border: 1px solid black;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				color: #040707;
			}

			.inputs {
				width: 100%;
			}

			input[type='text'],
			input[type='email'] {
				width: 100%;
			}

			textarea {
				border: none !important;
				background-color: #f2f2f2 !important;
			}

			label {
				display: block;
				width: 100%;

				& > span {
					display: block;
					font-weight: 700;
					margin: 12px 0 15px;
				}

				.input-label {
					margin: 0;
					color: #000000;
				}

				.attach-top {
					margin-top: 27px;
				}
			}

			input[type='file'] {
				display: none;
			}

			.uploadfile-button {
				width: 100%;
				padding: 11px 13px;
				border-radius: 5px;
				margin-bottom: 10px;
				border-radius: 5px;
				border: 1px solid #000000;
				text-align: left;
				font-size: 14px;
				line-height: 1;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.black-arrow-button {
				min-width: 245px;
				text-align: left;
				padding-left: 30px;
				margin-left: auto;
				margin-top: 7px;
			}

			.grey-comment {
				color: #7b7b7b;
			}

			.capcha-align {
				margin-left: auto;
				text-align: end;
			}

			.captcha-wrapper iframe {
				transform-origin: right;
			}

			.form-row {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 17px 0 40px;
				gap: 20px 10px;

				p {
					margin: 0;
				}
			}
		}
	}

	.info-area {
		max-width: 290px;

		.content-wrapper {
			padding: 10px 0 32px 0;

			& > p {
				display: block;
				width: 100%;
				text-align: center;
			}

			h2 {
				font-size: 16px;
				color: #717171;
				margin: 75px 0 15px 0;
				text-align: left;
			}

			.contact-item {
				width: 100%;
				display: flex;
				margin-bottom: 13px;

				span {
					margin-right: 24px;
				}

				p {
					margin: 0;
				}

				&.first {
					border-right: 1px solid #ababab;
				}

				a {
					font-weight: 700;
					color: black;
				}
			}
		}
	}
}

@media (max-width: 820px) {
	#home-slider {
		margin-top: -30px;
	}

	#home-slider .content-wrapper {
		max-width: 660px;
		width: 100%;
	}

	.raffle-carousel .owl-item {
		padding: 30px;
	}

	.raffle-carousel-main,
	.raffle-registration {
		box-shadow: 2px 7px 29px #434b5759;
	}

	.raffle-carousel .owl-nav button {
		top: 195px;
	}

	.raffle-carousel .owl-nav button.owl-prev {
		left: 7%;
	}

	.raffle-carousel .owl-nav button.owl-next {
		right: 7%;
	}

	.raffle-carousel .owl-dots {
		margin-top: -10px;
	}

	#anchor-links a,
	#scroll-to-top {
		margin: 0 10px;
	}

	#anchor-links a:last-child {
		margin-right: 0;
	}

	#scroll-to-top {
		margin-left: 0;
	}
}

@media (max-width: 600px) {
	.slider-go-to-prod a {
		right: 20px;
		bottom: 23px;
	}

	#default-header #burger-btn {
		width: 28px;
		height: 22px;
		background-color: transparent;
		margin-right: 13px;
	}

	#default-header #burger-btn img {
		width: 24px;
		height: 23px;
	}

	.go-to-prod {
		display: block;
	}

	#product-title,
	#product-price,
	#heritage,
	#heritage h2 {
		text-align: left;
	}

	#product-title,
	#product-price {
		padding-left: 5px;
	}

	.cms-content .icons-wrapper {
		flex-direction: column;
		align-items: flex-start;
		margin: 0;
	}

	.cms-content .icons-wrapper .icon-col {
		width: 100%;
		max-width: 200px;
		margin-bottom: 35px;
	}

	.cms-content .icons-wrapper .icon-col:last-child {
		margin-bottom: 0;
	}
}

@media (max-width: 480px) {
	.raffle-prod-details {
		margin-left: 18px;
	}

	.img-container-double {
		display: block;
	}

	.img-container-double > div {
		width: 100%;
		max-width: 300px;
		margin: 0 auto 20px;
	}

	.img-gallery {
		margin-left: -15px;
		margin-right: -15px;
	}

	#raffle-ends,
	#raffle-starts {
		padding-left: 0;
	}

	#raffle-ends span,
	#raffle-starts span {
		margin-right: 10px;
	}

	.popup .content-wrapper {
		padding: 0;
	}

	.cms-content .images-grid {
		flex-direction: column;
	}

	.cms-content .images-grid img:nth-child(n) {
		width: 100%;
		margin-bottom: 35px;
	}

	.cms-content .rounded {
		height: 236px;
		object-fit: cover;
	}

	.cms-content .images-grid img:nth-child(3) {
		height: 182px;
		margin-bottom: 0;
	}

	.cms-content .orange-btn {
		margin: 25px auto 0;
	}
}

@media (max-width: 420px) {
	.birth-date-options .select-day,
	.birth-date-options .select-year {
		width: 31%;
	}

	.birth-date-options .select-month {
		width: 31%;
	}
}

@media (max-width: 600px), (max-height: 500px) {
	#close-modal {
		top: 20px;
		right: 15px;
		padding-right: 0;
	}

	#close-modal svg path {
		stroke: #000;
		fill: #000;
	}

	#main-menu {
		text-align: left;
		background: #e9edf3;
		border-bottom: none;
		max-width: none;
	}

	#main-menu li {
		padding-left: 46px;
	}

	#user-options {
		max-width: none;
		padding-left: 0;
		border-bottom: none;
	}

	#external li,
	#user-options li,
	#lang {
		padding-left: 15px;
	}

	#main-menu li {
		padding-bottom: 11px;
	}

	#user-options li {
		padding-bottom: 8px;
		padding-top: 11px;
	}

	#external li {
		padding-top: 17px !important;
		padding-bottom: 17px !important;
	}

	#lang {
		padding-top: 8px;
		padding-bottom: 16px;
	}

	#menu-modal ul#external {
		background: #e9edf3;
	}

	#main-menu li:last-child {
		border-bottom: none;
		padding-bottom: 12px;
	}

	#user-options svg path {
		stroke: #040707;
	}

	#user-options svg path#Path_224 {
		fill: #040707;
	}

	#product,
	#heritage,
	#raffle-ends,
	#raffle-starts,
	.faq-content {
		font-size: 16px;
	}
}

.search-screen {
	width: 100%;
	height: calc(100vh - 60px);
	z-index: 10000;
	background: #f2f4f8;
	overflow: auto;
	scrollbar-width: none;
	top: 125px;

	.content-wrapper {
		max-width: 1160px;
		padding: 0 15px;
	}

	@media (max-width: 767px) {
		.exit-search {
			max-width: unset;
			width: 100%;
			padding: 0 33px;
			top: 20px;
		}
	}

	&::-webkit-scrollbar {
		width: 0;
	}

	.search-container {
		display: none;
		@media (max-width: 1200px) {
			display: block;
		}

		.search {
			background-color: #fff;
			padding: 15px 45px 15px;

			@media (max-width: 767px) {
				padding: 15px 12px 15px;
			}

			input {
				display: block;
				background-color: #f2f4f8;
			}
			button {
				background-color: #e2e5eb;
				width: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.searchbar-window-predictions {
			position: unset;
			padding: 15px 45px;

			@media (max-width: 767px) {
				gap: 10px;
				padding: 15px 12px;
			}
		}
	}

	.search-results-loader {
		max-width: 1160px;
		padding: 0 15px 200px;
		margin: auto;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 16px;

		@media (max-width: 1100px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media (max-width: 800px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (max-width: 350px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	.search-results-container {
		max-width: 1160px;
		padding: 0 15px 200px;
		margin: auto;
		.search-results-title {
			position: relative;
			font-size: 20px;
			font-family: 'Roboto Condensed';
			margin: 40px 0 30px;

			@media (max-width: 1200px) {
				margin: 30px 0 30px;
			}
		}

		.search-results {
			position: relative;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			gap: 16px;

			@media (max-width: 1100px) {
				grid-template-columns: repeat(3, 1fr);
			}

			@media (max-width: 800px) {
				grid-template-columns: repeat(2, 1fr);

				.product-card {
					max-width: 100%;
				}
			}

			@media (max-width: 350px) {
				grid-template-columns: repeat(1, 1fr);

				.product-card {
					max-width: 100%;
				}
			}
		}

		.show-all-results {
			width: 100%;
			display: flex;
			justify-content: center;

			button {
				width: 300px;
				z-index: 10000;
				position: fixed;
				bottom: 4vh;
				-webkit-box-shadow: 2px 10px 6px -6px rgba(0, 0, 0, 0.75);
				-moz-box-shadow: 2px 10px 6px -6px rgba(0, 0, 0, 0.75);
				box-shadow: 2px 10px 6px -6px rgba(0, 0, 0, 0.75);
			}
		}
	}
}

.no-results {
	text-align: left;
	margin-top: 40px;

	#personalized-products {
		@media (max-width: 768px) {
			background-color: #f2f4f8;
		}
	}

	.content-wrapper {
		display: block !important;
		padding: 0 !important;

		.swiper {
			width: 100%;
		}
	}
}

/* CUSTOM CHECKBOX */
.custom-checkbox {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 17px;
	cursor: pointer;
	font-size: 16px;
	color: #6e7486;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	/* Hide the browser's default checkbox */
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 4px;
		left: 0;
		height: 20px;
		width: 20px;
		border: 1px solid #b5bac3;
		border-radius: 3px;
	}

	/* When the checkbox is checked, add a blue background */
	input:checked ~ .checkmark {
		border: 1px solid #b5bac3;
		background-color: #fff;
		border-radius: 3px;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.checkmark:after {
		left: 6px;
		top: 2px;
		width: 6px;
		height: 10px;
		border: solid #000;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}
/* CUSTOM CHECKBOX */

// APPLICATION LANDING PAGE

#application-landing {
	position: relative;

	.breadcrumbs-container {
		position: sticky;
		z-index: 1;
		background: #fff;
	}

	.content-wrapper {
		background: #f0f0f0;
		padding: 0;
		text-align: left;
	}

	.upper-section {
		padding-top: 32px;
		padding-bottom: 50px;

		h1,
		.texts {
			padding: 0 80px;
		}

		h1 {
			line-height: 60px;

			.title {
				margin: 0;
				letter-spacing: -1px;

				&:last-child {
					font-size: 62px;
					color: #0154ff;
				}
			}
		}

		.texts {
			font-size: 20px;
			line-height: normal;

			p {
				margin: 20px 0;

				&:nth-child(1) {
					max-width: 520px;
				}

				&:nth-child(2),
				&:nth-child(3) {
					max-width: 720px;
				}
			}
		}

		@media (max-width: 1900px) {
			position: sticky;
			z-index: 1;
			background: #f0f0f0;
		}

		@media (max-width: 575px) {
			padding-top: 50px;
			padding-bottom: 0;

			h1,
			.texts {
				padding: 0 28px;
			}

			h1 {
				line-height: normal;

				.title {
					font-size: 24px;

					&:last-child {
						font-size: 32px;
					}
				}
			}

			.texts {
				font-size: 16px;
			}
		}
	}

	.dual-monitor {
		display: grid;
		grid-template-columns: 63% 37%;

		@media (max-width: 767px) {
			grid-template-columns: 100%;
		}

		.monitor2 {
			@media (max-width: 767px) {
				position: fixed;
				left: 50%;
				transform: translateX(-50%);
				bottom: 5%;
			}
		}
	}

	.steps-section {
		text-align: left;

		@media (max-width: 575px) {
			text-align: center;
		}

		h2 {
			padding: 0 30px;
			padding-bottom: 100px;
			font-size: 34px;
			line-height: normal;
			letter-spacing: -1px;
			margin: 0;

			@media (max-width: 1900px) {
				position: sticky;
				z-index: 1;
				background: #f0f0f0;
			}

			@media (max-width: 767px) {
				padding-bottom: 0;
				font-size: 30px;
				line-height: normal;
			}
		}

		.steps {
			display: flex;
			flex-direction: column;
			align-items: center;

			@media (max-width: 575px) {
				padding: 0;
				padding-top: 380px;
			}

			.step {
				max-width: 470px;

				@media (max-width: 1200px) {
					max-width: unset;
					width: 100%;
				}

				@media (max-width: 1200px) {
					&.step1 {
						margin-top: 100px;
					}
				}

				.text {
					@media (max-width: 1200px) {
						opacity: 0.92;
						background: #fff;
						padding: 30px 100px;
					}

					@media (max-width: 950px) {
						padding: 12px 50px;
					}

					@media (max-width: 950px) {
						position: sticky;
						z-index: 1;
					}

					@media (max-width: 480px) {
						padding: 10px;
					}
				}

				h3 {
					font-size: 32px;
					color: #0154ff;
					line-height: normal;
				}

				p {
					font-size: 20px;
					line-height: normal;
					margin: 14px 0;
				}

				.content {
					background: #f0f0f0;
					height: 400px;

					@media (max-width: 767px) {
						height: 500px;
					}
				}
			}
		}
	}

	.footer-section {
		padding-top: 60px;

		@media (max-width: 800px) {
			position: sticky;
			z-index: 1;
			background: #f0f0f0;
		}

		@media (max-width: 575px) {
			padding-top: 0;
		}

		.inner-part {
			padding: 0 180px;
			padding-bottom: 160px;
			display: grid;
			place-items: center;
			gap: 60px;

			@media (max-width: 575px) {
				padding: 80px 0;
			}

			.custom-checkbox {
				.checkmark {
					top: 0;
				}
			}

			button {
				width: 256px;
				border-radius: 60px !important;
				font-size: 20px !important;
			}

			img {
				width: 114px;
				opacity: 0.3;

				@media (max-width: 575px) {
					display: none;
				}
			}
		}
	}

	.parallax-phone {
		position: sticky;
		padding-top: 160px;
		top: 0;
		right: 0;

		@media (max-width: 767px) {
			padding-top: 0;
		}

		.parallax-container {
			position: relative;
			height: 100%;
			margin: 20px;
			max-width: 280px;
			overflow: hidden;
			border-radius: 55px;
			background: #fff;

			@media (max-width: 920px) {
				margin: 0;
				border-radius: 50px;
			}
		}

		.phone {
			border-radius: 55px;
			max-width: 280px;
			max-height: 750px;
			position: sticky;
			z-index: 1;

			@media (max-width: 1440px) {
				width: 346px;
			}

			@media (max-width: 900px) {
				border-radius: 46px;
			}

			@media (max-width: 600px) {
				border-radius: 46px;
			}

			@media (max-width: 400px) {
				border-radius: 32px;
			}

			@media (max-height: 750px) {
				height: 500px;
				width: 346px;
			}
		}

		.image-holder {
			position: absolute;
			border-radius: 24px;
			top: 10px;
			height: calc(100% - 22px);
			left: 15px;
			width: calc(100% - 30px);
			min-width: 250px;

			&.next-step {
				transform: translateY(-52%);
			}

			&.initial-step {
				display: none;
			}

			@media (max-width: 767px) {
				&.initial-step {
					display: block;
					top: 50%;
					transform: translateY(-50%);
					height: unset;
				}
			}
		}
	}
	.footer-img {
		position: sticky;
	}
}

// APPLICATION MODE
#application-mode {
	width: 100%;
	height: 35px;
	background-color: #ff0145;
	position: sticky;
	top: 0;

	.container {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		gap: 20px;
		height: 100%;

		@media (max-width: 375px) {
			gap: 12px;
		}

		h2 {
			font-size: 15px;
			margin: 0;

			@media (max-width: 340px) {
				font-size: 14px;
			}
		}

		h1 {
			font-size: 18px;
			margin: 0;
		}

		.switch-input {
			position: relative;
			display: inline-block;
			width: 40px;
			height: 18px;

			/* Hide the checkbox */
			.switch-input__checkbox {
				opacity: 0;
				width: 0;
				height: 0;
			}

			/* The slider */
			.switch-input__slider {
				position: absolute;
				cursor: pointer;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: #ccc;
				transition: 0.4s;
				border-radius: 34px;

				&:before {
					position: absolute;
					content: '';
					height: 13px;
					width: 13px;
					left: 3px;
					bottom: 2px;
					background-color: white;
					transition: 0.4s;
					border-radius: 50%;
				}
			}

			.switch-input__checkbox + .switch-input__slider {
				background-color: #2253f5;
			}

			/* Style the slider when the checkbox is checked */
			.switch-input__checkbox:checked + .switch-input__slider {
				background-color: #2196f3;
			}

			.switch-input__checkbox:checked + .switch-input__slider:before {
				transform: translateX(20px);
			}
		}
	}
}

.application-poupup-modal {
	.modal-dialog-overlay {
		align-items: center;
	}

	@media (max-width: 768px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: auto;
		}
	}
	@media (max-width: 575px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: flex-end;
			overflow: auto;
		}
	}

	.application-popup {
		max-width: 575px;
		width: 100%;
		margin: 20px 0;
		background-color: #fff;

		@media (max-width: 575px) {
			margin: 0;
			border-radius: 20px 20px 0 0;
		}

		.application-popup-container {
			padding: 40px 20px 40px;
			max-width: 450px;
			margin: auto;

			@media (max-width: 420px) {
				padding: 40px 20px 40px;
			}

			.header {
				margin-bottom: 45px;
				text-align: center;

				.title {
					font-size: 22px;
					font-family: 'Roboto Condensed';
					font-weight: 600;
					line-height: 34px;
					text-align: center;

					@media (max-width: 420px) {
						margin-left: 1px;
					}

					@media (max-width: 360px) {
						font-size: 20px;
					}

					span {
						color: #ff0145;
						font-size: 25px;
						text-transform: uppercase;
					}
				}
			}

			.footer {
				width: 100%;
				display: flex;
				gap: 10px;
				button {
					width: 100% !important;
					max-width: 100% !important;

					&.mode-actions {
						padding: 18px 0px !important;
					}
				}
			}
		}
	}
}

#black-friday {
	min-height: 100vh;
	height: 100%;
	background-color: #000;
	position: relative;

	.bread-simple {
		z-index: 10;
		position: absolute;
		top: 20px;
		left: 5%;
		color: #fff;
		border: 0px;

		@media (max-width: 600px) {
			top: 10px;
			left: 10px;
		}

		@media (max-width: 450px) {
			top: 3px;
			left: 3px;
		}

		.bread-item {
			font-size: 20px;
			font-weight: bold;

			@media (max-width: 600px) {
				font-size: 16px;
			}

			@media (max-width: 450px) {
				font-size: 14px;
			}

			&:last-child {
				margin-left: 8px;
			}
		}

		a {
			color: #fff;
		}
	}

	.banner {
		padding: 100px 0;
		max-width: 100%;
		width: 100%;
		// height: 975px;
		aspect-ratio: 1920/800;
		position: relative;
		display: flex;
		flex-direction: column;

		@media (max-width: 750px) {
			height: 500px;
		}

		@media (max-width: 475px) {
			height: 385px;
		}

		@media (max-width: 400px) {
			height: 285px;
		}

		@media (max-width: 850px) {
			padding: 35px 0;
		}

		.content-wrapper {
			margin: 0 auto;
			@media (max-width: 475px) {
				padding: 0;
			}
		}
	}

	.outer {
		aspect-ratio: 1920/1080;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.mega-container {
		@media (max-width: 475px) {
			margin: 30px auto 25px;
		}

		.mega-title {
			display: block;
			color: #fff;
			text-align: center;
			padding: 0px 0 50px;
			margin-top: 10px;
			font-family: 'Roboto Condensed';

			@media (max-width: 700px) {
				margin-top: 75px;
			}

			@media (max-width: 475px) {
				margin-top: 45px;
			}

			@media (max-width: 400px) {
				margin-top: 0px;
			}

			.title {
				font-size: 42px;
				line-height: 52px;
				@media (max-width: 700px) {
					font-size: 35px;
				}

				@media (max-width: 600px) {
					line-height: 35px;
					font-size: 30px;
				}

				@media (max-width: 475px) {
					line-height: 29px;
					font-size: 22px;
				}

				@media (max-width: 370px) {
					font-size: 22px;
				}
			}
			.badge {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 45px;
				margin-top: 20px;
				font-family: 'Roboto Condensed';

				@media (max-width: 475px) {
					gap: 10px;
				}

				@media (max-width: 400px) {
					margin-top: 5px;
				}

				b:first-child {
					font-family: 'Roboto Condensed';
					font-size: 100px;
					line-height: 100px;
					@media (max-width: 700px) {
						line-height: 80px;
						font-size: 75px;
					}
					@media (max-width: 600px) {
						line-height: 50px;
						font-size: 55px;
					}
					@media (max-width: 475px) {
						line-height: 59px;
						font-size: 45px;
					}
				}
				b:nth-child(2) {
					font-family: 'Roboto Condensed';
					font-size: 200px;
					line-height: 200px;
					@media (max-width: 700px) {
						line-height: 130px;
						font-size: 140px;
					}
					@media (max-width: 600px) {
						line-height: 90px;
						font-size: 95px;
					}
					@media (max-width: 475px) {
						font-size: 92px;
					}
					@media (max-width: 400px) {
						font-size: 75px;
					}
				}
			}
		}

		.mega-description {
			display: block;
			z-index: 10;
			color: #fff;
			text-align: center;
			font-size: 18px;
			line-height: 24px;
		}
	}

	.marquee-images {
		width: 100%;
		margin-top: 250px;
		z-index: 100;
		background: rgb(107, 25, 249);
		background: linear-gradient(168deg, rgba(107, 25, 249, 1) 0%, rgba(34, 83, 245, 1) 17%, rgba(107, 25, 249, 1) 82%);
		position: relative;

		@media (max-width: 750px) {
			margin-top: 150px;
		}

		@media (max-width: 475px) {
			margin-top: 75px;
		}

		@media (max-width: 400px) {
			margin-top: 70px;
		}

		.swiper {
			min-height: unset;
			align-items: center;
			height: 70px;
		}

		.swiper-slide {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 25px;
			width: auto;
		}

		.up60 {
			max-height: 65px;
			width: 140px;
			@media (max-width: 475px) {
				width: 120px;
				height: 55px;
			}
		}

		.bf {
			max-height: 65px;
			width: 250px;
			@media (max-width: 475px) {
				width: 200px;
				height: 50px;
			}
		}

		.asics {
			max-height: 65px;
			width: 200px;
			@media (max-width: 475px) {
				width: 140px;
				height: 50px;
			}
		}

		.swiper {
			width: 100%;
			min-height: unset;
			display: flex;
			align-items: center;
			padding: 5px;

			@media (max-width: 475px) {
				padding: 5px;
			}
		}
	}

	.gender-category {
		margin-top: 50px;
		height: 470px;
		display: flex;
		align-items: end;
		justify-content: center;
		z-index: 10;
		position: relative;

		&.male {
			margin-top: -285px;
			@media (max-width: 750px) {
				margin-top: -100px;
			}
		}

		@media (max-width: 565px) {
			margin-top: -80px;
			height: 365px;
		}
	}

	.male-section {
		.offers {
			display: grid;
			grid-template-columns: 1fr;
			gap: 50px;

			.offer {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 50px;
				font-family: 'Roboto Condensed';

				.offer-information {
					max-width: 450px;
					@media (max-width: 850px) {
						max-width: 500px;
					}

					h2 {
						font-family: 'Roboto Condensed';
						text-align: left;
						color: #fff;

						@media (max-width: 850px) {
							text-align: center;
						}

						span {
							font-size: 49px;
							line-height: 52px;
						}

						b {
							font-size: 101px;
							line-height: 110px;
							font-family: 'Roboto Condensed';
						}

						small {
							font-size: 34px;
							line-height: 38px;
						}
					}

					button {
						margin-top: 25px;

						@media (max-width: 850px) {
							display: block;
							margin: 25px auto 0;
						}
					}
				}

				.offer-image img {
					width: 600px;
					height: 500px;
				}

				&:first-child {
					@media (max-width: 850px) {
						flex-direction: column-reverse;
					}
					.offer-information {
						padding-left: 150px;
						@media (max-width: 850px) {
							padding-left: 0px;
						}
					}
				}

				&:nth-child(2) {
					@media (max-width: 850px) {
						flex-direction: column;
					}
				}

				&:nth-child(3) {
					margin-top: 85px;

					@media (max-width: 850px) {
						flex-direction: column;
						margin-top: 0px;
					}

					.offer-image img {
						width: 475px;
						height: 475px;

						@media (max-width: 1024px) {
							width: 375px;
							height: 375px;
						}
					}

					.brand-image img {
						width: 175px;

						// @media (max-width: 1024px) {
						// 	display: none;
						// }
					}

					.offer-information {
						@media (max-width: 850px) {
							order: 3;
							padding-left: 0px;
						}
					}

					.offer-image {
						@media (max-width: 850px) {
							order: 1;
						}
					}
					.brand-image {
						@media (max-width: 850px) {
							order: 2;
						}
					}
				}
			}
		}
	}

	.female-section {
		.offers {
			display: grid;
			grid-template-columns: 1fr;
			gap: 50px;

			.offer {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 50px;

				@media (max-width: 850px) {
					gap: 25px;
					margin-bottom: 50px;
				}

				.offer-information {
					max-width: 450px;
					@media (max-width: 850px) {
						max-width: 500px;
					}

					h2 {
						font-family: 'Roboto Condensed';
						text-align: left;
						color: #fff;

						@media (max-width: 850px) {
							text-align: center;
						}

						span {
							font-size: 49px;
							line-height: 52px;
						}

						b {
							font-size: 101px;
							line-height: 110px;
							font-family: 'Roboto Condensed';
						}

						small {
							font-size: 34px;
							line-height: 38px;
						}
					}

					button {
						margin-top: 25px;

						@media (max-width: 850px) {
							display: block;
							margin: 25px auto 0;
						}
					}
				}

				.offer-image img {
					width: 600px;
					height: 500px;
				}

				&:first-child {
					@media (max-width: 850px) {
						flex-direction: column-reverse;
					}
					.offer-information {
						padding-left: 150px;
						@media (max-width: 1050px) {
							padding-left: 0px;
						}
					}
				}

				&:nth-child(2) {
					margin-top: 85px;

					@media (max-width: 850px) {
						flex-direction: column;
						margin-top: 0px;
					}

					.offer-information {
						@media (max-width: 850px) {
							order: 3;
							padding-left: 0px;
						}
					}

					.offer-image {
						@media (max-width: 850px) {
							order: 1;
						}
						img {
							width: 475px;
							height: 475px;

							@media (max-width: 1024px) {
								width: 375px;
								height: 375px;
							}
						}
					}

					.brand-image {
						@media (max-width: 850px) {
							order: 2;
						}

						img {
							width: 175px;
						}
					}
				}
			}
		}
	}

	.gender-category-child {
		margin-top: 150px;
		margin-bottom: 100px;
		height: 470px;
		display: flex;
		justify-content: center;

		@media (max-width: 850px) {
			margin-top: 20px;
		}

		@media (max-width: 565px) {
			height: 365px;
		}
	}

	.child-section {
		.offers {
			display: flex;
			justify-content: space-between;
			gap: 50px;

			@media (max-width: 850px) {
				flex-direction: column;
			}

			.offer {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 50px;

				.offer-information {
					@media (max-width: 850px) {
						max-width: 500px;
					}

					h2 {
						font-family: 'Roboto Condensed';
						text-align: left;
						color: #fff;

						@media (max-width: 850px) {
							text-align: center;
						}

						span {
							font-size: 49px;
							line-height: 52px;
						}

						b {
							font-size: 101px;
							line-height: 110px;
							font-family: 'Roboto Condensed';
						}

						small {
							font-size: 34px;
							line-height: 38px;
						}
					}

					button {
						margin-top: 25px;
						display: block;
						margin: 25px 0 0;

						@media (max-width: 850px) {
							display: block;
							margin: 25px auto 0;
						}
					}
				}
			}
		}
	}

	.text-outline {
		color: #000; /* Fallback for older browsers */
		color: rgba(0, 0, 0, 1);
		font-size: 50px;
		text-shadow:
			-1px 1px 0 #fff,
			1px 1px 0 #fff,
			1px -1px 0 #fff,
			-1px -1px 0 #fff;
		font-weight: 900;
		text-align: center;
		margin: 50px 0;
		font-family: 'Roboto Condensed';
		line-height: 70px;
	}

	.countdown {
		margin: 60px auto 50px;
		display: flex;
		justify-content: center;
		gap: 100px;

		@media (max-width: 765px) {
			gap: 50px;
		}

		@media (max-width: 565px) {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 12px;
		}

		@media (max-width: 500px) {
			margin: 20px auto 30px;
		}

		& > div {
			font-weight: 700;
			border-radius: 12px;
			display: flex;
			flex-direction: column;
			gap: 12px;

			@media (max-width: 625px) {
				gap: 8px;
			}

			@media (max-width: 565px) {
				gap: 5px;
			}

			b {
				min-width: 100px;
				background-color: #fff;
				height: 100px;
				color: #000;
				width: 100%;
				font-size: 55px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: transparent linear-gradient(290deg, #6b19f9 0%, #2253f5 100%) 0% 0% no-repeat padding-box;
				-webkit-text-fill-color: #fff;
				border: 2px solid #fff;

				@media (max-width: 565px) {
					max-width: 100px;
					min-width: 65px;
				}

				@media (max-width: 460px) {
					height: 65px;
					font-size: 24px;
				}
			}

			span {
				color: #fff;
				text-align: center;
				font-size: 15px;

				@media (max-width: 565px) {
					font-size: 10px;
				}

				@media (max-width: 460px) {
					font-size: 11px;
				}
			}
		}
	}

	.submega-title {
		max-width: 700px;
		margin: 0 auto;
		color: #fff;
		text-align: center;
		font-size: 34px;
		font-family: 'Roboto Condensed';
		line-height: 40px;

		@media (max-width: 565px) {
			font-size: 24px;
		}
	}

	.newsletter-section {
		color: #fff;
		max-width: 750px;
		width: 100%;
		margin: 30px auto;

		.input-group {
			width: 100%;
			display: flex;
			align-items: center;
			gap: 10px;

			@media (max-width: 565px) {
				flex-direction: column;
			}

			.form-input {
				width: 60%;
				display: flex;
				align-items: center;
				margin: 0;

				@media (max-width: 565px) {
					width: 100%;
				}

				input {
					width: 100%;
					height: 50px;
					outline: none;

					&:focus-visible,
					&:focus {
						border: 0px;
					}

					&::placeholder {
						opacity: 1;
					}

					&:focus::-webkit-input-placeholder {
						opacity: 1;
					}

					&:focus:-moz-placeholder {
						opacity: 1;
					}

					&:focus::-moz-placeholder {
						opacity: 1;
					}

					&:focus:-ms-input-placeholder {
						opacity: 1;
					}

					&:focus::placeholder {
						opacity: 1;
					}

					::-webkit-input-placeholder {
						color: #7b7b7b;
						font-family: 'Roboto', sans-serif;
						font-weight: 400;
						font-size: 14px;
						opacity: 1;
					}
				}

				span {
					height: 50px;
					width: 50px;
					display: flex;
					align-items: center;
					background-color: #f3f3f3;

					svg {
					}
				}
			}

			button {
				display: block;
				height: 50px;
				width: 40%;

				@media (max-width: 565px) {
					width: 100%;
				}
			}
		}

		p {
			font-size: 14px;
			line-height: 20px;
		}

		.newsletter-acceptance-container {
			margin-top: 25px;
			display: flex;
			flex-direction: column;
			gap: 15px;
		}
	}

	.top-brands-section {
		margin-top: 50px;
		padding: 50px 0 80px;

		@media (max-width: 565px) {
			padding: 50px 0 40px;
		}

		h2 {
			font-size: 34px;
			line-height: 35px;
			color: #fff;
			gap: 10px;
			text-align: center;
			font-family: 'Roboto Condensed';

			@media (max-width: 565px) {
				font-size: 24px;
			}
		}

		.brands-images {
			display: flex;
			justify-content: center;
			gap: 44px;
			flex-wrap: wrap;
			margin: 50px auto 40px;

			img {
				max-width: 250px;

				@media (max-width: 625px) {
					max-width: 200px;
				}

				@media (max-width: 465px) {
					gap: 20px;
					max-width: 150px;
				}
			}
		}
	}

	.why-us {
		padding: 50px 0 80px;
		h2 {
			font-size: 34px;
			line-height: 35px;
			color: #fff;
			gap: 10px;
			text-align: center;
			font-family: 'Roboto Condensed';

			@media (max-width: 565px) {
				font-size: 24px;
			}

			b {
				font-weight: 700;

				span {
					color: #0154ff;
				}
			}
		}

		.container {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 40px;
			row-gap: 60px;
			padding-top: 50px;

			@media (max-width: 1024px) {
				grid-template-columns: 1fr 1fr;

				.item:nth-child(3) {
					grid-column: span 2;
				}
			}

			@media (max-width: 565px) {
				gap: 5px;
			}

			.item {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 10px;
				text-align: center;
				line-height: 30px;

				b {
					color: #fff;
					font-size: 25px;
					font-family: 'Roboto Condensed';

					@media (max-width: 565px) {
						font-size: 20px;
					}
				}

				span {
					color: #fff;
					font-size: 18px;
					font-family: 'Roboto Condensed';

					@media (max-width: 565px) {
						font-size: 16px;
					}
				}
			}
		}
	}

	.faq-banner {
		height: 200px;
		margin-bottom: 35px;

		.faq-icon {
			background-color: #0154ff;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			margin: -50px auto;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 2;
		}

		.faq-background {
			width: 100%;
			height: 100%;
			background-image: url(../images/discount-bg.svg) !important;
			background-repeat: no-repeat !important;
			background-position: center !important;
			background-size: cover;
			padding-top: 70px;

			.faq-title {
				font-size: 34px;
				color: #fff;
			}

			.faq-description {
				font-size: 18px;
				margin: auto;
				color: #fff;
				text-align: center;
				margin-top: 18px;
			}
		}
	}

	.faq {
		padding: 50px 0;
		color: #fff;

		.questions {
			padding-top: 50px;
			width: 100%;
			display: grid;
			grid-template-columns: 1fr;
			gap: 20px;

			.question {
				border-bottom: 2px solid #0154ff;
				padding-bottom: 25px;
				cursor: pointer;
				transition: 0.3s;

				.heading {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 20px;

					.title {
						font-size: 20px;
						font-weight: bold;
						text-align: left;
						@media (max-width: 565px) {
							font-size: 16px;
						}
					}

					button {
						min-width: 40px;
						min-height: 40px;
						background-color: #fff;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 50%;

						svg {
							width: 14px;
							height: 14px;
							transform: rotate(90deg);

							stroke {
								fill: #0154ff;
							}
						}
					}
				}

				.content {
					display: none;
					padding-top: 20px;
					font-size: 16px;
					line-height: 24px;
				}

				&.active {
					.content {
						display: block;
					}
					button svg {
						transform: rotate(270deg);
					}
				}
			}
		}
	}
}

#pre-black-friday {
	min-height: 100vh;
	height: 100%;
	background-color: #000;
	position: relative;

	.bread-simple {
		z-index: 10;
		position: absolute;
		top: 20px;
		left: 5%;
		color: #fff;
		border: 0px;

		@media (max-width: 600px) {
			top: 10px;
			left: 10px;
		}

		@media (max-width: 450px) {
			top: 3px;
			left: 3px;
		}

		.bread-item {
			font-size: 20px;
			font-weight: bold;

			@media (max-width: 600px) {
				font-size: 16px;
			}

			@media (max-width: 450px) {
				font-size: 14px;
			}

			&:last-child {
				margin-left: 8px;
			}
		}

		a {
			color: #fff;
		}
	}

	.banner {
		padding: 100px 0;
		max-width: 100%;
		width: 100%;
		// height: 975px;
		aspect-ratio: 1920/800;
		position: relative;
		display: flex;
		flex-direction: column;

		// @media (max-width: 750px) {
		// 	height: 500px;
		// }

		// @media (max-width: 475px) {
		// 	height: 355px;
		// }

		// @media (max-width: 400px) {
		// 	height: 265px;
		// }

		@media (max-width: 850px) {
			padding: 35px 0;
		}

		.content-wrapper {
			margin: 0 auto;
			@media (max-width: 475px) {
				padding: 0;
			}
		}
	}

	.outer {
		aspect-ratio: 1920/1080;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.mega-container {
		@media (max-width: 475px) {
			margin: 30px auto 25px;
		}

		.mega-title {
			display: none;
			color: #fff;
			text-align: center;
			padding: 0px 0 20px;
			margin-top: 30px;
			font-family: 'Roboto Condensed';

			@media (max-width: 700px) {
				margin-top: 75px;
			}

			@media (max-width: 475px) {
				margin-top: 45px;
			}

			@media (max-width: 400px) {
				margin-top: 0px;
			}

			.title {
				font-size: 42px;
				line-height: 52px;
				@media (max-width: 700px) {
					font-size: 35px;
				}

				@media (max-width: 600px) {
					line-height: 35px;
					font-size: 30px;
				}

				@media (max-width: 475px) {
					line-height: 29px;
					font-size: 22px;
				}

				@media (max-width: 370px) {
					font-size: 22px;
				}
			}
			.badge {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 45px;
				margin-top: 20px;
				font-family: 'Roboto Condensed';

				@media (max-width: 475px) {
					gap: 10px;
				}

				@media (max-width: 400px) {
					margin-top: 5px;
				}

				b:first-child {
					font-family: 'Roboto Condensed';
					font-size: 100px;
					line-height: 100px;
					@media (max-width: 700px) {
						line-height: 80px;
						font-size: 75px;
					}
					@media (max-width: 600px) {
						line-height: 50px;
						font-size: 55px;
					}
					@media (max-width: 475px) {
						line-height: 59px;
						font-size: 45px;
					}
				}
				b:nth-child(2) {
					font-family: 'Roboto Condensed';
					font-size: 200px;
					line-height: 200px;
					@media (max-width: 700px) {
						line-height: 130px;
						font-size: 140px;
					}
					@media (max-width: 600px) {
						line-height: 90px;
						font-size: 95px;
					}
					@media (max-width: 475px) {
						font-size: 92px;
					}
					@media (max-width: 400px) {
						font-size: 75px;
					}
				}
			}
		}

		.mega-description {
			display: none;
			z-index: 10;
			color: #fff;
			text-align: center;
			font-size: 18px;
			line-height: 24px;
		}
	}

	.marquee-images {
		width: 100%;
		margin-top: 250px;
		z-index: 100;
		background: rgb(107, 25, 249);
		background: linear-gradient(168deg, rgba(107, 25, 249, 1) 0%, rgba(34, 83, 245, 1) 17%, rgba(107, 25, 249, 1) 82%);
		position: relative;

		@media (max-width: 750px) {
			margin-top: 150px;
		}

		@media (max-width: 475px) {
			margin-top: 75px;
		}

		@media (max-width: 400px) {
			margin-top: 70px;
		}

		.swiper {
			min-height: unset;
			align-items: center;
			height: 70px;
		}

		.swiper-slide {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 25px;
			width: auto;
		}

		.up60 {
			max-height: 65px;
			width: 140px;
			@media (max-width: 475px) {
				width: 120px;
				height: 55px;
			}
		}

		.bf {
			max-height: 65px;
			width: 250px;
			@media (max-width: 475px) {
				width: 200px;
				height: 50px;
			}
		}

		.asics {
			max-height: 65px;
			width: 200px;
			@media (max-width: 475px) {
				width: 140px;
				height: 50px;
			}
		}

		.swiper {
			width: 100%;
			min-height: unset;
			display: flex;
			align-items: center;
			padding: 5px;

			@media (max-width: 475px) {
				padding: 5px;
			}
		}
	}

	.gender-category {
		margin-top: 50px;
		height: 470px;
		display: flex;
		align-items: end;
		justify-content: center;
		z-index: 10;
		position: relative;

		&.male {
			margin-top: -285px;
			@media (max-width: 750px) {
				margin-top: 50px;
			}
		}

		@media (max-width: 565px) {
			height: 365px;
		}
	}

	.male-section {
		.offers {
			display: grid;
			grid-template-columns: 1fr;
			gap: 50px;

			.offer {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 50px;
				font-family: 'Roboto Condensed';

				.offer-information {
					max-width: 450px;
					@media (max-width: 850px) {
						max-width: 500px;
					}

					h2 {
						font-family: 'Roboto Condensed';
						text-align: left;
						color: #fff;

						@media (max-width: 850px) {
							text-align: center;
						}

						span {
							font-size: 49px;
							line-height: 52px;
						}

						b {
							font-size: 101px;
							line-height: 110px;
							font-family: 'Roboto Condensed';
						}

						small {
							font-size: 34px;
							line-height: 38px;
						}
					}

					button {
						margin-top: 25px;

						@media (max-width: 850px) {
							display: block;
							margin: 25px auto 0;
						}
					}
				}

				.offer-image img {
					width: 600px;
					height: 500px;
				}

				&:first-child {
					@media (max-width: 850px) {
						flex-direction: column-reverse;
					}
					.offer-information {
						padding-left: 150px;
						@media (max-width: 850px) {
							padding-left: 0px;
						}
					}
				}

				&:nth-child(2) {
					@media (max-width: 850px) {
						flex-direction: column;
					}
				}

				&:nth-child(3) {
					margin-top: 85px;

					@media (max-width: 850px) {
						flex-direction: column;
						margin-top: 0px;
					}

					.offer-image img {
						width: 475px;
						height: 475px;

						@media (max-width: 1024px) {
							width: 375px;
							height: 375px;
						}
					}

					.brand-image img {
						width: 175px;

						// @media (max-width: 1024px) {
						// 	display: none;
						// }
					}

					.offer-information {
						@media (max-width: 850px) {
							order: 3;
							padding-left: 0px;
						}
					}

					.offer-image {
						@media (max-width: 850px) {
							order: 1;
						}
					}
					.brand-image {
						@media (max-width: 850px) {
							order: 2;
						}
					}
				}
			}
		}
	}

	.female-section {
		.offers {
			display: grid;
			grid-template-columns: 1fr;
			gap: 50px;

			.offer {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 50px;

				@media (max-width: 850px) {
					gap: 25px;
					margin-bottom: 50px;
				}

				.offer-information {
					max-width: 450px;
					@media (max-width: 850px) {
						max-width: 500px;
					}

					h2 {
						font-family: 'Roboto Condensed';
						text-align: left;
						color: #fff;

						@media (max-width: 850px) {
							text-align: center;
						}

						span {
							font-size: 49px;
							line-height: 52px;
						}

						b {
							font-size: 101px;
							line-height: 110px;
							font-family: 'Roboto Condensed';
						}

						small {
							font-size: 34px;
							line-height: 38px;
						}
					}

					button {
						margin-top: 25px;

						@media (max-width: 850px) {
							display: block;
							margin: 25px auto 0;
						}
					}
				}

				.offer-image img {
					width: 600px;
					height: 500px;
				}

				&:first-child {
					@media (max-width: 850px) {
						flex-direction: column-reverse;
					}
					.offer-information {
						padding-left: 150px;
						@media (max-width: 1050px) {
							padding-left: 0px;
						}
					}
				}

				&:nth-child(2) {
					margin-top: 85px;

					@media (max-width: 850px) {
						flex-direction: column;
						margin-top: 0px;
					}

					.offer-information {
						@media (max-width: 850px) {
							order: 3;
							padding-left: 0px;
						}
					}

					.offer-image {
						@media (max-width: 850px) {
							order: 1;
						}
						img {
							width: 475px;
							height: 475px;

							@media (max-width: 1024px) {
								width: 375px;
								height: 375px;
							}
						}
					}

					.brand-image {
						@media (max-width: 850px) {
							order: 2;
						}

						img {
							width: 175px;
						}
					}
				}
			}
		}
	}

	.gender-category-child {
		margin-top: 150px;
		margin-bottom: 100px;
		height: 470px;
		display: flex;
		justify-content: center;

		@media (max-width: 850px) {
			margin-top: 20px;
		}

		@media (max-width: 565px) {
			height: 365px;
		}
	}

	.child-section {
		.offers {
			display: flex;
			justify-content: space-between;
			gap: 50px;

			@media (max-width: 850px) {
				flex-direction: column;
			}

			.offer {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 50px;

				.offer-information {
					@media (max-width: 850px) {
						max-width: 500px;
					}

					h2 {
						font-family: 'Roboto Condensed';
						text-align: left;
						color: #fff;

						@media (max-width: 850px) {
							text-align: center;
						}

						span {
							font-size: 49px;
							line-height: 52px;
						}

						b {
							font-size: 101px;
							line-height: 110px;
							font-family: 'Roboto Condensed';
						}

						small {
							font-size: 34px;
							line-height: 38px;
						}
					}

					button {
						margin-top: 25px;
						display: block;
						margin: 25px 0 0;

						@media (max-width: 850px) {
							display: block;
							margin: 25px auto 0;
						}
					}
				}
			}
		}
	}

	.text-outline {
		color: #000; /* Fallback for older browsers */
		color: rgba(0, 0, 0, 1);
		font-size: 50px;
		text-shadow:
			-1px 1px 0 #fff,
			1px 1px 0 #fff,
			1px -1px 0 #fff,
			-1px -1px 0 #fff;
		font-weight: 900;
		text-align: center;
		margin: 50px 0;
		font-family: 'Roboto Condensed';
		line-height: 70px;
	}

	.countdown {
		margin: 60px auto 50px;
		display: flex;
		justify-content: center;
		gap: 100px;

		@media (max-width: 765px) {
			gap: 50px;
		}

		@media (max-width: 565px) {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 12px;
		}

		@media (max-width: 500px) {
			margin: 20px auto 30px;
		}

		& > div {
			font-weight: 700;
			border-radius: 12px;
			display: flex;
			flex-direction: column;
			gap: 12px;

			@media (max-width: 625px) {
				gap: 8px;
			}

			@media (max-width: 565px) {
				gap: 5px;
			}

			b {
				min-width: 100px;
				background-color: #fff;
				height: 100px;
				color: #000;
				width: 100%;
				font-size: 55px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: transparent linear-gradient(290deg, #6b19f9 0%, #2253f5 100%) 0% 0% no-repeat padding-box;
				-webkit-text-fill-color: #fff;
				border: 2px solid #fff;

				@media (max-width: 565px) {
					max-width: 100px;
					min-width: 65px;
				}

				@media (max-width: 460px) {
					height: 65px;
					font-size: 24px;
				}
			}

			span {
				color: #fff;
				text-align: center;
				font-size: 15px;

				@media (max-width: 565px) {
					font-size: 10px;
				}

				@media (max-width: 460px) {
					font-size: 11px;
				}
			}
		}
	}

	.submega-title {
		max-width: 700px;
		margin: 0 auto;
		color: #fff;
		text-align: center;
		font-size: 34px;
		font-family: 'Roboto Condensed';
		line-height: 40px;

		@media (max-width: 565px) {
			font-size: 24px;
		}
	}

	.newsletter-section {
		color: #fff;
		max-width: 750px;
		width: 100%;
		margin: 30px auto;

		.input-group {
			width: 100%;
			display: flex;
			align-items: center;
			gap: 10px;

			@media (max-width: 565px) {
				flex-direction: column;
			}

			.form-input {
				width: 60%;
				display: flex;
				align-items: center;
				margin: 0;

				@media (max-width: 565px) {
					width: 100%;
				}

				input {
					width: 100%;
					height: 50px;
					outline: none;

					&:focus-visible,
					&:focus {
						border: 0px;
					}

					&::placeholder {
						opacity: 1;
					}

					&:focus::-webkit-input-placeholder {
						opacity: 1;
					}

					&:focus:-moz-placeholder {
						opacity: 1;
					}

					&:focus::-moz-placeholder {
						opacity: 1;
					}

					&:focus:-ms-input-placeholder {
						opacity: 1;
					}

					&:focus::placeholder {
						opacity: 1;
					}

					::-webkit-input-placeholder {
						color: #7b7b7b;
						font-family: 'Roboto', sans-serif;
						font-weight: 400;
						font-size: 14px;
						opacity: 1;
					}
				}

				span {
					height: 50px;
					width: 50px;
					display: flex;
					align-items: center;
					background-color: #f3f3f3;

					svg {
					}
				}
			}

			button {
				display: block;
				height: 50px;
				width: 40%;

				@media (max-width: 565px) {
					width: 100%;
				}
			}
		}

		p {
			font-size: 14px;
			line-height: 20px;
		}

		.newsletter-acceptance-container {
			margin-top: 25px;
			display: flex;
			flex-direction: column;
			gap: 15px;
		}
	}

	.top-brands-section {
		margin-top: 50px;
		padding: 50px 0 80px;

		@media (max-width: 565px) {
			padding: 50px 0 40px;
		}

		h2 {
			font-size: 34px;
			line-height: 35px;
			color: #fff;
			gap: 10px;
			text-align: center;
			font-family: 'Roboto Condensed';

			@media (max-width: 565px) {
				font-size: 24px;
			}
		}

		.brands-images {
			display: flex;
			justify-content: center;
			gap: 40px;
			flex-wrap: wrap;
			margin: 50px auto 40px;

			img {
				max-width: 250px;

				@media (max-width: 625px) {
					max-width: 200px;
				}

				@media (max-width: 465px) {
					gap: 20px;
					max-width: 150px;
				}
			}
		}
	}

	.why-us {
		padding: 50px 0 80px;
		h2 {
			font-size: 34px;
			line-height: 35px;
			color: #fff;
			gap: 10px;
			text-align: center;
			font-family: 'Roboto Condensed';

			@media (max-width: 565px) {
				font-size: 24px;
			}

			b {
				font-weight: 700;

				span {
					color: #0154ff;
				}
			}
		}

		.container {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 40px;
			row-gap: 60px;
			padding-top: 50px;

			@media (max-width: 1024px) {
				grid-template-columns: 1fr 1fr;

				.item:nth-child(3) {
					grid-column: span 2;
				}
			}

			@media (max-width: 565px) {
				gap: 5px;
			}

			.item {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 10px;
				text-align: center;
				line-height: 30px;

				b {
					color: #fff;
					font-size: 25px;
					font-family: 'Roboto Condensed';

					@media (max-width: 565px) {
						font-size: 20px;
					}
				}

				span {
					color: #fff;
					font-size: 18px;
					font-family: 'Roboto Condensed';

					@media (max-width: 565px) {
						font-size: 16px;
					}
				}
			}
		}
	}

	.faq-banner {
		height: 200px;
		margin-bottom: 35px;

		.faq-icon {
			background-color: #0154ff;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			margin: -50px auto;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 2;
		}

		.faq-background {
			width: 100%;
			height: 100%;
			background-image: url(../images/discount-bg.svg) !important;
			background-repeat: no-repeat !important;
			background-position: center !important;
			background-size: cover;
			padding-top: 70px;

			.faq-title {
				font-size: 34px;
				color: #fff;
			}

			.faq-description {
				font-size: 18px;
				margin: auto;
				color: #fff;
				text-align: center;
				margin-top: 18px;
			}
		}
	}

	.faq {
		padding: 50px 0;
		color: #fff;

		.questions {
			padding-top: 50px;
			width: 100%;
			display: grid;
			grid-template-columns: 1fr;
			gap: 20px;

			.question {
				border-bottom: 2px solid #0154ff;
				padding-bottom: 25px;
				cursor: pointer;
				transition: 0.3s;

				.heading {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 20px;

					.title {
						font-size: 20px;
						font-weight: bold;
						text-align: left;
						@media (max-width: 565px) {
							font-size: 16px;
						}
					}

					button {
						min-width: 40px;
						min-height: 40px;
						background-color: #fff;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 50%;

						svg {
							width: 14px;
							height: 14px;
							transform: rotate(90deg);

							stroke {
								fill: #0154ff;
							}
						}
					}
				}

				.content {
					display: none;
					padding-top: 20px;
					font-size: 16px;
					line-height: 24px;
				}

				&.active {
					.content {
						display: block;
					}
					button svg {
						transform: rotate(270deg);
					}
				}
			}
		}
	}
}

/* FAQ COMPONENT */
#faq-component {
	.faq-banner {
		height: 200px;
		margin-bottom: 35px;

		.faq-icon {
			background-color: #0154ff;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			margin: -50px auto;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 2;
		}

		.faq-background {
			width: 100%;
			height: 100%;
			background-image: url(../images/discount-bg.svg) !important;
			background-repeat: no-repeat !important;
			background-position: center !important;
			background-size: cover;
			padding-top: 70px;

			.faq-title {
				font-size: 34px;
				color: #fff;
			}

			.faq-description {
				font-size: 18px;
				margin: auto;
				color: #fff;
				text-align: center;
				margin-top: 18px;
			}
		}
	}

	.faq {
		margin-top: 20px;
		padding: 50px 0;
		color: #fff;

		.questions {
			padding-top: 30px;
			width: 100%;
			display: grid;
			grid-template-columns: 1fr;
			gap: 20px;

			.question {
				border-bottom: 2px solid #ccc;
				padding-bottom: 15px;
				cursor: pointer;
				transition: 0.3s;

				.heading {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 20px;

					.title {
						font-size: 20px;
						font-weight: bold;
						color: #000;
						text-align: left;
						@media (max-width: 565px) {
							font-size: 16px;
						}
					}

					button {
						width: 40px;
						height: 40px;
						background-color: #fff;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 50%;

						svg {
							width: 14px;
							height: 14px;
							transform: rotate(90deg);

							stroke {
								fill: #fff;
							}
						}
					}
				}

				.content {
					display: none;
					padding-top: 20px;
					font-size: 16px;
					line-height: 24px;
					color: #000;
				}

				&.active {
					.content {
						display: block;
					}
					button svg {
						transform: rotate(270deg);
					}
				}
			}
		}
	}
}

/* FAQ COMPONENT ENDS */

.free-shipping-bar {
	.bar-container {
		max-width: 350px;
		margin: 0 auto 12px;
		height: 4px;
		display: flex;
		justify-content: space-between;
		gap: 12px;
		align-items: center;

		.bar {
			border-radius: 12px;
			max-width: 350px;
			margin: auto;
			background-color: #bcbcbc;
			border-radius: 60px;
			width: 100%;
			height: 7px;

			.fill {
				height: 7px;
				border-radius: 60px;
				background-color: #0154ff;

				&-10 {
					width: 10%;
				}

				&-20 {
					width: 20%;
				}

				&-30 {
					width: 30%;
				}

				&-40 {
					width: 40%;
				}

				&-50 {
					width: 50%;
				}

				&-60 {
					width: 60%;
				}

				&-70 {
					width: 70%;
				}

				&-80 {
					width: 80%;
				}

				&-90 {
					width: 90%;
				}

				&-100 {
					width: 100%;
				}
			}
		}
	}
	.free-shipping {
		text-align: center;
		margin-bottom: 40px;
	}
}
