@media (min-width: 1025px) {
	body:has(.menu-item-container.active) {
		overflow: hidden;
	}
}

@media (max-width: 1024px) {
	body:has(.menu-mobile-open) {
		overflow: hidden;
	}
}

.top-container {
	height: 40px;
	justify-content: center;
	display: flex;
	gap: 30px;
	@media (min-width: 400px) {
		padding: 0 10px;
	}
	a {
		text-decoration: none;
		color: black;
		font-size: 14px;
		font-weight: bold;
		@media (max-width: 480px) {
			font-size: 12px;
		}
	}
	.follow-icon {
		width: 28px;
		margin-right: 4px;
	}
	.return-icon {
		width: 35px;
	}

	.order-links {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
	}
}
#layout-header {
	background-color: #fff;
	position: sticky;
	top: 0;
	z-index: 150;
	-webkit-box-shadow: 0px 10px 24px -4px rgba(214, 203, 214, 1);
	-moz-box-shadow: 0px 10px 24px -4px rgba(214, 203, 214, 1);
	box-shadow: 0px 10px 24px -4px rgba(214, 203, 214, 1);

	@media (max-width: 1024px) {
		padding: 0;
	}

	.header-inner {
		position: relative;
	}

	.top {
		padding-top: 18px;
		display: flex;
		align-items: center;
		gap: 25px;

		@media (max-width: 1024px) {
			width: 100%;
			height: 50px;
			justify-content: space-between;
			padding-bottom: 18px;
		}

		@media (max-width: 400px) {
			gap: 10px;
		}

		.burger-btn {
			display: none;
			align-items: center;
			justify-content: center;
			height: 40px;
			width: 40px;

			@media (max-width: 1024px) {
				display: flex;
			}

			@media (max-width: 400px) {
				height: 30px;
				width: 30px;
			}
		}

		.logo {
			a {
				display: block;
				width: 218px;
				height: 22px;

				@media (max-width: 575px) {
					width: 180px;
					height: 18px;
				}

				@media (max-width: 400px) {
					max-width: 150px;
					height: 15px;
				}
			}
		}

		.nav-buttons {
			width: 100%;
			margin-left: auto;
			display: flex;
			align-items: center;
			justify-content: end;
			gap: 14px;

			@media (max-width: 1024px) {
				width: auto;
				margin-left: 0;
			}

			@media (max-width: 575px) {
				gap: 8px;
			}

			.nav-btn {
				img {
					width: 30px;
					height: 30px;

					@media (max-width: 575px) {
						width: 25px;
						height: 25px;
					}
				}
			}

			.search-btn {
				height: 40px;
				position: relative;
				margin-right: 4px;
				max-width: 520px;
				width: 100%;

				@media (max-width: 1024px) {
					margin-right: 0;
					max-width: unset;
					width: auto;
					height: 30px;
				}

				@media (max-width: 575px) {
					width: 25px;
					height: 25px;
				}

				input {
					height: 40px;
					max-width: 520px;
					width: 100%;

					@media (max-width: 1024px) {
						display: none;
					}

					&:focus {
						outline: none;
						border: 0;
					}
				}

				button {
					width: 30px;
					height: 40px;
					position: absolute;
					right: 5px;

					@media (max-width: 1024px) {
						width: unset;
						height: unset;
						position: relative;
						right: 0;
					}

					img {
						width: 31px;
						height: 31px;

						@media (max-width: 1024px) {
							width: 30px;
							height: 30px;
						}

						@media (max-width: 575px) {
							width: 25px;
							height: 25px;
						}
					}
				}
			}

			.cart-btn {
				position: relative;

				.quantity {
					position: absolute;
					top: -2px;
					right: -5px;
					background-color: #000;
					width: 15px;
					height: 15px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #fff;
					border-radius: 100%;
					font-size: 8px;
				}
			}
		}
	}

	.menu-wrapper {
		margin: 9px 0 0;
		padding: 9px 0 18px;
		width: 100%;

		@media (max-width: 1024px) {
			position: fixed;
			margin: 0;
			top: 0;
			left: 0;
			display: flex;
			flex-direction: column;
			max-width: 100%;
			width: 100%;
			background: #fff;
			-webkit-transform: translateX(-100%);
			transform: translateX(-100%);
			transition: 0.3s;
			z-index: 10;
			opacity: 0.6;

			&.menu-mobile-open {
				opacity: 1;
				-webkit-transform: none;
				transform: none;
				max-height: 100dvh;
				height: 100dvh;
				overflow-y: auto;
			}
		}

		.close-menu-mobile {
			display: none;
			align-items: center;
			justify-content: center;
			height: 40px;
			width: 40px;
			position: fixed;
			top: 10px;
			right: 14px;
			background-color: #f9f9f9;

			@media (max-width: 1024px) {
				display: flex;
				z-index: 10;
			}

			img {
				width: 16px;
				height: 16px;
			}
		}

		.menu-items {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 47px;

			@media (max-width: 1024px) {
				flex-direction: column;
				align-items: start;
				justify-content: start;
				padding: 0 14px;
				gap: 5px;
				margin-top: 55px;
			}

			.menu-item-container {
				@media (max-width: 1024px) {
					width: 100%;
					height: 55px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border: 1px solid #e2e5ea;
					border-radius: 10px;
					padding: 0 8px;
					gap: 0;

					&.BRANDS,
					&.SPORTS,
					&.OFFERS {
						height: auto;
						margin-top: 32px;
						display: block;
						border: 0px;

						.menu-item {
							padding-left: 0;
						}

						.sub-menu {
							display: block;
							position: relative;
							height: auto;
							left: unset;
							top: unset;
							width: unset;
							padding: 0;

							.sub-menu-container {
								padding: 0;
								margin: 16px 0 0;
							}

							.columns {
								display: flex;
								justify-content: start !important;
								flex-direction: row;
								gap: 22px !important;

								.image-menu-item {
									a {
										max-width: 60px;
									}
								}

								img {
									max-width: 60px !important;
								}
							}
						}

						.go-back-mobile {
							display: none !important;
						}

						.open-children-mobile {
							display: none !important;
						}
					}

					&.COLLECTION {
						.go-back-mobile {
							display: none !important;
						}
					}

					&:last-child {
						margin-top: 40px;
					}

					.menu-item {
						padding-left: 20px;
					}
				}

				.image-mobile {
					display: none;
					width: 40px;
					height: 40px;
					border-radius: 100%;

					@media (max-width: 1024px) {
						display: block;
					}

					img {
						width: 40px;
						height: 40px;
						border-radius: 100%;
					}
				}

				li,
				.link-menu-item {
					font-size: 16px;
					font-family: 'Roboto Condensed';
					font-weight: 600;
					line-height: 21px;
					cursor: pointer;

					&:hover {
						color: #0154ff;
					}

					@media (max-width: 1024px) {
						margin-right: auto;
						display: flex;
						justify-content: space-between;
					}

					.show-all-link {
						display: none;
						text-decoration: underline;
						font-size: 14px;

						@media (max-width: 1024px) {
							display: block;
						}
					}
				}

				.open-children-mobile {
					display: none;
					height: 50px;
					width: 50%;
					align-items: center;
					justify-content: end;
					padding-right: 20px;

					@media (max-width: 1024px) {
						display: flex;
					}
				}

				.sub-menu {
					height: 100dvh;
					overflow: auto;
					position: absolute;
					top: 100%;
					left: 0;
					right: 0;
					width: 100%;
					background-color: #fff;
					display: none;

					@media (max-width: 1024px) {
						position: fixed;
						top: 0;
						left: 0;
					}

					hr {
						margin: 0;
						@media (max-width: 1024px) {
							display: none;
						}
					}

					.close-menu-desktop {
						position: absolute;
						top: 10px;
						right: 10px;
						width: 20px;
						height: 20px;

						@media (max-width: 1024px) {
							display: none;
						}

						img {
							width: 20px;
							height: 20px;
						}
					}

					.go-back-mobile {
						display: none;
						position: absolute;
						top: 23px;
						left: 22px;
						height: 20px;
						font-size: 18px;
						color: #ccc;
						font-weight: 700;
						text-decoration: underline;

						@media (max-width: 1024px) {
							display: block;
						}
					}

					.sub-menu-container {
						padding: 35px 22px 80px;
						background-color: #fff;
						display: flex;
						justify-content: space-between;
						gap: 40px;

						@media (max-width: 1024px) {
							padding: 0 14px;
							margin-top: 55px;
						}

						// LAYOUT OVERWRITES
						&.collection-layout {
							padding: 70px 22px 80px;

							@media (max-width: 1024px) {
								padding: 0 14px;
							}
						}
					}

					.columns {
						width: 100%;
						display: grid;
						gap: 25px;
						grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
						max-height: 70dvh;
						overflow: auto;

						@media (max-width: 1024px) {
							display: flex;
							flex-direction: column;
							max-height: 90dvh;
						}

						&::-webkit-scrollbar {
							width: 8px;
						}

						&::-webkit-scrollbar-thumb {
							background-color: #ccc;
							border-radius: 0px;
						}
						&::-webkit-scrollbar-track {
							border-radius: 0px;
							box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
						}

						// layouts overwrites
						&:has(.sports-layout) {
							display: flex;
							justify-content: center;
							gap: 70px;
							flex-wrap: wrap;

							.image-menu-item {
								a {
									display: flex;
									flex-direction: column;
									gap: 4px;

									b {
										color: #000;
										font-weight: 400;
										white-space: wrap;
										font-size: 12px;
									}
									img {
										max-width: 120px;
									}
								}
							}
						}

						&:has(.brands-layout) {
							display: flex;
							justify-content: center;
							gap: 90px;
							flex-wrap: wrap;

							.image-menu-item {
								img {
									max-width: 120px;
								}
							}
						}

						&:has(.collection-layout) {
							display: block;
							gap: unset;
							grid-template-columns: unset;

							.header {
								display: none;
								justify-content: center;
								margin-bottom: 20px;

								@media (max-width: 1024px) {
									display: flex;
								}

								.mobile-back {
									width: 30px;
									height: 30px;
									display: flex;
									align-items: center;
									justify-content: center;
									position: absolute;
									top: 15px;
									left: 14px;
									transform: rotate(180deg);

									img {
										width: 8px;
									}
								}

								span {
									font-size: 20px;
									line-height: 26px;
									color: #000;
									font-weight: 600;
									font-family: 'Roboto Condensed';
									position: absolute;
									top: 18px;
								}
							}

							.brands-container {
								width: 100%;
								display: flex;
								gap: 30px;
								justify-content: center;
								flex-wrap: wrap;
								margin-bottom: 55px;

								@media (max-width: 1024px) {
									column-gap: 22px;
									row-gap: 18px;
									justify-content: start;
									padding-bottom: 25px;
									margin-bottom: 0;
									border-top: 1px solid #e2e5ea;
									padding-top: 18px;
								}

								a {
									width: 80px;

									img {
										width: 80px;
									}

									@media (max-width: 1024px) {
										width: 60px;

										img {
											width: 60px;
										}
									}
								}
							}

							.collection-menu-items {
								display: grid;
								grid-template-columns: repeat(auto-fill, minmax(356px, 1fr));
								column-gap: 16px;
								row-gap: 44px;

								@media (max-width: 1024px) {
									display: flex;
									flex-direction: column;
									gap: 4px;
									border-top: 1px solid #e2e5ea;
									padding-top: 18px;
								}

								.grouped-collection {
									display: none;
									flex-direction: column;
									gap: 10px;

									@media (max-width: 1024px) {
										display: flex;
									}

									li {
										font-size: 16px;
										line-height: 21px;
										color: #000;
										padding: 5px 0;
										margin-right: unset;
										width: 100%;
										display: flex;
										justify-content: space-between;
									}

									.children {
										display: none;
										position: fixed;
										top: 90px;
										left: 14px;
										width: calc(100% - 28px);
										background-color: #fff;
										height: calc(100dvh - 90px);
										flex-direction: column;
										gap: 10px;

										&.active {
											display: flex;
										}
									}

									.sub-children {
										display: none;
										position: fixed;
										top: 90px;
										left: 14px;
										width: calc(100% - 28px);
										background-color: #fff;
										height: calc(100dvh - 90px);
										flex-direction: column;
										gap: 10px;

										&.active {
											display: flex;
										}

										a {
											font-size: 16px;
											line-height: 21px;
											color: #000;
											padding: 5px 0;
											margin-right: unset;
											width: 100%;
											display: flex;
											justify-content: space-between;
											font-weight: 600;
										}
									}
								}

								.group {
									display: flex;

									@media (max-width: 1024px) {
										display: none;
										flex-direction: column;
										gap: 4px;
									}

									.column {
										min-width: 160px;

										@media (max-width: 1024px) {
											width: 100%;
										}

										b {
											display: block;
											width: 100%;
											font-size: 16px;
											line-height: 30px;
											color: #0154ff;
											border-bottom: 1px solid #f3f3f3;

											@media (max-width: 1024px) {
												color: #000;
												border-bottom: 0;
											}
										}

										ul {
											padding-top: 30px;
											display: flex;
											flex-direction: column;
											gap: 10px;

											@media (max-width: 1024px) {
												display: none;
											}

											a {
												font-size: 14px;
												line-height: 30px;
												white-space: wrap;
											}
										}
									}
								}
							}
						}

						&:has(.default-layout) {
							display: grid;
							gap: 25px;
							grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

							@media (max-width: 1024px) {
								display: flex;
								flex-direction: column;
								gap: 4px;
							}
						}

						.column {
							@media (max-width: 1024px) {
								position: relative;
							}

							.parent-title {
								display: block;
								width: 100%;
								padding-bottom: 6px;
								font-size: 16px;
								font-weight: 600;
								line-height: 30px;
								border-bottom: 1px solid #e2e5eb;
								color: #0154ff;

								@media (max-width: 1024px) {
									color: #000;
									border: 1px solid #f3f3f3;
									height: 54px;
									padding: 0 14px;
									display: flex;
									align-items: center;
									border-radius: 10px;
								}
							}

							.open-sub-children-mobile {
								display: none;
								height: 50px;
								width: 50%;
								align-items: center;
								justify-content: end;
								padding-right: 20px;
								position: absolute;
								top: 0;
								right: 0;

								@media (max-width: 1024px) {
									display: flex;
								}
							}

							.children-menu-items {
								margin-top: 30px;
								display: flex;
								flex-direction: column;
								gap: 10px;

								@media (max-width: 1024px) {
									display: none;

									&.active {
										display: flex;
										position: fixed;
										top: 0;
										left: 0;
										width: 100%;
										background: #fff;
										margin-top: 55px;
										z-index: 1;
										padding: 0 14px;
										gap: 4px;
										max-height: 90dvh;
										overflow: auto;
									}
								}

								li {
									font-size: 15px;
									font-weight: 500;

									@media (max-width: 1024px) {
										width: 100%;
										border: 1px solid #f3f3f3;
										height: 54px;
										padding: 0 14px;
										display: flex;
										align-items: center;
										border-radius: 10px;

										a {
											display: flex;
											align-items: center;
											width: 100%;
											height: 54px;
											font-size: 16px;
											font-weight: 600;
										}
									}

									img {
										display: none;

										@media (max-width: 1024px) {
											display: block;
										}
									}
								}
							}

							.image-menu-item {
								display: flex;
								flex-direction: column;
								gap: 8px;

								b {
									font-size: 14px;
								}
							}
						}
					}

					.menu-item-image {
						margin-left: auto;
						max-width: 270px;
						width: 100%;

						@media (max-width: 1024px) {
							display: none;
						}

						.category-img-title {
							display: block;
							margin-top: 8px;
							margin-bottom: 8px;
							text-align: left;
							font-size: 14px;
							font-weight: 600;
							text-decoration: underline;
							font-family: 'Roboto Condensed';
						}
					}
				}

				&.active {
					color: #0154ff;

					.sub-menu {
						display: block;
						background: #fff;
						z-index: 1;
					}
				}
			}
		}
	}

	.underheader-message-box {
		padding: 0 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		background-color: #0154ff;
		color: #fff;
		text-align: center;
		font-size: 16px;
		font-family: 'Roboto', sans-serif;

		@media (max-width: 1024px) {
			font-size: 14px;
			padding: 0 8px;
		}

		@media (max-width: 575px) {
			font-size: 14px;
			padding: 0 8px;
		}
	}
}
